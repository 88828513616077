import React from "react";
import Sidebar from "./Sidebar";
import Footer from "../../global/Footer";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Progress,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import {
  ArrowUpIcon,
  BanknotesIcon,
  BellIcon,
  CheckCircleIcon,
  CheckIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  LockOpenIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ShoppingCartIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import {useState} from "react";
import Breadcrumb from "./Components/Breadcrumbs";

const Approvals = () => {
  const ordersOverviewData = [
    {
      icon: BellIcon,
      color: "text-green-500",
      title: "$2400, Design changes",
      description: "22 DEC 7:20 PM",
    },
    {
      icon: PlusCircleIcon,
      color: "text-red-500",
      title: "New order #1832412",
      description: "21 DEC 11 PM",
    },
    {
      icon: ShoppingCartIcon,
      color: "text-blue-500",
      title: "Server payments for April",
      description: "21 DEC 9:34 PM",
    },
    {
      icon: CreditCardIcon,
      color: "text-orange-500",
      title: "New card added for order #4395133",
      description: "20 DEC 2:20 AM",
    },
    {
      icon: LockOpenIcon,
      color: "text-pink-500",
      title: "Unlock packages for development",
      description: "18 DEC 4:54 AM",
    },
    {
      icon: BanknotesIcon,
      color: "text-blue-gray-900",
      title: "New order #9583120",
      description: "17 DEC",
    },
  ];

  const projectsTableData = [
    {
      id: 1,
      img: "/img/logo-xd.svg",
      name: "Day Off",
      employee_name: "John Doe",
      members: [
        {
          img: "/img/team-1.jpeg",
          name: "Romina Hadid",
          role: "CEO",
          approved: true,
          comment:
            "Lorem ipsum dolor sit amet. Sit quibusdam suscipit qui velit minima et delectus totam ea voluptatem architecto aut voluptatibus reprehenderit et accusantium veniam. Id ipsum rerum ut voluptas animi et quidem sunt et officia voluptatem id voluptatem cupiditate qui quisquam debitis.",
        },
        {
          img: "/img/team-2.jpeg",
          name: "Ryan Tompson",
          role: "Manager",
          approved: true,
          comment:
            "Lorem ipsum dolor sit amet. Sit quibusdam suscipit qui velit minima et delectus totam ea voluptatem architecto aut voluptatibus reprehenderit et accusantium veniam. Id ipsum rerum ut voluptas animi et quidem sunt et officia voluptatem id voluptatem cupiditate qui quisquam debitis.",
        },
        {
          img: "/img/team-3.jpeg",
          name: "Jessica Doe",
          role: "Supervisor",
          approved: false,
          comment:
            "Lorem ipsum dolor sit amet. Sit quibusdam suscipit qui velit minima et delectus totam ea voluptatem architecto aut voluptatibus reprehenderit et accusantium veniam. Id ipsum rerum ut voluptas animi et quidem sunt et officia voluptatem id voluptatem cupiditate qui quisquam debitis.",
        },
        {
          img: "/img/team-4.jpeg",
          name: "Alexander Smith",
          role: "CEO",
          approved: null,
          comment: null,
        },
      ],
      cost: "HIGH",
      completion: 60,
      completion_num: "6/10",
    },
    {
      id: 2,
      img: "/img/logo-atlassian.svg",
      name: "Call from CEO",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-2.jpeg", name: "Ryan Tompson"},
        {img: "/img/team-4.jpeg", name: "Alexander Smith"},
      ],
      cost: "MED",
      completion: 10,
      completion_num: "1/10",
    },
    {
      id: 3,
      img: "/img/logo-slack.svg",
      name: "Pay Raise",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-3.jpeg", name: "Jessica Doe"},
        {img: "/img/team-1.jpeg", name: "Romina Hadid"},
      ],
      cost: "Free",
      completion: 100,
      completion_num: "10/10",
    },
    {
      id: 4,
      img: "/img/logo-spotify.svg",
      name: "Half Day",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-4.jpeg", name: "Alexander Smith"},
        {img: "/img/team-3.jpeg", name: "Jessica Doe"},
        {img: "/img/team-2.jpeg", name: "Ryan Tompson"},
        {img: "/img/team-1.jpeg", name: "Romina Hadid"},
      ],
      cost: "Free",
      completion: 100,
      completion_num: "10/10",
    },
    {
      id: 5,
      img: "/img/logo-jira.svg",
      name: "Day Off",
      employee_name: "John Doe",
      members: [{img: "/img/team-4.jpeg", name: "Alexander Smith"}],
      cost: "LOW",
      completion: 20,
      completion_num: "2/10",
    },
    {
      id: 6,
      img: "/img/logo-invision.svg",
      name: "Promotion",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-1.jpeg", name: "Romina Hadid"},
        {img: "/img/team-4.jpeg", name: "Alexander Smith"},
      ],
      cost: "MED",
      completion: 40,
      completion_num: "4/10",
    },
  ];

  const [selectedAction, setSelectedAction] = useState(projectsTableData[0]);
  const [openAction, setOpenAction] = React.useState(false);
  const handleOpenAction = () => setOpenAction(!openAction);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
        <Breadcrumb
          link={"/admin/Dashboard/"}
          linkCrumb={"/Dashboard/Approvals"}
          title={"Approvals"}
        ></Breadcrumb>
        <div className="h-6"></div>
        <div className="w-4/5">
          <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-1">
            <Card className="overflow-hidden xl:col-span-2">
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 flex items-center justify-between p-6"
              >
                <div>
                  <Typography variant="h6" color="blue-gray" className="mb-1">
                    Actions
                  </Typography>
                  <Typography
                    variant="small"
                    className="flex items-center gap-1 font-normal text-blue-gray-600"
                  >
                    <CheckIcon
                      strokeWidth={3}
                      className="h-4 w-4 text-blue-500"
                    />
                    <strong>30 done</strong> this month
                  </Typography>
                </div>
                <Menu placement="left-start">
                  <MenuHandler>
                    <IconButton size="sm" variant="text" color="blue-gray">
                      <EllipsisVerticalIcon
                        strokeWidth={3}
                        fill="currenColor"
                        className="h-6 w-6"
                      />
                    </IconButton>
                  </MenuHandler>
                  <MenuList>
                    <MenuItem>Action</MenuItem>
                    <MenuItem>Another Action</MenuItem>
                    <MenuItem>Something else here</MenuItem>
                  </MenuList>
                </Menu>
              </CardHeader>
              <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                <table className="w-full min-w-[640px] table-auto">
                  <thead>
                    <tr>
                      {["Action", "Employee", "flow", "cost", "completion"].map(
                        (el) => (
                          <th
                            key={el}
                            className="border-b border-blue-gray-50 py-3 px-6 text-left"
                          >
                            <Typography
                              variant="small"
                              className="text-[11px] font-medium uppercase text-blue-gray-400"
                            >
                              {el}
                            </Typography>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {projectsTableData.map((action) => {
                      const className = `py-3 px-5 ${
                        action.key === projectsTableData.length - 1
                          ? ""
                          : "border-b border-blue-gray-50"
                      }`;

                      return (
                        <tr
                          key={action.id}
                          className={" hover:bg-blue-gray-50, cursor-pointer"}
                          onClick={() => {
                            setSelectedAction(action);
                            handleOpenAction();
                          }}
                        >
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              {/* <Avatar
                                          src={img}
                                          alt={name}
                                          size="sm"
                                        /> */}
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {action.name}
                              </Typography>
                            </div>
                          </td>
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {action.employee_name}
                              </Typography>
                            </div>
                          </td>
                          <td className={className}>
                            {action.members.map(({img, name}, key) => (
                              <Tooltip key={name} content={name}>
                                <Avatar
                                  src={img}
                                  alt={name}
                                  size="xs"
                                  variant="circular"
                                  className={`cursor-pointer border-2 border-white ${
                                    key === 0 ? "" : "-ml-2.5"
                                  }`}
                                />
                              </Tooltip>
                            ))}
                          </td>
                          <td>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={action.cost}
                                color={
                                  action.cost === "HIGH"
                                    ? "red"
                                    : action.cost === "MED"
                                    ? "yellow"
                                    : action.cost === "LOW"
                                    ? "green"
                                    : "purple"
                                }
                              />
                            </div>
                          </td>

                          <td className={className}>
                            <div className="w-10/12">
                              <Typography
                                variant="small"
                                className="mb-1 block text-xs font-medium text-blue-gray-600"
                              >
                                {action.completion_num}
                              </Typography>
                              <Progress
                                value={action.completion}
                                variant="gradient"
                                color={
                                  action.completion === 100 ? "green" : "blue"
                                }
                                className="h-1"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            <Dialog open={openAction} handler={handleOpenAction}>
              <DialogHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 p-6"
              >
                <Typography variant="h6" color="blue-gray" className="mb-2">
                  Actions Overview - {selectedAction.name} for{" "}
                  {selectedAction.employee_name}
                </Typography>
                <Typography
                  variant="small"
                  className="flex items-center gap-1 font-normal text-blue-gray-600"
                >
                  <ArrowUpIcon
                    strokeWidth={3}
                    className="h-3.5 w-3.5 text-green-500"
                  />
                  <strong>{selectedAction.completion_num}</strong> Completed
                </Typography>
              </DialogHeader>
              <DialogBody className="pt-0">
                <div className="flex flex-row">
                  <div>
                    {selectedAction.members.map((member) => (
                      <div
                        key={member.img}
                        className="flex items-start gap-4 py-3"
                      >
                        <div
                          className={`relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] 
                        
                        `}
                        >
                          <div
                            className={`relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] ${
                              member.id === selectedAction.members.length - 1
                                ? "after:h-0"
                                : "after:h-4/6"
                            }`}
                          >
                            {React.createElement(
                              member.approved === null
                                ? MinusCircleIcon
                                : member.approved === true
                                ? CheckCircleIcon
                                : XCircleIcon,
                              {
                                className: `!w-5 !h-5 ${
                                  member.approved === null
                                    ? "text-purple-500"
                                    : member.approved === true
                                    ? "text-green-500"
                                    : "text-red-500"
                                }`,
                              }
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row items-center space-x-5">
                          <div>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="block font-medium"
                            >
                              {member.name}
                            </Typography>
                            <Typography
                              as="span"
                              variant="small"
                              className="text-xs font-medium text-blue-gray-500"
                            >
                              {member.role}
                            </Typography>
                          </div>

                          <Typography
                            as="span"
                            variant="small"
                            className="text-xs font-medium text-blue-gray-500"
                          >
                            {member.comment !== null
                              ? member.comment
                              : "Awaiting your approval"}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </DialogBody>
              <DialogFooter>
                <div className="relative w-[32rem]">
                  <Textarea
                    variant="static"
                    placeholder="Your Comment"
                    rows={8}
                  />
                  <div className="flex w-full justify-between py-1.5">
                    <div className="flex gap-2">
                      <Button
                        size="sm"
                        color="red"
                        variant="text"
                        className="rounded-md"
                        onClick={handleOpenAction}
                      >
                        Deny Action
                      </Button>
                      <Button
                        size="sm"
                        className="rounded-md"
                        onClick={handleOpenAction}
                      >
                        Approve Action
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogFooter>
            </Dialog>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Approvals;
