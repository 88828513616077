import React from "react";
import Navbar from "../../global/NavbarHR";

const DepartmentHR = () => {
  return (
    <>
      <Navbar name="DEPARTMENT"></Navbar>
      <div>Department</div>
    </>
  );
};

export default DepartmentHR;
