import React, { useContext, useEffect, useState } from 'react';
import { StatusContext } from '../../contexts/Status';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import { actionPending, getApprovalGroups, getActions } from '../../global/api';
import ApprovalGroupGrid from './ApprovalGroupGrid';
import ApprovalActionModal from './ApprovalActionModal';

const ApprovalsGrid = () => {
    const { CompanyID, ApprovalGroup } = useContext(StatusContext);
    const { employees } = useContext(EmployeeContext);
    const [loading, setLoading] = useState(true);
    const [groupedActions, setGroupedActions] = useState({});
    const [groupDetails, setGroupDetails] = useState({});
    const [actionDetails, setActionDetails] = useState({});

    const refreshGrid = async () => {
        setLoading(true);
        const localGroupedActions = {};

        try {
            const groupNames = await getApprovalGroups(CompanyID);
            const groupDetailsMap = groupNames.reduce((acc, group) => {
                acc[group.Approval_Group_ID] = group;
                return acc;
            }, {});
            setGroupDetails(groupDetailsMap);

            const actionDetailsResponse = await getActions(CompanyID);
            const actionDetailsMap = actionDetailsResponse.reduce((acc, action) => {
                acc[action.Action_ID] = action;
                return acc;
            }, {});
            setActionDetails(actionDetailsMap);

            for (const groupId of ApprovalGroup) {
                const actions = await actionPending(CompanyID, groupId);
                console.log('Actions:', actions);
                if (actions && actions.length > 0) {
                    localGroupedActions[groupId] = {
                        groupName: groupDetailsMap[groupId]?.Approval_Group_Display_Name || `Group ${groupId}`,
                        actions: actions.map(action => {
                            const employee = employees.find(emp => emp.Employee_ID === action.Employee_ID);
                            return {
                                ...action,
                                Action_Name: actionDetailsMap[action.Action_ID]?.Action_Name || 'Unknown Action',
                                Employee_Name: employee ? `${employee.First_Name} ${employee.Last_Name}` : 'Unknown Employee',
                                Hire_Date: employee && employee.Date_of_Hire
                                    ? new Date(employee.Date_of_Hire).toLocaleDateString('en-US')
                                    : 'Unknown Date',
                            };
                        }),
                    };
                }
            }
            setGroupedActions(localGroupedActions);
        } catch (error) {
            console.error('Error refreshing data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                await refreshGrid();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (CompanyID && Array.isArray(ApprovalGroup) && ApprovalGroup.length > 0) {
            console.log('Fetching data for CompanyID:', CompanyID);
            fetchData();
        } else {
            console.log('Invalid data:', { CompanyID, ApprovalGroup });
        }
    }, [CompanyID, ApprovalGroup, employees]);

    if (loading) return <div>Loading...</div>;

    return (
        <>
            {Object.entries(groupedActions).map(([groupId, group]) => (
                <ApprovalGroupGrid
                    key={groupId}
                    groupName={group.groupName}
                    data={group.actions}
                    refreshGrid={refreshGrid}
                />
            ))}
        </>
    );
};

export default ApprovalsGrid;