const TripleCard = (props) => {
  return (
    <div className="flex h-full w-full bg-white rounded-2xl mx-auto pb-7">
      <div className="flex-col items-center space-y-20 px-10">
        <div>
          <div className="text-left text-6xl pt-8 font-bold">{props.first}</div>
          <div className="text-left text-6xl font-bold">{props.last}</div>
          <div className="text-left text-3xl">{props.department}</div>
        </div>
        <div>
          <div className="text-left text-6xl font-bold">{props.flightrisk}</div>
          <div className="text-left text-3xl">Flight risk score</div>
        </div>
        <div>
          <div className="text-left text-6xl font-bold">
            {props.flightriskscore}
          </div>
          <div className="text-left text-3xl">Cost of flight</div>
        </div>
      </div>
    </div>
  );
};

export default TripleCard;
