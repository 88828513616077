import React, { useContext, useState, useEffect } from 'react';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import { AccountContext } from '../../contexts/Account';
import { actionCompleted, actionAdvance, actionReject, getActions, getWorkflows } from '../../global/api';

const ApprovalActionModal = ({ action, onClose }) => {
    const { employees } = useContext(EmployeeContext);
    const [comments, setComments] = useState('');
    const [actionHistory, setActionHistory] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const { getSession } = useContext(AccountContext);
    const [actions, setActions] = useState([]);
    const [workflows, setWorkflows] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const session = await getSession();
                setUserEmail(session.idToken.payload.email);

                const [actionsData, workflowsData] = await Promise.all([
                    getActions(action.Company_ID),
                    getWorkflows(action.Company_ID),
                ]);
                const workflowsMap = workflowsData.reduce((acc, workflow) => ({
                    ...acc,
                    [workflow.Action_Group_ID]: workflow,
                }), {});
                setActions(actionsData);
                setWorkflows(workflowsMap);

                console.log('Workflows:', workflowsMap);

                const history = await actionCompleted(action.Company_ID, action.Employee_ID);
                const formattedHistory = history.map(item => ({
                    ...item,
                    User_Email: item.user_id,
                    Comments: item.Approval_Notes,
                    Completed: item.Completed,
                }));
                setActionHistory(formattedHistory || []);
            } catch (error) {
                console.error('Error fetching data:', error);
                setActionHistory([]);
            }
        };

        if (action.Employee_ID) {
            fetchData();
        }
    }, [action.Company_ID, action.Employee_ID, getSession]);

    const formatDate = (dateString) => {
        return dateString ? new Date(dateString).toLocaleDateString('en-US') : 'N/A';
    };

    const employeeDetails = employees.find(emp => emp.Employee_ID === action.Employee_ID);

    const handleApprove = async () => {
        try {
            const nextApprovalStep = action.Approval_Step + 1;
            console.log('Action:', action);
            console.log('Workflows:', workflows);
            console.log('Action Group ID:', action.action_group_id);
            console.log('Next Approval Step:', nextApprovalStep);
    
            const activeApprover = workflows[action.action_group_id]?.[`Approval_Group_${nextApprovalStep}`];
            console.log('Active Approver:', activeApprover);
    
            const approvalPayload = {
                Company_Id: action.Company_ID,
                Action_GUID: action.Action_GUID,
                Employee_Id: action.Employee_ID,
                Work_Location_Id: action.Work_Location_ID,
                Department_Id: action.Department_ID,
                Job_Id: action.Job_ID,
                Action_Id: action.Action_ID,
                Action_Init_Date: new Date().toISOString(),
                user_id: userEmail,
                Approval_Step: nextApprovalStep,
                Approval_Step_Date: new Date().toISOString(),
                Next_Step: activeApprover === 'END' ? null : nextApprovalStep + 1,
                Approval_Notes: comments,
                action_group_id: action.action_group_id,
                active_approver: activeApprover,
                Completed: activeApprover === 'END' ? true : null,
                Completion_Date: activeApprover === 'END' ? new Date().toISOString() : null,
                TimeStamp: new Date().toISOString(),
            };
            console.log('Approval payload:', approvalPayload);
    
            const response = await actionAdvance(approvalPayload);
            console.log('Action advance response:', response);
    
            alert('Action approved successfully!');
            onClose();
        } catch (error) {
            console.error('Error approving action:', error);
            alert('Failed to approve action.');
        }
    };

    const handleReject = async () => {


        try {
                const rejectionPayload = {
                    Company_Id: action.Company_ID,
                    Action_GUID: action.Action_GUID,
                    Employee_Id: action.Employee_ID,
                    Work_Location_Id: employeeDetails?.Work_Location_ID,
                    Department_Id: employeeDetails?.Department_ID,
                    Job_Id: employeeDetails?.Job_ID,
                    Action_Id: action.Action_ID,
                    Action_Init_Date: action.Action_Init_Date,
                    user_id: userEmail,
                    Approval_Step: action.Approval_Step,
                    Approval_Step_Date: new Date().toISOString(),
                    Next_Step: null,
                    Approval_Notes: comments,
                    action_group_id: action.action_group_id,
                    Rejected: true,
                    Rejected_Date: new Date().toISOString(),
                    active_approver: null,
                    timeStamp: new Date().toISOString(),
                };
            console.log('Rejection payload:', rejectionPayload);

            await actionReject(rejectionPayload);
            alert('Action rejected successfully!');
            onClose();
        } catch (error) {
            console.error('Error rejecting action:', error);
            alert('Failed to reject action.');
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-4xl overflow-auto h-full max-h-screen">
                <h2 className="text-xl font-bold mb-4">{action.Action_Name}</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <p className="text-sm text-gray-500">Employee Name: {employeeDetails ? `${employeeDetails.First_Name} ${employeeDetails.Last_Name}` : 'N/A'}</p>
                        <p className="text-sm text-gray-500">Employee ID: {employeeDetails ? employeeDetails.Employee_ID : 'N/A'}</p>
                        <p className="text-sm text-gray-500">Annual Salary: {employeeDetails ? employeeDetails.Annual_Salary : 'N/A'}</p>
                        <p className="text-sm text-gray-500">Last Salary Update: {employeeDetails ? formatDate(employeeDetails.Last_Salary_Adjustment_Date) : 'N/A'}</p>
                        <p className="text-sm text-gray-500">Hire Date: {employeeDetails ? formatDate(employeeDetails.Date_of_Hire) : 'N/A'}</p>
                    </div>
                    <div>
                        <p><strong>Regrettable:</strong> {action.regret ? 'Yes' : 'No'}</p>
                        <p><strong>Regret Comment:</strong> {action.regret_comment || 'N/A'}</p>
                        <p><strong>Additional Comments:</strong> {action.Approval_Notes || 'N/A'}</p>
                    </div>
                </div>
                <textarea
                    className="w-full p-2 border border-gray-300 rounded mt-4"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Add comments for approval/rejection"
                ></textarea>
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700" onClick={handleApprove}>Approve</button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700" onClick={handleReject}>Reject</button>
                    <button className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700" onClick={onClose}>Cancel</button>
                </div>

                {/* Action History Section */}
                {actionHistory && actionHistory.length > 0 && (
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold">Action History</h3>
                        {actionHistory.map((item, index) => (
                            <div key={index} className="mb-4">
                                <p className="text-sm text-gray-500">
                                    {item.Action_Name} - {item.Completed ? 'Completed' : 'Approved'} on: {formatDate(item.Completion_Date || item.Approval_Step_Date)}
                                </p>
                                <p className="text-sm text-gray-500">User: {item.User_Email}</p>
                                <p className="text-sm text-gray-500">Comments: {item.Comments || 'N/A'}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ApprovalActionModal;