import React, {useContext, useState} from "react";
import Navbar from "../../global/NavbarAdmin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faTrash,
  faCheckSquare,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {StatusContext} from "../../contexts/Status";
import {useEffect} from "react";
import Spinner from "../../global/Spinner";
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflows,
  updateWorkflow,
} from "../../global/api";
import Sidebar from "./Sidebar";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Step,
  Stepper,
  Typography,
} from "@material-tailwind/react";
import {useParams} from "react-router-dom";

const Workflows = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [awaitWorkflows, setAwaitWorkflows] = useState(true);
  const [workflows, setWorkflows] = useState(null);

  const [currentEdit, setCurrentEdit] = useState("");

  const {
    Email, ClientID, ClientName, CompanyID, CompanyName,
  } = useContext(StatusContext);

  const fetchApprovals = async () => {
    setAwaitWorkflows(true);

    const approvals = await getWorkflows(CompanyID);
    console.log(approvals);
    setWorkflows(approvals);

    setAwaitWorkflows(false);
  };

  const handleComplete = () => {
    console.log("complete");
  };

  useEffect(() => {
    if (CompanyID) {
      fetchApprovals().finally(handleComplete);
    }
  }, [CompanyID]);

  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
          <div className="mt-10"></div>
          <div className="w-4/5">
            <div className="w-full py-4 px-8">
              {awaitWorkflows === true ? (
                <Spinner />
              ) : (
                <ul>
                  {workflows.map((workflow) => (
                    <WorkFlowCard
                      Status={workflow.Status}
                      Group={workflow.Action_Group_ID}
                      Approvers={[
                        workflow.Approval_Group_1,
                        workflow.Approval_Group_2,
                        workflow.Approval_Group_3,
                        workflow.Approval_Group_4,
                        workflow.Approval_Group_5,
                        workflow.Approval_Group_6,
                        workflow.Approval_Group_7,
                        workflow.Approval_Group_8,
                      ].filter(Boolean)}
                      CompanyID={CompanyID}
                      setCurrentEdit={setCurrentEdit}
                      setShowModalEdit={setShowModalEdit}
                    />
                  ))}
                </ul>
              )}
            </div>
          </div>
          <Button
            variant="gradient"
            color="purple"
            className="fixed bottom-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Add New Workflow
          </Button>
          {showModal ? (
            <ModalAddNew
              setShowModal={setShowModal}
              CompanyID={CompanyID}
            />
          ) : null}
          {showModalEdit ? (
            <ModalEdit
              setShowModal={setShowModalEdit}
              CompanyID={CompanyID}
              Group={currentEdit}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const WorkFlowCard = (props) => {
  const Approvers = props.Approvers;
  const handleDeleteWorkflow = async () => {
    await deleteWorkflow(props.CompanyID, props.Group);
    window.location.reload(false);
  };

  return (
    <Card className="my-10">
      <CardHeader
        floated={false}
        variant="gradient"
        color="gray"
        className="grid h-24 m-0 place-items-center"
      >
        <div className="w-full px-20 pt-4 pb-8">
          <Stepper
            // activeStep={activeStep}
            lineClassName="bg-white/50"
            activeLineClassName="bg-white"
          >
            {Approvers.map((approver) => {
              return (
                <Step
                  className="h-4 w-4 !bg-blue-gray-50 text-white/75 cursor-pointer"
                  activeClassName="ring-0 !bg-white text-white"
                  completedClassName="!bg-white text-white"
                  // onClick={() => setActiveStep(0)}
                >
                  <div className="absolute -bottom-[2.3rem] w-max text-center text-xs">
                    <Typography variant="h6" color="inherit">
                      {approver}
                    </Typography>
                  </div>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </CardHeader>
      <CardFooter>
        <div className="flex justify-between">
          <div>
            <Typography variant="h3">{props.Group}</Typography>
            <Typography variant="h5">{Approvers.length} Approvers</Typography>
          </div>
          <div>
            <Button
              variant="text"
              color="purple"
              onClick={() => {
                props.setCurrentEdit(props.Group);
                props.setShowModalEdit(true);
              }}
              className="mr-1"
            >
              <span>Edit</span>
            </Button>
            <Button
              variant="gradient"
              color="red"
              onClick={() => handleDeleteWorkflow()}
            >
              <span>Delete Workflow</span>
            </Button>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

const ModalEdit = (props) => {
  const [actionFree, setActionFree] = useState(false);
  const [actionLow, setActionLow] = useState(false);
  const [actionMed, setActionMed] = useState(false);
  const [actionHigh, setActionHigh] = useState(false);
  const [actionRefill, setActionRefill] = useState(false);
  const [actionPreCruit, setActionPreCruit] = useState(false);
  const [status, setStatus] = useState(false);
  const [awaitWorkflow, setAwaitWorkflow] = useState(true);
  const [workflow, setWorkflow] = useState(null);

  const fetchActions = async () => {
    setAwaitWorkflow(true);

    const actions = await getWorkflows(props.CompanyID, props.Group);
    console.log("action: ", actions[0]);
    setWorkflow(actions[0]);
    handleGroup(actions[0].Action_Group_ID);
    setStatus(actions[0].Status);
    setApp1(
      actions[0].Approval_Group_1 === null
        ? "Approver 1"
        : actions[0].Approval_Group_1
    );
    setApp2(
      actions[0].Approval_Group_2 === null
        ? "Approver 2"
        : actions[0].Approval_Group_2
    );
    setApp3(
      actions[0].Approval_Group_3 === null
        ? "Approver 3"
        : actions[0].Approval_Group_3
    );
    setApp4(
      actions[0].Approval_Group_4 === null
        ? "Approver 4"
        : actions[0].Approval_Group_4
    );
    setApp5(
      actions[0].Approval_Group_5 === null
        ? "Approver 5"
        : actions[0].Approval_Group_5
    );
    setApp6(
      actions[0].Approval_Group_6 === null
        ? "Approver 6"
        : actions[0].Approval_Group_6
    );
    setApp7(
      actions[0].Approval_Group_7 === null
        ? "Approver 7"
        : actions[0].Approval_Group_7
    );
    setApp8(
      actions[0].Approval_Group_8 === null
        ? "Approver 8"
        : actions[0].Approval_Group_8
    );
  };

  const handleComplete = () => {
    console.log("complete");
  };

  useEffect(() => {
    if (props.Group) {
      fetchActions().finally(handleComplete);
    }
  }, [props.CompanyID]);

  const handleGroup = (actionGroup) => {
    if (actionGroup === "Free") {
      setActionFree(true);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "Low") {
      setActionFree(false);
      setActionLow(true);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "Med") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(true);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "High") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(true);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "Refill") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(true);
      setActionPreCruit(false);
    } else if (actionGroup === "PreCruit") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(true);
    }
  };

  const currentGroup = () => {
    if (actionFree) {
      return "Free";
    } else if (actionLow) {
      return "Low";
    } else if (actionMed) {
      return "Med";
    } else if (actionHigh) {
      return "High";
    } else if (actionRefill) {
      return "Refill";
    } else if (actionPreCruit) {
      return "PreCruit";
    }
  };

  const buttons = ["Free", "Low", "Med", "High", "Refill", "PreCruit"];

  const handleSubmit = async () => {
    await updateWorkflow(props.CompanyID, currentGroup(), {
      Company_ID: props.CompanyID,
      Action_Group_ID: currentGroup(),
      Approval_Group_1: app1 === "Approver 1" ? null : app1,
      Approval_Group_2: app2 === "Approver 2" ? null : app2,
      Approval_Group_3: app3 === "Approver 3" ? null : app3,
      Approval_Group_4: app4 === "Approver 4" ? null : app4,
      Approval_Group_5: app5 === "Approver 5" ? null : app5,
      Approval_Group_6: app6 === "Approver 6" ? null : app6,
      Approval_Group_7: app7 === "Approver 7" ? null : app7,
      Approval_Group_8: app8 === "Approver 8" ? null : app8,
      Status: status,
    });
    window.location.reload(false);
  };

  const [app1, setApp1] = useState("Approver 1");
  const [app2, setApp2] = useState("Approver 2");
  const [app3, setApp3] = useState("Approver 3");
  const [app4, setApp4] = useState("Approver 4");
  const [app5, setApp5] = useState("Approver 5");
  const [app6, setApp6] = useState("Approver 6");
  const [app7, setApp7] = useState("Approver 7");
  const [app8, setApp8] = useState("Approver 8");

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Edit Workflow</h3>
            </div>
            {/*body*/}
            <form className="relative p-6 flex-auto">
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Action Group
                  </label>
                </div>
                <div className="md:w-2/3">
                  <div className="w-2/3 justify-evenly">
                    {buttons.map((button) => (
                      <button
                        className={
                          eval("action" + button)
                            ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        }
                        type="button"
                        onClick={() => handleGroup(button)}
                      >
                        {button}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Status
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value={status}
                    defaultChecked={status}
                    onChange={(e) => setStatus(!status)}
                    class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  ></input>
                </div>
              </div>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-password"
                  >
                    Approver
                  </label>
                </div>
                <div className="md:w-1/3 flex flex-col">
                  <WorkflowDropdown
                    num={1}
                    approver={app1}
                    setApp1={setApp1}
                    setApp2={setApp2}
                    setApp3={setApp3}
                    setApp4={setApp4}
                    setApp5={setApp5}
                    setApp6={setApp6}
                    setApp7={setApp7}
                    setApp8={setApp8}
                  />
                  {app1 === "Approver 1" ? null : (
                    <WorkflowDropdown
                      num={2}
                      approver={app2}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app2 === "Approver 2" ? null : (
                    <WorkflowDropdown
                      num={3}
                      approver={app3}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app3 === "Approver 3" ? null : (
                    <WorkflowDropdown
                      num={4}
                      approver={app4}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app4 === "Approver 4" ? null : (
                    <WorkflowDropdown
                      num={5}
                      approver={app5}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app5 === "Approver 5" ? null : (
                    <WorkflowDropdown
                      num={6}
                      approver={app6}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app6 === "Approver 6" ? null : (
                    <WorkflowDropdown
                      num={7}
                      approver={app7}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app7 === "Approver 7" ? null : (
                    <WorkflowDropdown
                      num={8}
                      approver={app8}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => props.setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModal(false);
                    handleSubmit();
                  }}
                >
                  Edit Workflow
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ModalAddNew = (props) => {
  const [actionFree, setActionFree] = useState(false);
  const [actionLow, setActionLow] = useState(false);
  const [actionMed, setActionMed] = useState(false);
  const [actionHigh, setActionHigh] = useState(false);
  const [actionRefill, setActionRefill] = useState(false);
  const [actionPreCruit, setActionPreCruit] = useState(false);
  const [status, setStatus] = useState(true);

  const {clientID, companyID} = useParams();
  const decodedClientID = window.atob(clientID);
  const decodedCompanyID = window.atob(companyID);

  const handleGroup = (actionGroup) => {
    if (actionGroup === "Free") {
      setActionFree(true);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "Low") {
      setActionFree(false);
      setActionLow(true);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "Med") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(true);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "High") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(true);
      setActionRefill(false);
      setActionPreCruit(false);
    } else if (actionGroup === "Refill") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(true);
      setActionPreCruit(false);
    } else if (actionGroup === "PreCruit") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
      setActionRefill(false);
      setActionPreCruit(true);
    }
  };

  const currentGroup = () => {
    if (actionFree) {
      return "Free";
    } else if (actionLow) {
      return "Low";
    } else if (actionMed) {
      return "Med";
    } else if (actionHigh) {
      return "High";
    } else if (actionRefill) {
      return "Refill";
    } else if (actionPreCruit) {
      return "PreCruit";
    }
  };

  const buttons = ["Free", "Low", "Med", "High", "Refill", "PreCruit"];

  const handleSubmit = async () => {
    await createWorkflow(decodedCompanyID, {
      Company_ID: props.CompanyID,
      Action_Group_ID: currentGroup(),
      Status: status,
      Approval_Group_1: app1 === "Approver 1" ? null : app1,
      Approval_Group_2: app2 === "Approver 2" ? null : app2,
      Approval_Group_3: app3 === "Approver 3" ? null : app3,
      Approval_Group_4: app4 === "Approver 4" ? null : app4,
      Approval_Group_5: app5 === "Approver 5" ? null : app5,
      Approval_Group_6: app6 === "Approver 6" ? null : app6,
      Approval_Group_7: app7 === "Approver 7" ? null : app7,
      Approval_Group_8: app8 === "Approver 8" ? null : app8,

      // Add more attributes as needed
    });
    window.location.reload(false);
  };

  const [app1, setApp1] = useState("Approver 1");
  const [app2, setApp2] = useState("Approver 2");
  const [app3, setApp3] = useState("Approver 3");
  const [app4, setApp4] = useState("Approver 4");
  const [app5, setApp5] = useState("Approver 5");
  const [app6, setApp6] = useState("Approver 6");
  const [app7, setApp7] = useState("Approver 7");
  const [app8, setApp8] = useState("Approver 8");

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add New Workflow</h3>
            </div>
            {/*body*/}
            <form className="relative p-6 flex-auto">
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Action Group
                  </label>
                </div>
                <div className="md:w-2/3">
                  <div className="w-2/3 justify-evenly">
                    {buttons.map((button) => (
                      <button
                        className={
                          eval("action" + button)
                            ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        }
                        type="button"
                        onClick={() => handleGroup(button)}
                      >
                        {button}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Status
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    defaultChecked={status}
                    onChange={(e) => setStatus(!status)}
                    class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  ></input>
                </div>
              </div>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-password"
                  >
                    Approver
                  </label>
                </div>
                <div className="md:w-1/3 flex flex-col">
                  <WorkflowDropdown
                    num={1}
                    approver={app1}
                    setApp1={setApp1}
                    setApp2={setApp2}
                    setApp3={setApp3}
                    setApp4={setApp4}
                    setApp5={setApp5}
                    setApp6={setApp6}
                    setApp7={setApp7}
                    setApp8={setApp8}
                  />
                  {app1 === "Approver 1" ? null : (
                    <WorkflowDropdown
                      num={2}
                      approver={app2}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app2 === "Approver 2" ? null : (
                    <WorkflowDropdown
                      num={3}
                      approver={app3}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app3 === "Approver 3" ? null : (
                    <WorkflowDropdown
                      num={4}
                      approver={app4}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app4 === "Approver 4" ? null : (
                    <WorkflowDropdown
                      num={5}
                      approver={app5}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app5 === "Approver 5" ? null : (
                    <WorkflowDropdown
                      num={6}
                      approver={app6}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app6 === "Approver 6" ? null : (
                    <WorkflowDropdown
                      num={7}
                      approver={app7}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                  {app7 === "Approver 7" ? null : (
                    <WorkflowDropdown
                      num={8}
                      approver={app8}
                      setApp1={setApp1}
                      setApp2={setApp2}
                      setApp3={setApp3}
                      setApp4={setApp4}
                      setApp5={setApp5}
                      setApp6={setApp6}
                      setApp7={setApp7}
                      setApp8={setApp8}
                    />
                  )}
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => props.setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModal(false);
                    handleSubmit();
                  }}
                >
                  Add Workflow
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const WorkflowDropdown = (props) => {
  const approvers = [
    "HR",
    "R&D",
    "Finance",
    "Compensation",
    "Manufacturing",
    "Security",
    "Technology",
    "Response",
  ];

  const setApprover = (approver) => {
    if (props.num === 1) {
      props.setApp1(approver);
    } else if (props.num === 2) {
      props.setApp2(approver);
    } else if (props.num === 3) {
      props.setApp3(approver);
    } else if (props.num === 4) {
      props.setApp4(approver);
    } else if (props.num === 5) {
      props.setApp5(approver);
    } else if (props.num === 6) {
      props.setApp6(approver);
    } else if (props.num === 7) {
      props.setApp7(approver);
    } else if (props.num === 8) {
      props.setApp8(approver);
    }
  };

  const handleDeleteApprover = () => {
    if (props.num === 1) {
      props.setApp1("Approver 1");
      props.setApp2("Approver 2");
      props.setApp3("Approver 3");
      props.setApp4("Approver 4");
      props.setApp5("Approver 5");
      props.setApp6("Approver 6");
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 2) {
      props.setApp2("Approver 2");
      props.setApp3("Approver 3");
      props.setApp4("Approver 4");
      props.setApp5("Approver 5");
      props.setApp6("Approver 6");
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 3) {
      props.setApp3("Approver 3");
      props.setApp4("Approver 4");
      props.setApp5("Approver 5");
      props.setApp6("Approver 6");
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 4) {
      props.setApp4("Approver 4");
      props.setApp5("Approver 5");
      props.setApp6("Approver 6");
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 5) {
      props.setApp5("Approver 5");
      props.setApp6("Approver 6");
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 6) {
      props.setApp6("Approver 6");
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 7) {
      props.setApp7("Approver 7");
      props.setApp8("Approver 8");
    } else if (props.num === 8) {
      props.setApp8("Approver 8");
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="whitespace-normal inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {props.approver}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 divide-y w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {approvers.map((approver) => (
              <Menu.Item>
                <div
                  onClick={() => setApprover(approver)}
                  className={
                    "bg-white text-gray-900 cursor-pointer block px-4 py-2 text-sm"
                  }
                >
                  {approver}
                </div>
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            <Menu.Item>
              <div
                onClick={() => handleDeleteApprover()}
                className={
                  "bg-white text-gray-900 cursor-pointer block px-4 py-2 text-sm"
                }
              >
                Delete
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Workflows;
