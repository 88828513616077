import {BeakerIcon} from "@heroicons/react/24/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faMale,
  faCircleUp,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {Bar} from "react-chartjs-2";
import Navbar from "../../global/NavbarApprover";
import AdminTableComponent from "./Components/TableComponent";
import LineChart from "./Components/LineChart";

import React, {useContext, useEffect, useState} from "react";
import {SelectColumnFilter} from "./Components/Table";
import Status, {StatusContext} from "../../contexts/Status";

import AWS from "aws-sdk";
import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import {AdminContext} from "../admin/Admin";
import BarChart from "../admin/Components/BarChart";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);


const ApproverOrg = () => {
  const {
    employees,
    awaitEmployees,
    departments,
    setEmployees,
    selectedEmp,
    setSelectedEmp,
  } = useContext(AdminContext);

  const [costOfRisk, setCostOfRisk] = useState(0);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [updatedEmp, setUpdatedEmp] = useState(null);

  useEffect(() => {
    const setDeptName = () => {
      let employeeList = employees.map((item) => {
        let matchingID = departments.find(
          (i) => i.Department_ID === item.Department_ID
        );
        if (matchingID) {
          item.Department_ID = matchingID.Department_Name;
        }
        return item;
      });
      console.log("updatedEmplyees", employeeList);
      setUpdatedEmp(employeeList);
    };

    const calcRiskCost = () => {
      let count = 0;
      for (let item of employees) {
        let cost = 0;
        if (item.Flight_Risk_Score >= 0.8) {
          cost = item.Annual_Salary * 1.5;
        } else if (item.Flight_Risk_Score >= 0.5) {
          cost = item.Annual_Salary * 0.75;
        }
        count += cost;
      }
      setCostOfRisk(count);
    };

    const calcGender = () => {
      let femaleCount = employees.filter(
        (employee) => employee.Gender === "Female"
      ).length;

      // Calculate the percentage of female employees
      let femalePercentage = (femaleCount / employees.length) * 100;

      setMale(100 - femalePercentage.toFixed(2));
      setFemale(femalePercentage.toFixed(2));
    };

    if (employees !== null) {
      calcRiskCost();
      calcGender();
      setSelectedEmp(employees[0]);
      if (departments !== null) {
        setDeptName();
      }
      // setDeptName();
    }
  }, [employees, awaitEmployees, departments]);

  return (
    <>
      <Navbar name="ORG"></Navbar>
      {updatedEmp === null ? (
        <Spinner />
      ) : (
        <div className="bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga">
          <div className="h-7"></div>
          <TopCard costOfRisk={costOfRisk}></TopCard>
          <div className="w-4/5 h-52 m-auto">
            <div className=" mt-20 md:flex space-x-5 w-full h-full ">
              <LeftCard />
              <RightCard female={female} male={male} />
            </div>
          </div>
          <div className="h-7"></div>
          <div className="w-4/5 m-auto bg-white rounded-2xl">
            <div className="py-8 px-6">
              <div className="flex flex-row justify-between">
                <div className="text-5xl">Stackup by Manager</div>
                <div className="flex flex-row">
                  <div className="flex-1"></div>
                  <div className="flex flex-row space-x-5 my-3 mr-5">
                    <div className="flex flex-row space-x-2 items-center">
                      <div className="w-4 h-4 rounded-full bg-[#FF9696]"></div>
                      <div className="text-lg">Serious Flight Risk</div>
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                      <div className="w-4 h-4 rounded-full bg-[#F8D57E]"></div>
                      <div className="text-lg">Moderate Flight Risk</div>
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                      <div className="w-4 h-4 rounded-full bg-[#80E6D1]"></div>
                      <div className="text-lg">No Flight Risk</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-64 w-full">
                <BarChart
                  data={{
                    labels: [
                      "John Doe",
                      "Kenneth Doe",
                      "Jeremy Doe",
                      "Jane Doe",
                    ],
                    datasets: [
                      {
                        data: [92, 68, 40, 10],
                        backgroundColor: "#FF9696",
                        borderColor: "#FF9696",
                        pointBorderColor: "transparent",
                        tension: 0.4,
                        barPercentage: 0.4,
                      },
                      {
                        data: [118, 102, 83, 62],
                        backgroundColor: "#F8D57E",
                        borderColor: "#F8D57E",
                        pointBorderColor: "transparent",
                        tension: 0.4,
                        barPercentage: 0.4,
                      },
                      {
                        data: [140, 128, 109, 71],
                        backgroundColor: "#80E6D1",
                        borderColor: "#80E6D1",
                        pointBorderColor: "transparent",
                        tension: 0.4,
                        barPercentage: 0.4,
                      },
                    ],
                  }}
                ></BarChart>
              </div>
            </div>
          </div>
          <div className="h-7"></div>
          <AdminTableComponent
            columns={[
              {
                Header: "First Name",
                accessor: "First_Name",
              },
              {
                Header: "Last Name",
                accessor: "Last_Name",
              },

              {
                Header: "Flight Risk Score",
                accessor: "Flight_Risk_Score",
              },
              {
                Header: "Title",
                accessor: "Job_ID",
              },
              {
                Header: "Department",
                accessor: "Department_ID",
              },
              // {
              //   Header: "Role",
              //   accessor: "role",
              //   Filter: SelectColumnFilter, // new
              //   filter: "includes", // new
              // },

              {
                Header: "Date of Hire",
                accessor: "Date_of_Hire",
              },
            ]}
            data={updatedEmp}
          />

          {selectedEmp === null ? (
            <Spinner />
          ) : (
            <BottomCard
              first={selectedEmp.First_Name}
              last={selectedEmp.Last_Name}
              dept={selectedEmp.Department_ID}
            />
          )}
          <div className="h-7"></div>
          <QuadCard />
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default ApproverOrg;

const TopCard = (props) => {
  return (
    <div className="w-4/5 h-52 bg-white mx-auto grid grid-cols-2 justify-center items-center rounded-2xl">
      <div className="">
        <div className="flex flex-col w/2 text-center">
          <div className="font-bold text-6xl text-center">
            ${props.costOfRisk}
          </div>
          <p className="text-gray-700 text-4xl text-center">
            Total Cost Of Flight Risk
          </p>
        </div>
      </div>
      <div className="w/2 flex">
        <div className="w-full">
          <LineChart
            data={{
              labels: ["Jan", "Feb", "March", "Apr", "May", "Jun", "Jul"],
              datasets: [
                {
                  data: [8, 7.8, 6, 8, 7, 9, 9.9],
                  backgroundColor: "transparent",
                  borderColor: "#f26c6d",
                  pointBorderColor: "transparent",
                  pointBorderWidth: 4,
                  tension: 0.4,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

const LeftCard = () => {
  return (
    <div className=" bg-white rounded-2xl w-1/2 h-full py-12">
      <div className="grid grid-cols-2 divide-x-4 justify-center">
        <div className="flex flex-col">
          <div className="font-bold text-6xl text-center">+9.7%</div>
          <p className="text-gray-700 text-4xl text-center">
            Since Last Quarter
          </p>
        </div>
        <div className="w/2 flex flex-col justify-center w-full h-full items-center">
          <div className="font-bold text-6xl text-center">-11.3%</div>
          <p className="text-gray-700 text-4xl text-center">YTD</p>
        </div>
      </div>
    </div>
    // <div className=" bg-white rounded-2xl w-1/2 items-center py-10">
    //   <div className="items-center justify-center m-auto flex flex-col">
    //     <div className="text-4xl">86</div>
    //     <div className="text-2xl">Flight risk Score</div>
    //   </div>
    // </div>
  );
};

const RightCard = (props) => {
  return (
    <div className=" bg-white rounded-2xl w-1/2 items-center py-12">
      <div className="grid grid-cols-2 divide-x-4 m-auto justify-center items-center">
        <div className="flex flex-row justify-center w-full h-full items-center">
          <FontAwesomeIcon icon={faFemale} size="6x" color="Pink" />
          <div className="flex flex-col">
            <div className="font-bold text-6xl text-center">
              {props.female}%
            </div>
            <p className="text-gray-700 text-4xl text-center">Female</p>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full h-full items-center">
          <FontAwesomeIcon icon={faMale} size="6x" color="Blue" />
          <div className="flex flex-col">
            <div className="font-bold text-6xl text-center">{props.male}%</div>
            <p className="text-gray-700 text-4xl text-center">Male</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const BottomCard = (props) => {
  const state = {
    labels: ["", "", "", "", "", "", "", "", "", "", ""],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [40, 44, 52, 58, 65, 59, 80, 81, 56, 43, 38],
      },
    ],
  };

  return (
    <>
      <div className=" w-4/5 min-h-min bg-white text-gray-900 justify-center mx-auto rounded-2xl mt-8">
        <div className="text-6xl py-4 pl-4">
          {props.first} {props.last} - {props.dept}
        </div>
        {/* <div className="w-full h-32 bg-yellow-400 flex flex-row items-center pl-4">
          <div className="flex-1">
            <div className="flex flex-row">
              <div className="px-4">
                <FontAwesomeIcon icon={faBell} size="6x" />
              </div>
              <div className="flex flex-col">
                <div className="text-4xl">High Flight Risk</div>
                <div className="text-3xl">3 actions taken so far</div>
              </div>
            </div>
          </div>
          <div className="pr-4 flex flex-row space-x-5">
            <div className="bg-white text-center rounded-full w-48 h-12 pt-2 cursor-pointer">
              <div className="text-xl">Take Action</div>
            </div>
            <div className="bg-white text-center rounded-full w-48 h-12 pt-2 cursor-pointer">
              <div className="text-xl">No Action</div>
            </div>
          </div>
        </div> */}
        <div className="h-2/3">
          <div className="flex flex-row">
            <div className="flex-1"></div>
            <div className="flex flex-row space-x-5 my-3 mr-5">
              <div className="flex flex-row space-x-2 items-center">
                <div className="w-6 h-6 rounded-full bg-green-500"></div>
                <div className="text-xl">Total Compensation</div>
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <div className="w-6 h-6 rounded-full bg-red-500"></div>
                <div className="text-xl">Performance Rating</div>
              </div>
            </div>
          </div>

          <div className="h-full w-full bg-white rounded-md">
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: false,
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    min: 2,
                    max: 10,
                    ticks: {
                      stepSize: 2,
                      callback: (value) => value + "K",
                    },
                    grid: {},
                  },
                },
              }}
              data={{
                labels: [
                  "2011",
                  "2012",
                  "2013",
                  "2014",
                  "2015",
                  "2016",
                  "2017",
                  "2018",
                  "2019",
                  "2020",
                  "2021",
                  "2022",
                ],
                datasets: [
                  {
                    data: [
                      2, 3.8, 2.6, 4.2, 5.3, 4.4, 5.1, 6.2, 6.8, 7.1, 6.2, 5.9,
                    ],
                    backgroundColor: "transparent",
                    borderColor: "#6FD293",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                  {
                    data: [
                      3.8, 2.6, 4.2, 5.3, 5.7, 6.3, 7, 8.1, 7.1, 6.2, 5.5, 5.1,
                    ],
                    backgroundColor: "transparent",
                    borderColor: "#FF9696",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 justify-between mr-5 my-10 items-center">
          <div className="items-center grid grid-cols-2 justify-evenly">
            <div className="flex flex-col px-10">
              <div className="text-4xl">$180,454</div>
              <div className="text-2xl">Current Base Pay</div>
            </div>
            <div className="h-36">
              <Bar
                data={state}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  plugins: {
                    legend: false,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="items-center grid grid-cols-2 justify-evenly">
            <div className="flex flex-col px-12">
              <div className="text-4xl">$16,280</div>
              <div className="text-2xl">Current Performance Incentive</div>
            </div>
            <div className="h-36">
              <Bar
                data={state}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  plugins: {
                    legend: false,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="items-center grid grid-cols-2 justify-evenly">
            <div className="flex flex-col px-10">
              <div className="text-4xl">0.78</div>
              <div className="text-2xl">Current Comp. Ratio</div>
            </div>
            <div className="h-36">
              <Bar
                data={state}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  plugins: {
                    legend: false,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" w-4/5 min-h-min bg-white text-gray-900 justify-center mx-auto rounded-2xl mt-2">
        <div className="grid grid-cols-4 py-8">
          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Bonus Eligible: </div>
            <div className="">12%</div>
          </div>

          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Date of Joining: </div>
            <div>23 May, 2005</div>
          </div>
          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Annual PTO: </div>
            <div>15 days</div>
          </div>
          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Last Salary raise: </div>
            <div>16 Jan, 2020</div>
          </div>
        </div>
      </div>
      <div className="h-2"></div>
    </>
  );
};

const QuadCard = () => {
  return (
    <div className="w-4/5 text-gray-900 flex flex-row space-x-5 mx-auto">
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">86</div>
          <div className="text-2xl">Flight risk Score</div>
        </div>
      </div>
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">196 days</div>
          <div className="text-2xl">Average Time to Hire</div>
        </div>
      </div>
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">$ 24,342</div>
          <div className="text-2xl">Cost to Approving Action</div>
        </div>
      </div>
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">$ 357,455</div>
          <div className="text-2xl">Cost to Taking No Action</div>
        </div>
      </div>
    </div>
  );
};
