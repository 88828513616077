import React, { useContext, useEffect, useState } from "react";
import { BanknotesIcon, ClockIcon } from "@heroicons/react/24/solid";
import { StatusContext } from "../../contexts/Status";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import { getLocations } from "../../global/api";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import Sidebar from "../../components/common/sidebar_new";
import TopNavigation from "../../components/common/TopNavigation";
import StatisticsChart from "../../components/dashboard/DataChart";
import chartsConfig from "../../components/dashboard/charts-config";
import { CompanyDataProvider } from '../../contexts/CompanyContext';
import { CompanyChartDataProvider } from "../../contexts/CompanyChartDataContext";
import { EmployeeProvider } from "../../contexts/EmployeeContext";
import EmployeeGrid from "../../components/dashboard/EmployeeGrid";

const DashboardLocation = () => {
  const { CompanyID } = useContext(StatusContext);
  const [costOfRisk, setCostOfRisk] = useState(0);
  const [locationBars, setLocationBars] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filters, setFilters] = useState({});
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    if (CompanyID) {
      fetchLocations();
    }
  }, [CompanyID]);

  const fetchLocations = async () => {
    try {
      const locations = await getLocations(CompanyID);
      if (Array.isArray(locations) && locations.length > 0) {
        processLocationData(locations);
      } else {
        console.error("Locations data is empty or not in expected format");
        setLocationBars([]);
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
      setLocationBars([]);
    }
  };

  const processLocationData = (locations) => {
    const processedLocations = locations.map(location => ({
      ...location,
      redPercentage: location.count_total ? (location.count_red / location.count_total) * 100 : 0,
      yellowPercentage: location.count_total ? (location.count_yellow / location.count_total) * 100 : 0,
      totalEmployees: location.count_total || 0,
      riskAmount: parseFloat(location.total_risk?.replace(/[$,]/g, "") || "0"),
      Location_Name: location.Location_Name || "Unknown Location",
    }));

    processedLocations.sort((a, b) => (b.redPercentage + b.yellowPercentage) - (a.redPercentage + a.yellowPercentage));
    setLocationBars(processedLocations);

    const totalRisk = processedLocations.reduce((sum, location) => sum + location.riskAmount, 0);
    setCostOfRisk(totalRisk);
  };

  const getCurrentPageData = () => {
    if (!locationBars) return [];
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return locationBars.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLocationChange = (locationId) => {
    const location = locationBars.find(loc => loc.Work_Location_ID === locationId);
    setSelectedLocation(location);
    setFilters(prev => ({ ...prev, locationId: locationId }));
  };

  const locationsChart = locationBars && locationBars.length > 0 ? {
    type: "bar",
    height: 400,
    series: [
      {
        name: "High Risk",
        data: getCurrentPageData().map((location) => location.redPercentage || 0),
      },
      {
        name: "Med Risk",
        data: getCurrentPageData().map((location) => location.yellowPercentage || 0),
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#B81D13", "#EFB700"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
          borderRadius: 3,
          stacked: true,
        },
      },
      xaxis: {
        categories: getCurrentPageData().map((location) => location.Location_Name),
        labels: {
          formatter: function (val) {
            return val.toFixed(0) + "%";
          },
        },
      },
      yaxis: {
        title: {
          text: "Locations"
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            const location = getCurrentPageData()[dataPointIndex] || {};
            const riskType = seriesIndex === 0 ? "High Risk" : "Med Risk";
            const count = seriesIndex === 0 ? location.count_red : location.count_yellow;
            return `${riskType}: ${val.toFixed(1)}% (${count || 0} employees)
                    Total Risk: $${(location.riskAmount || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    Total Employees: ${location.totalEmployees || 0}`;
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          const location = getCurrentPageData()[dataPointIndex] || {};
          const totalRiskPercentage = (location.redPercentage || 0) + (location.yellowPercentage || 0);
          if (seriesIndex === 1) { // Only show label on the yellow (top) bar
            return `${totalRiskPercentage.toFixed(1)}% - $${(location.riskAmount || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
          }
          return "";
        },
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        background: {
          enabled: false
        },
      },
    },
  } : null;

  const locationChartsData = [
    {
      color: "white",
      title: "Location Risk Distribution",
      description: "Risk percentage and amount across different locations",
      footer: "Updated today",
      chart: locationsChart,
    },
  ];

  const statisticsCardsData = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Total Risk",
      value: `$${costOfRisk.toLocaleString(undefined, { maximumFractionDigits: 0 })}`,
      footer: {
        color: "text-green-500",
        value: "+55%",
        label: "than last week",
      },
    },
  ];

  if (!locationBars) return <Spinner />;

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col bg-[#F2F2F2] min-h-screen w-full font-fabriga">
        <TopNavigation />
        <div className="p-4">
          <Typography variant="h4" color="blue-gray" className="mb-4">
            Locations Dashboard
          </Typography>
          <div className="mb-6">
            <Select label="Select Location" onChange={(e) => handleLocationChange(e.target.value)}>
              {locationBars.map((location) => (
                <Option key={location.Work_Location_ID} value={location.Work_Location_ID}>
                  {location.Location_Name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mb-6">
            {locationChartsData.map((props) => (
              <StatisticsChart
                key={props.title}
                {...props}
                footer={
                  <Typography variant="small" className="flex items-center font-normal text-blue-gray-600">
                    <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                    &nbsp;{props.footer}
                  </Typography>
                }
                pagination={
                  <div className="flex gap-2 p-2">
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === Math.ceil((locationBars || []).length / ITEMS_PER_PAGE)}
                    >
                      Next
                    </Button>
                  </div>
                }
              />
            ))}
          </div>
          <div className="mb-6">
            {statisticsCardsData.map(({ icon, title, footer, ...rest }) => (
              <StatisticsCard
                key={title}
                {...rest}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-6 h-6 text-white",
                })}
                footer={
                  <Typography className="font-normal text-blue-gray-600">
                    <strong className={footer.color}>{footer.value}</strong>
                    &nbsp;{footer.label}
                  </Typography>
                }
              />
            ))}
          </div>
          {selectedLocation && (
            <Typography variant="h5" color="blue-gray" className="mb-4">
              Selected Location: {selectedLocation.Location_Name}
            </Typography>
          )}
          <EmployeeProvider companyId={CompanyID}>
            <CompanyDataProvider companyId={CompanyID}>
              <CompanyChartDataProvider>
                <EmployeeGrid filters={filters} />
              </CompanyChartDataProvider>
            </CompanyDataProvider>
          </EmployeeProvider>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const StatisticsCard = ({ color, icon, title, value, footer }) => {
  return (
    <Card>
      <CardHeader
        variant="gradient"
        color={color}
        className="absolute -mt-4 grid h-16 w-16 place-items-center"
      >
        {icon}
      </CardHeader>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        <Typography variant="h4" color="blue-gray">
          {value}
        </Typography>
      </CardBody>
      {footer && (
        <CardFooter className="border-t border-blue-gray-50 p-4">
          {footer}
        </CardFooter>
      )}
    </Card>
  );
};

export default DashboardLocation;
