import {BeakerIcon} from "@heroicons/react/24/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faMale,
  faCircleUp,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {Bar} from "react-chartjs-2";
import Navbar from "../../global/NavbarManager";
import TableComponent from "./Components/TableComponent";
import LineChart from "./Components/LineChart";

import React from "react";
import {SelectColumnFilter} from "./Components/Table";
import {useContext} from "react";
import {useState} from "react";
import {AdminContext} from "../admin/Admin";
import {useEffect} from "react";
import {Line} from "react-chartjs-2";
import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {StatusContext} from "../../contexts/Status";
import AWS from "aws-sdk";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import {ManagerContext} from "./Manager";

const ManagerOrg = () => {
  const {
    employees,
    awaitEmployees,
    departments,
    setEmployees,
    selectedEmp,
    setSelectedEmp,
  } = useContext(ManagerContext);

  const [costOfRisk, setCostOfRisk] = useState(0);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [updatedEmp, setUpdatedEmp] = useState(null);

  const setDeptName = () => {
    let employeeList = employees.map((item) => {
      let matchingID = departments.find(
        (i) => i.Department_ID === item.Department_ID
      );
      if (matchingID) {
        item.Department_ID = matchingID.Department_Name;
      }
      return item;
    });
    console.log("updatedEmplyees", employeeList);
    setUpdatedEmp(employeeList);
  };

  const calcRiskCost = () => {
    let count = 0;
    for (let emp of employees) {
      let cost = 0;
      if (emp.Flight_Risk_Score >= 0.8) {
        cost = parseFloat(emp.Annual_Salary.replace(/[$,]/g, "")) * 1.5;
      } else if (emp.Flight_Risk_Score >= 0.5) {
        cost = parseFloat(emp.Annual_Salary.replace(/[$,]/g, "")) * 0.75;
      }
      count += cost;
    }
    setCostOfRisk(count);
  };

  const calcGender = () => {
    let femaleCount = employees.filter(
      (employee) => employee.Gender === "Female"
    ).length;

    // Calculate the percentage of female employees
    let femalePercentage = (femaleCount / employees.length) * 100;

    setMale(100 - femalePercentage.toFixed(2));
    setFemale(femalePercentage.toFixed(2));
  };

  useEffect(() => {
    if (employees !== null) {
      calcRiskCost();
      calcGender();
      setSelectedEmp(employees[0]);
      if (departments !== null) {
        setDeptName();
      }
      // setDeptName();
    }
  }, [employees]);

  return (
    <>
      <Navbar name="MY ORG"></Navbar>
      {updatedEmp === null ? (
        <Spinner />
      ) : (
        <div className="bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga">
          <div className="h-7"></div>
          <TopCard costOfRisk={costOfRisk}></TopCard>
          <div className="w-4/5 h-52 m-auto">
            <div className=" mt-20 md:flex space-x-5 w-full h-full ">
              <LeftCard />
              <RightCard female={female} male={male} />
            </div>
          </div>
          <div className="h-7"></div>
          <TableComponent
            columns={[
              {
                Header: "First Name",
                accessor: "First_Name",
              },
              {
                Header: "Last Name",
                accessor: "Last_Name",
              },

              {
                Header: "Flight Risk Score",
                accessor: "Flight_Risk_Score",
              },
              {
                Header: "Title",
                accessor: "Job_ID",
              },
              {
                Header: "Department",
                accessor: "Department_ID",
              },
              // {
              //   Header: "Role",
              //   accessor: "role",
              //   Filter: SelectColumnFilter, // new
              //   filter: "includes", // new
              // },

              {
                Header: "Date of Hire",
                accessor: "Date_of_Hire",
              },
            ]}
            data={updatedEmp}
          />

          {selectedEmp === null ? (
            <Spinner />
          ) : (
            <BottomCard
              first={selectedEmp.First_Name}
              last={selectedEmp.Last_Name}
              dept={selectedEmp.Department_ID}
            />
          )}
          <div className="h-7"></div>
          <QuadCard />
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default ManagerOrg;

const TopCard = (props) => {
  return (
    <div className="w-4/5 h-52 bg-white mx-auto grid grid-cols-2 justify-center items-center rounded-2xl">
      <div className="">
        <div className="flex flex-col w/2 text-center">
          <div className="font-bold text-6xl text-center">
            ${props.costOfRisk}
          </div>
          <p className="text-gray-700 text-4xl text-center">
            Total Cost Of Flight Risk
          </p>
        </div>
      </div>
      <div className="w/2 flex">
        <div className="w-full">
          <LineChart
            data={{
              labels: ["Jan", "Feb", "March", "Apr", "May", "Jun", "Jul"],
              datasets: [
                {
                  data: [8, 7.8, 6, 8, 7, 9, 9.9],
                  backgroundColor: "transparent",
                  borderColor: "#f26c6d",
                  pointBorderColor: "transparent",
                  pointBorderWidth: 4,
                  tension: 0.4,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

const LeftCard = () => {
  return (
    <div className=" bg-white rounded-2xl w-1/2 h-full py-12">
      <div className="grid grid-cols-2 divide-x-4 justify-center">
        <div className="flex flex-col">
          <div className="font-bold text-6xl text-center">+9.7%</div>
          <p className="text-gray-700 text-4xl text-center">
            Since Last Quarter
          </p>
        </div>
        <div className="w/2 flex flex-col justify-center w-full h-full items-center">
          <div className="font-bold text-6xl text-center">-11.3%</div>
          <p className="text-gray-700 text-4xl text-center">YTD</p>
        </div>
      </div>
    </div>
    // <div className=" bg-white rounded-2xl w-1/2 items-center py-10">
    //   <div className="items-center justify-center m-auto flex flex-col">
    //     <div className="text-4xl">86</div>
    //     <div className="text-2xl">Flight risk Score</div>
    //   </div>
    // </div>
  );
};

const RightCard = () => {
  return (
    <div className=" bg-white rounded-2xl w-1/2 items-center py-12">
      <div className="grid grid-cols-2 divide-x-4 m-auto justify-center items-center">
        <div className="flex flex-row justify-center w-full h-full items-center">
          <FontAwesomeIcon icon={faFemale} size="6x" color="Pink" />
          <div className="flex flex-col">
            <div className="font-bold text-6xl text-center">53.6%</div>
            <p className="text-gray-700 text-4xl text-center">Female</p>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full h-full items-center">
          <FontAwesomeIcon icon={faMale} size="6x" color="Blue" />
          <div className="flex flex-col">
            <div className="font-bold text-6xl text-center">53.6%</div>
            <p className="text-gray-700 text-4xl text-center">Male</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const BottomCard = (props) => {
  const [showModalTakeAction, setShowModalTakeAction] = useState(false);
  const [showModalTakeNoAction, setShowModalNoAction] = useState(false);

  const state = {
    labels: ["", "", "", "", "", "", "", "", "", "", ""],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [40, 44, 52, 58, 65, 59, 80, 81, 56, 43, 38],
      },
    ],
  };

  return (
    <>
      <div className=" w-4/5 min-h-min bg-white text-gray-900 justify-center mx-auto rounded-2xl mt-8">
        <div className="text-6xl py-4 pl-4">
          {props.first} {props.last} - {props.dept}
        </div>
        <div className="w-full h-32 bg-yellow-400 flex flex-row items-center pl-4">
          <div className="flex-1">
            <div className="flex flex-row">
              <div className="px-4">
                <FontAwesomeIcon icon={faBell} size="6x" />
              </div>
              <div className="flex flex-col">
                <div className="text-4xl">High Flight Risk</div>
                <div className="text-3xl">3 actions taken so far</div>
              </div>
            </div>
          </div>
          <div className="pr-4 flex flex-row space-x-5">
            <div
              className="bg-white text-center rounded-full w-48 h-12 pt-2 cursor-pointer"
              onClick={() => setShowModalTakeAction(true)}
            >
              <div className="text-xl">Take Action</div>
            </div>
            <div
              className="bg-white text-center rounded-full w-48 h-12 pt-2 cursor-pointer"
              onClick={() => setShowModalNoAction(true)}
            >
              <div className="text-xl">No Action</div>
            </div>
          </div>
        </div>

        <div className="h-2/3">
          <div className="flex flex-row">
            <div className="flex-1"></div>
            <div className="flex flex-row space-x-5 my-3 mr-5">
              <div className="flex flex-row space-x-2 items-center">
                <div className="w-6 h-6 rounded-full bg-green-500"></div>
                <div className="text-xl">Total Compensation</div>
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <div className="w-6 h-6 rounded-full bg-red-500"></div>
                <div className="text-xl">Performance Rating</div>
              </div>
            </div>
          </div>

          <div className="h-full w-full bg-white rounded-md">
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: false,
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    min: 2,
                    max: 10,
                    ticks: {
                      stepSize: 2,
                      callback: (value) => value + "K",
                    },
                    grid: {},
                  },
                },
              }}
              data={{
                labels: [
                  "2011",
                  "2012",
                  "2013",
                  "2014",
                  "2015",
                  "2016",
                  "2017",
                  "2018",
                  "2019",
                  "2020",
                  "2021",
                  "2022",
                ],
                datasets: [
                  {
                    data: [
                      2, 3.8, 2.6, 4.2, 5.3, 4.4, 5.1, 6.2, 6.8, 7.1, 6.2, 5.9,
                    ],
                    backgroundColor: "transparent",
                    borderColor: "#6FD293",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                  {
                    data: [
                      3.8, 2.6, 4.2, 5.3, 5.7, 6.3, 7, 8.1, 7.1, 6.2, 5.5, 5.1,
                    ],
                    backgroundColor: "transparent",
                    borderColor: "#FF9696",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 justify-between mr-5 my-10 items-center">
          <div className="items-center grid grid-cols-2 justify-evenly">
            <div className="flex flex-col px-10">
              <div className="text-4xl">$180,454</div>
              <div className="text-2xl">Current Base Pay</div>
            </div>
            <div className="h-36">
              <Bar
                data={state}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  plugins: {
                    legend: false,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="items-center grid grid-cols-2 justify-evenly">
            <div className="flex flex-col px-12">
              <div className="text-4xl">$16,280</div>
              <div className="text-2xl">Current Performance Incentive</div>
            </div>
            <div className="h-36">
              <Bar
                data={state}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  plugins: {
                    legend: false,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="items-center grid grid-cols-2 justify-evenly">
            <div className="flex flex-col px-10">
              <div className="text-4xl">0.78</div>
              <div className="text-2xl">Current Comp. Ratio</div>
            </div>
            <div className="h-36">
              <Bar
                data={state}
                options={{
                  title: {
                    display: true,
                    text: "Average Rainfall per month",
                    fontSize: 20,
                  },
                  plugins: {
                    legend: false,
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" w-4/5 min-h-min bg-white text-gray-900 justify-center mx-auto rounded-2xl mt-2">
        <div className="grid grid-cols-4 py-8">
          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Bonus Eligible: </div>
            <div className="">12%</div>
          </div>

          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Date of Joining: </div>
            <div>23 May, 2005</div>
          </div>
          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Annual PTO: </div>
            <div>15 days</div>
          </div>
          <div className="items-center justify-center m-auto text-2xl flex flex-row">
            <div className="">Last Salary raise: </div>
            <div>16 Jan, 2020</div>
          </div>
        </div>
      </div>
      <div className="h-2"></div>
      {showModalTakeNoAction ? (
        <ModalNoAction setShowModalNoAction={setShowModalNoAction} />
      ) : null}
      {showModalTakeAction ? (
        <ModalTakeAction setShowModalTakeAction={setShowModalTakeAction} />
      ) : null}
    </>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ModalTakeAction = (props) => {
  const [text, setText] = useState("");

  const [showDropdown, setShowDropdown] = useState(false);

  const [awaitActions, setAwaitActions] = useState(true);
  const [actions, setActions] = useState(null);
  const {CompanyID, Loading} = useContext(StatusContext);

  const [selectedAction, setSelectedAction] = useState("Select an action");

  AWS.config.update({
    region: "us-east-1",
    accessKeyId: "AKIAXYAJO73XJC7OXQTR",
    secretAccessKey: "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",
    dynamoDbCrc32: false,
  });

  const fetchActions = async () => {
    setAwaitActions(true);

    const dynamodb = new AWS.DynamoDB.DocumentClient();

    try {
      const actionParams = {
        TableName: "Config_Actions",
        KeyConditionExpression: "Company_ID = :companyID",
        // FilterExpression: "Action_Status = :actionStatus",
        ExpressionAttributeValues: {
          ":companyID": CompanyID,
          // ":actionStatus": true,
        },
      };

      const actions = await dynamodb.query(actionParams).promise();
      console.log(
        "actions: ",
        actions.Items.sort(function (b, a) {
          return a.Action_Group_ID - b.Action_Group_ID;
        })
      );
      setActions(
        actions.Items.sort(function (b, a) {
          return a.Action_Group_ID - b.Action_Group_ID;
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setAwaitActions(false);
    }
  };

  const handleComplete = () => {
    console.log("complete");
  };

  useEffect(() => {
    fetchActions().finally(handleComplete);
  }, [CompanyID, Loading]);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Pick a retention action
              </h3>
            </div>
            {/*body*/}
            <form className="relative inline-block p-6 flex-auto justify-center">
              <Menu as="div" className=" text-left justify-center">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    {selectedAction}
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  {awaitActions === false ? (
                    <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {actions.map((action) => (
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedAction(action.Action_Name);
                                }}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {action.Action_Name +
                                  " - " +
                                  action.Action_Group_ID}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  ) : null}
                </Transition>
              </Menu>

              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModalTakeAction(false);
                    console.log("canceled");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModalTakeAction(false);
                  }}
                >
                  Take Action
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ModalNoAction = (props) => {
  const [text, setText] = useState("");

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Are you sure you want to take no action?
              </h3>
            </div>
            {/*body*/}
            <form className="relative p-6 flex-auto">
              <div class="md:flex md:items-center mb-6 justify-center">
                <div class="md:w-2/3">
                  <textarea
                    class="block bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="large-input"
                    type="text"
                    placeholder="Tell us why you believe no action is a good idea..."
                    rows="5"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModalNoAction(false);
                    console.log("canceled");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModalNoAction(false);
                  }}
                >
                  Yes, Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
const QuadCard = () => {
  return (
    <div className="w-4/5 text-gray-900 flex flex-row space-x-5 mx-auto">
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">86</div>
          <div className="text-2xl">Flight risk Score</div>
        </div>
      </div>
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">196 days</div>
          <div className="text-2xl">Average Time to Hire</div>
        </div>
      </div>
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">$ 24,342</div>
          <div className="text-2xl">Cost to Approving Action</div>
        </div>
      </div>
      <div className=" bg-white rounded-2xl w-1/4 items-center py-10">
        <div className="items-center justify-center m-auto flex flex-col">
          <div className="text-4xl">$ 357,455</div>
          <div className="text-2xl">Cost to Taking No Action</div>
        </div>
      </div>
    </div>
  );
};
