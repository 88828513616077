import { fieldsConfig } from '../../configs/fieldsConfig';

export const prepareChartDataCompany = (companyData) => {
  if (!companyData || companyData.length === 0) return {};

  const fields = fieldsConfig.company; // This is an object, not an array
  let chartData = {};

  // You need to iterate over the keys of the fields object
  Object.keys(fields).forEach((key) => {
    chartData[key] = companyData.map((entry) => {
      let value = entry[key];

      // Check the field type in the config
      if (fields[key].type === 'currency' && typeof value === 'string') {
        value = parseFloat(value.replace(/[$,]/g, ''));
      }

      return {
        x: new Date(entry.Period_Date).getTime(),
        y: value
      };
    });
  });

  return chartData;
};
