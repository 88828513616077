import React, { useState,useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { createEmployee, deleteLoadID, getLoadID, loadProcess } from "../../global/api";
import { Button } from "@material-tailwind/react";
import Spinner from "../../global/Spinner"; // Make sure the path is correct
import './LoadEmployeeComponent.css';
import { StatusContext } from "../../contexts/Status";

const LoadEmployeeComponent = () => {
  const [file, setFile] = useState(null);
  const [currentLoadID, setCurrentLoadID] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { ClientID } = useContext(StatusContext);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadData = async () => {
    setIsLoading(true);
    try {
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = async (evt) => {
          const textFile = evt.target.result;
          const response = await createEmployee(textFile);
          setIsLoading(false);

          if (response && response.status >= 500) {
            setError(response.error || 'Server error');
            return;
          }

          const { loadID, recordCount } = response;
          setCurrentLoadID(loadID);
          setRecordCount(recordCount);
          setFile(null);
        };

        reader.onerror = (evt) => {
          console.error("An error occurred reading the file", evt);
        };
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message || 'An error occurred');
      console.error("Caught exception:", error);
    }
  };

  const handleDeleteLoad = async () => {
    setIsLoading(true);
    await deleteLoadID(currentLoadID);
    setCurrentLoadID(null);
    setRecordCount(0);
    setIsLoading(false);
  };

 // CSV Data and Download function
const csvData = [
  "Client_ID",
  "Company_ID",
  "Employee_ID",
  "First_Name",
  "Last_Name",
  "Post_Code",
  "Annual_Salary",
  "Date_of_Birth",
  "Date_of_Hire",
  "Date_of_Termination",
  "Term_Reason",
  "Country",
  "Currency",
  "Performance_Review",
  "Work_Location_ID",
  "Department_ID",
  "Job_ID",
  "Reports_To",
  "Last_Salary_Adjustment_Date",
  "Address_1",
  "Address_2",
  "City",
  "State",
  "Flight_Risk_Score",
  "Gender",
  "Marital_Status",
  "EEO1",
  "Period_Date",
  "Last_Update",
];

const downloadCsv = (event) => {
  event.preventDefault();
  const csvContent =
    "data:text/csv;charset=utf-8," + csvData.join(",") + "\n";
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};




  useEffect(() => {
    if (currentLoadID) {
      handleReviewData();
    }
  }, [currentLoadID]);

  const handleReviewData = async () => {
    if (!currentLoadID) return;

    const data = await getLoadID(currentLoadID);
    setCurrentData(data);
  };

  const closeModal = async (action) => {
    setShowModal(false);
    if (action === 'process') {
      await loadProcess();
    } else if (action === 'delete') {
      await deleteLoadID(currentLoadID);
    }
    setCurrentLoadID(null);
    setRecordCount(0);
  };
  const validateClientID = () => {
    if (currentData.some(data => data.Client_ID !== ClientID)) {
      setError('Client_ID in the load does not match the Client_ID in the URL');
    }
  };

  const closeModalAndAct = async (action) => {
    if (action === 'process') {
      await loadProcess();
    } else if (action === 'delete') {
      await deleteLoadID(currentLoadID);
    }
    setCurrentLoadID(null);
    setRecordCount(0);
    setShowModal(false);
    validateClientID();
  };

  return (
    <div className="flex flex-col w-2/3 items-center justify-center font-fabriga mx-auto">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
<form action="">
  <div className="text-2xl text-center mb-4">Pick a file to upload Employee Data</div>
  <div className="mb-4">
    Here is a CSV template for employee data records.
    <Button 
  onClick={downloadCsv} 
  className="standard-button mx-2"
  style={{ fontSize: '0.5rem', padding: '0.25rem 0.5rem' }}
>
  Template
</Button>

  </div>
  {!currentLoadID && (
    <div className="flex flex-row w-4/5 justify-center mt-4">
      <input
        type="file"
        id="csvFileInput"
        accept=".csv"
        onChange={handleOnChange}
      />
      {file && (
        <Button onClick={handleUploadData} className="standard-button mx-2">
          <div className="text-center">Upload</div>
        </Button>
      )}
    </div>
  )}
</form>


          {currentLoadID && (
            <div className="text-center my-4">
              <div>Load ID: {currentLoadID}</div>
              <div>Record Count: {recordCount}</div>
              <div className="flex justify-center my-4">
                <Button onClick={() => loadProcess()} className="standard-button mx-2">
                {isLoading ? <Spinner /> :'Process load'}
                </Button>
                <Button onClick={() => setShowModal(true)} className="standard-button mx-2">
                {isLoading ? <Spinner /> : 'Review load'}
                </Button>
                <Button onClick={handleDeleteLoad} className="standard-button mx-2">
                  {isLoading ? <Spinner /> : 'Delete load'}
                </Button>
                </div>
            </div>
          )}

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                <button 
          onClick={() => closeModal('process')} 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Process
        </button>
        <button 
          onClick={() => closeModal('delete')} 
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Delete
        </button>
                </div>
                <table className="mt-5">
                     <thead>
            <tr>
              <th>Client_ID</th>
              <th>Company_ID</th>
              <th>Employee_ID</th>
              <th>First_Name</th>
              <th>Last_Name</th>
              <th>Post_Code</th>
              <th>Annual_Salary</th>
              <th>Date_of_Birth</th>
              <th>Date_of_Hire</th>
              <th>Date_of_Termination</th>
              <th>Term_Reason</th>
              <th>Country</th>
              <th>Currency</th>
              <th>Performance_Review</th>
              <th>Work_Location_ID</th>
              <th>Department_ID</th>
              <th>Job_ID</th>
              <th>Reports_To</th>
              <th>Last_Salary_Adjustment_Date</th>
              <th>Address_1</th>
              <th>Address_2</th>
              <th>City</th>
              <th>State</th>
              <th>Flight_Risk_Score</th>
              <th>Gender</th>
              <th>Marital_Status</th>
              <th>EEO1</th>
              <th>Period_Date</th>
              <th>Last_Update</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((data, index) => (
              <tr key={index}>
                <td>{data.Client_ID}</td>
                <td>{data.Company_ID}</td>
                <td>{data.Employee_ID}</td>
                <td>{data.First_Name}</td>
                <td>{data.Last_Name}</td>
                <td>{data.Post_Code}</td>
                <td>{data.Annual_Salary}</td>
                <td>{data.Date_of_Birth}</td>
                <td>{data.Date_of_Hire}</td>
                <td>{data.Date_of_Termination}</td>
                <td>{data.Term_Reason}</td>
                <td>{data.Country}</td>
                <td>{data.Currency}</td>
                <td>{data.Performance_Review}</td>
                <td>{data.Work_Location_ID}</td>
                <td>{data.Department_ID}</td>
                <td>{data.Job_ID}</td>
                <td>{data.Reports_To}</td>
                <td>{data.Last_Salary_Adjustment_Date}</td>
                <td>{data.Address_1}</td>
                <td>{data.Address_2}</td>
                <td>{data.City}</td>
                <td>{data.State}</td>
                <td>{data.Flight_Risk_Score}</td>
                <td>{data.Gender}</td>
                <td>{data.Marital_Status}</td>
                <td>{data.EEO1}</td>
                <td>{data.Period_Date}</td>
                <td>{data.Last_Update}</td>
              </tr>
            ))}
            </tbody>
            </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LoadEmployeeComponent;
