import React, {useContext} from "react";
import DashboardAdmin from "../../pages/admin/DashboardAdmin.js";
import Status, {StatusContext} from "../../contexts/Status";
import {Outlet} from "react-router-dom";
import NotFound from "../../global/NotFound.js";
import Spinner from "../../global/Spinner";

const IsExecutive = () => {
  const {SuperAdmin, Executive, Loading} = useContext(StatusContext);

  if (Loading === true) {
    if (Executive === null) {
      return <Spinner />;
    }
  }

  if (SuperAdmin) {
    return <Outlet />;
  } else return Executive ? <Outlet /> : <NotFound />;
};

export default IsExecutive;
