import React from "react";
import Navbar from "../../global/NavbarHR";

const ApproverHR = () => {
  return (
    <>
      <Navbar name="APPROVER"></Navbar>
      <div>Approver</div>
    </>
  );
};

export default ApproverHR;
