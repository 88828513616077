import {CognitoUser} from "amazon-cognito-identity-js";
import {useState} from "react";
import UserPool from "../UserPool";
import Pool from "../UserPool";
import {Navigate, useNavigate} from "react-router-dom";
import {SendEmail} from "../../global/SendMail";
import {createUser} from "../../global/api";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [code, setCode] = useState(false);
  const [veriCode, setVeriCode] = useState("");

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        console.error(err);
        setError(err.message);
      } else {
        setCode(true);
      }
      console.log(data);
    });
    console.log("submitted");
  };

  const requestAnotherVerificationCode = (e) => {
    const user = new CognitoUser({
      Username: email,
      Pool: Pool,
    });
    user.resendConfirmationCode(async (err) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  };

  const confirmUser = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: Pool,
    });

    user.confirmRegistration(veriCode, true, async (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      } else {
        // await createUser({
        //   Email_Address: email,
        //   Client_ID: "",
        //   User_Status: "A",
        //   User_Status_Date: null,
        //   User_Role: "admin",
        //   Admin: false,
        //   Last_Login_Date: null,
        //   Login_History: null,
        //   User_Code: null,
        //   User_Display_Name: null,
        //   User_SMS: null,
        //   Communication_Preference: null,
        //   Notification_Status: null,
        //   User_Role_Scope: null,
        //   Approver: false,
        //   Create_Date: null,
        //   Executive: false,
        //   Recorder: false,
        //   Manager: false,
        //   Super_Admin: false,
        //   Auditor: false,
        //   HR: false,
        // });
        console.log("call result: " + result);
        navigate("/signin");
      }
    });
  };

  return (
    <div className="flex h-screen">
      <div className="m-auto max-w-7xl">
        <div className="flex flex-col md:flex-row">
          <div className="relative w-full bg-cover lg:w-6/12 xl:w-7/12 bg-gradient-to-r from-white via-white to-gray-100">
            <div className="relative flex flex-col items-center justify-center w-full h-full px-10 my-20 lg:px-16 lg:my-0">
              <div className="flex flex-col items-start space-y-8 tracking-tight lg:max-w-3xl">
                <div className="relative">
                  <h2 className="text-5xl font-bold text-gray-900 xl:text-6xl">
                    Happy Hippo
                  </h2>
                </div>
                <p className="text-2xl text-gray-700">
                  Transform your talent retention and DEI initiatives into
                  strategic competitive advantages.
                </p>
                <a
                  href="#_"
                  className="inline-block px-8 py-5 text-xl font-medium text-center text-white transition duration-200 bg-blue-600 rounded-lg hover:bg-blue-700 ease"
                  data-primary="blue-600"
                  data-rounded="rounded-lg"
                >
                  Get Started Today
                </a>
              </div>
            </div>
          </div>

          <div className="w-full bg-white lg:w-6/12 xl:w-5/12">
            <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
              <h4 className="w-full text-3xl font-bold">Signup</h4>
              <p className="text-lg text-gray-500">
                Or, if you have an account you can{" "}
                <a
                  href="/signin"
                  className="text-blue-600 underline"
                  data-primary="blue-600"
                >
                  sign in
                </a>
              </p>
              {!code ? (
                <form onSubmit={onSubmit}>
                  <div className="relative w-full mt-10 space-y-8">
                    <div className="relative">
                      <label className="font-medium text-gray-900">Name</label>
                      <input
                        type="text"
                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="relative">
                      <label className="font-medium text-gray-900">Email</label>
                      <input
                        type="text"
                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                        placeholder="Enter Your Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="relative">
                      <label className="font-medium text-gray-900">
                        Password
                      </label>
                      <input
                        type="password"
                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="text-md text-red-600">{error}</div>

                    <div class="relative">
                      <button
                        href="#_"
                        className="inline-block w-full px-5 py-4 text-lg font-medium text-center text-white transition duration-200 bg-blue-600 rounded-lg hover:bg-blue-700 ease"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={confirmUser}>
                  <div className="relative w-full mt-10 space-y-8">
                    <div className="relative">
                      <label className="font-medium text-gray-900">Email</label>
                      <input
                        type="text"
                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                        placeholder="Enter Your Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="relative">
                      <label className="font-medium text-gray-900">Code</label>
                      <input
                        type="veriCode"
                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                        placeholder="Enter your Verification Code"
                        value={veriCode}
                        onChange={(e) => setVeriCode(e.target.value)}
                      />
                      <a
                        className="text-blue-600 hover:cursor-pointer"
                        data-primary="blue-600"
                        onClick={() => requestAnotherVerificationCode()}
                      >
                        Request another code
                      </a>
                    </div>

                    <div className="text-md text-red-600">{error}</div>

                    <div className="relative">
                      <button
                        href="#_"
                        className="inline-block w-full px-5 py-4 text-lg font-medium text-center text-white transition duration-200 bg-blue-600 rounded-lg hover:bg-blue-700 ease"
                        data-primary="blue-600"
                        data-rounded="rounded-lg"
                      >
                        Confirm Email
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    // {/* // <div className="SignUp">
    // //   <h1>Sign In</h1>
    // //   <form onSubmit={onSubmit}>
    // //     <label htmlFor="email">Email</label>
    // //     <input value={email} onChange={(e) => setEmail(e.target.value)} />
    // //     <label htmlFor="password">Password</label>
    // //     <input value={password} onChange={(e) => setPassword(e.target.value)} />
    // //     <button>SignUp</button>
    // //   </form>
    // // </div> */}
  );
};

export default SignUp;
