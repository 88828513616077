import React, { createContext, useState, useEffect } from 'react';
import { getEmployees, getDepartment, getJobs, getLocations } from '../global/api';

export const EmployeeContext = createContext();

export const EmployeeProvider = ({ children, companyId }) => {
    const [employees, setEmployees] = useState([]);
    const [departmentMap, setDepartmentMap] = useState({});
    const [jobMap, setJobMap] = useState({});
    const [locationMap, setLocationMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (companyId) {
                setLoading(true);
                setError(null);
                try {
                    const [fetchedEmployees, departments, jobs, locations] = await Promise.all([
                        getEmployees(companyId),
                        getDepartment(companyId),
                        getJobs(companyId),
                        getLocations(companyId)
                    ]);

                    setEmployees(fetchedEmployees);

                    setDepartmentMap(departments.reduce((acc, dept) => {
                        acc[dept.Department_ID] = dept.Department_Name;
                        return acc;
                    }, {}));

                    setJobMap(jobs.reduce((acc, job) => {
                        acc[job.Job_ID] = job.Job_Title;
                        return acc;
                    }, {}));

                    setLocationMap(locations.reduce((acc, loc) => {
                        acc[loc.Location_ID] = loc.Location_Name;
                        return acc;
                    }, {}));

                } catch (err) {
                    setError('Failed to fetch data. Please try again later.');
                    console.error('Error fetching data:', err);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [companyId]);

    return (
        <EmployeeContext.Provider value={{ employees, departmentMap, jobMap, locationMap, loading, error }}>
            {children}
        </EmployeeContext.Provider>
    );
};