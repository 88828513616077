import React from "react";
import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const LineChart = (props) => {
  const data = props.data;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        position: "top",
        grid: {
          drawBorder: false,
        },
        border: {
          color: "rgba(0,0,0,0)",
        },
      },
      y: {
        display: false,
        min: 2,
        max: 10,
        ticks: {
          stepSize: 2,
          callback: (value) => value + "K",
        },
        grid: {},
      },
    },
  };

  return (
    <div className="">
      <Line data={data} options={options}></Line>
    </div>
  );
};

export default LineChart;
