import React from "react";
import Navbar from "../../global/NavbarHR";

const ActionsHR = () => {
  return (
    <>
      <Navbar name="ACTION"></Navbar>
      <div>Actions</div>
    </>
  );
};

export default ActionsHR;
