import React, { useMemo, useContext, useState } from 'react';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import { StatusContext } from '../../contexts/Status';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { Tooltip } from 'react-tooltip';
import EmployeeDetailsModal from './EmployeeDetailsModal';

// Default Column Filter
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter, id },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={id === 'Last_Name' ? `Search ${count} records...` : `Search...`}
      className="w-full p-1 text-sm border rounded"
    />
  );
};

const EmployeeGrid = () => {
    const { CompanyID } = useContext(StatusContext);
    const { employees, departmentMap, jobMap, locationMap, loading, error } = useContext(EmployeeContext);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

    const columns = useMemo(() => [
        { 
            Header: 'Last Name', 
            accessor: 'Last_Name',
            Filter: DefaultColumnFilter,
        },
        { 
            Header: 'First Name', 
            accessor: 'First_Name',
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Flight Risk',
            accessor: 'Flight_Risk_Score',
            disableFilters: true,
            Cell: ({ value }) => {
                const score = parseFloat(value);
                let risk = 'Low';
                if (score >= 0.7) risk = 'High';
                else if (score >= 0.4) risk = 'Medium';
                return <span className={`px-2 py-1 rounded ${getRowColor(score)}`}>{risk}</span>;
            }
        },
        {
            Header: 'Hire Date',
            accessor: 'Date_of_Hire',
            Filter: DefaultColumnFilter,
            Cell: ({ value }) => {
                const date = new Date(value);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            }
        },
        {
            Header: 'Last Salary Update',
            accessor: 'Last_Salary_Adjustment_Date',
            Filter: DefaultColumnFilter,
            Cell: ({ value }) => {
                const date = new Date(value);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            }
        },
        { 
            Header: 'Annual Salary', 
            accessor: 'Annual_Salary',
            Filter: DefaultColumnFilter,
        },
        { 
            Header: 'Location', 
            accessor: 'Work_Location_ID',
            Filter: DefaultColumnFilter,
            Cell: ({ value }) => locationMap?.[value] || value
        },
        { 
            Header: 'Job', 
            accessor: 'Job_ID',
            Filter: DefaultColumnFilter,
            Cell: ({ value }) => jobMap?.[value] || value
        },
        { 
            Header: 'Department', 
            accessor: 'Department_ID',
            Filter: DefaultColumnFilter,
            Cell: ({ value }) => departmentMap?.[value] || value
        },
    ], [departmentMap, jobMap, locationMap]);

    const getRowColor = (score) => {
        if (score >= 0.7) return 'bg-red-200 text-red-800';
        if (score >= 0.4) return 'bg-yellow-200 text-yellow-800';
        return 'bg-green-200 text-green-800';
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: employees,
            initialState: { pageIndex: 0, pageSize: 10 },
            defaultColumn: { Filter: DefaultColumnFilter },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const handleRowClick = (employee) => {
        setSelectedEmployeeId(employee.Employee_ID);
        setShowDetailsModal(true);
    };

    const closeModal = () => {
        setShowDetailsModal(false);
        setSelectedEmployeeId(null);
    };

    if (loading) {
        return <div className="text-center py-4">Loading employee data...</div>;
    }

    if (error) {
        return <div className="text-center py-4 text-red-600">Error: {error}</div>;
    }

    return (
        <>
            <div className="overflow-x-auto">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map(headerGroup => (
                            <>
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th 
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    {headerGroup.headers.map(column => (
                                        <th className="px-6 py-3">
                                            {column.canFilter ? column.render('Filter') : null}
                                        </th>
                                    ))}
                                </tr>
                            </>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr 
                                    {...row.getRowProps()} 
                                    className="hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleRowClick(row.original)}
                                    data-tip={`ID: ${row.original.Employee_ID}, Name: ${row.original.First_Name} ${row.original.Last_Name}`}
                                >
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex items-center justify-between">
                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="mr-2 px-4 py-2 border border-gray-300 rounded disabled:opacity-50">
                        {'<<'}
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="mr-2 px-4 py-2 border border-gray-300 rounded disabled:opacity-50">
                        {'<'}
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="mr-2 px-4 py-2 border border-gray-300 rounded disabled:opacity-50">
                        {'>'}
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-4 py-2 border border-gray-300 rounded disabled:opacity-50">
                        {'>>'}
                    </button>
                </div>
                <span className="text-sm text-gray-700">
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}
                    className="p-2 border border-gray-300 rounded"
                >
                    {[10, 20, 30, 40, 50].map(size => (
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    ))}
                </select>
            </div>
            {showDetailsModal && selectedEmployeeId && (
                <EmployeeDetailsModal 
                    CompanyId={CompanyID}
                    employeeId={selectedEmployeeId}
                    onClose={closeModal}                
                />
            )}
            <Tooltip place="top" type="dark" effect="float" />
        </>
    );
};

export default EmployeeGrid;