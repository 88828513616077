import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../global/Spinner';
import { Button, IconButton } from '@material-tailwind/react';
import { FiPlus } from 'react-icons/fi';
import { getUsersEntitleByClient, createUserEntitlement, updateUserEntitlement, deleteUserEntitlement } from '../../global/api';
import { StatusContext } from '../../contexts/Status';

const StepFour = () => {
    const { ClientID, CompanyName } = useContext(StatusContext);
    const [entitlements, setEntitlements] = useState([]);
    const [newEntitlement, setNewEntitlement] = useState({
        Email_Address: '',
        Company_ID: ClientID,
        User_Display_Name: '',
        Role: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getUsersEntitleByClient(ClientID).then(response => {
            setEntitlements(response || []);
            setIsLoading(false);
        }).catch(err => {
            setError('Error fetching user entitlements: ' + err.message);
            setIsLoading(false);
        });
    }, [ClientID]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedEntitlements = [...entitlements];
        updatedEntitlements[index][name] = value;
        setEntitlements(updatedEntitlements);
    };

    const handleNewEntitlementChange = (e) => {
        const { name, value } = e.target;
        setNewEntitlement(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddClick = async () => {
        if (!newEntitlement.Email_Address.includes('@')) {
            alert("Please enter a valid email address.");
            return;
        }
        setIsLoading(true);
        try {
            const response = await createUserEntitlement(newEntitlement);
            setEntitlements([...entitlements, response]);
            setNewEntitlement({ Email_Address: '', Company_ID: ClientID, User_Display_Name: '', Role: '' }); // Reset form
        } catch (err) {
            setError('Error adding new entitlement: ' + err.message);
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateClick = async (index) => {
        setIsLoading(true);
        try {
            await updateUserEntitlement(entitlements[index]);
            alert('Entitlement updated successfully.');
        } catch (err) {
            setError('Error updating entitlement: ' + err.message);
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClick = async (index) => {
        setIsLoading(true);
        try {
            await deleteUserEntitlement(entitlements[index].Email_Address);
            const updatedEntitlements = entitlements.filter((_, i) => i !== index);
            setEntitlements(updatedEntitlements);
            alert('Entitlement deleted successfully.');
        } catch (err) {
            setError('Error deleting entitlement: ' + err.message);
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto my-4 p-4">
            {isLoading && <Spinner />}
            {error && <div className="text-red-500">{error}</div>}
            <h2 className="text-xl font-semibold mb-4">User Entitlements for {CompanyName}</h2>
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr className="bg-gray-200">
                        <th>Email Address</th>
                        <th>User Display Name</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {entitlements.map((entitlement, index) => (
                        <tr key={index}>
                            <td><input type="text" value={entitlement.Email_Address} onChange={e => handleInputChange(e, index)} /></td>
                            <td><input type="text" value={entitlement.User_Display_Name} onChange={e => handleInputChange(e, index)} /></td>
                            <td>
                                <select value={entitlement.Role} onChange={e => handleInputChange(e, index)}>
                                    <option value="Admin">Admin</option>
                                    <option value="Approver">Approver</option>
                                    <option value="Executive">Executive</option>
                                    <option value="Recorder">Recorder</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Auditor">Auditor</option>
                                    <option value="HR">HR</option>
                                </select>
                            </td>
                            <td>
                                <Button color="red" onClick={() => handleDeleteClick(index)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="my-4">
                <input type="text" placeholder="Email Address" value={newEntitlement.Email_Address} onChange={handleNewEntitlementChange} name="Email_Address" className="input input-bordered mr-2" />
                <input type="text" placeholder="Display Name" value={newEntitlement.User_Display_Name} onChange={handleNewEntitlementChange} name="User_Display_Name" className="input input-bordered mr-2" />
                <select value={newEntitlement.Role} onChange={handleNewEntitlementChange} name="Role" className="select select-bordered mr-2">
                    <option value="">Select Role</option>
                    <option value="Admin">Admin</option>
                    <option value="Approver">Approver</option>
                    <option value="Executive">Executive</option>
                    <option value="Recorder">Recorder</option>
                    <option value="Manager">Manager</option>
                    <option value="Auditor">Auditor</option>
                    <option value="HR">HR</option>
                </select>
                <IconButton onClick={handleAddClick} className="text-green-500">
                    <FiPlus />
                </IconButton>
            </div>
        </div>
    );
};

export default StepFour;
