import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../global/Spinner';
import { Button } from '@material-tailwind/react';
import Papa from 'papaparse';
import { getJobs, getJobDetails, createJobDetail, deleteJobDetail, updateJobDetail } from '../../global/api';
import { StatusContext } from '../../contexts/Status';

const StepSeven = () => {
    const { CompanyID } = useContext(StatusContext);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [manualRows, setManualRows] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getJobDetails(CompanyID).then(response => {
            setIsLoading(false);
            if (response) {
                setManualRows(response);
            } else {
                setError('Failed to fetch job details');
            }
        }).catch(err => {
            setError('Error fetching job details: ' + err.message);
            setIsLoading(false);
        });
    }, [CompanyID]);

    const handleAddRow = () => {
        setManualRows([...manualRows, { Job_ID: '', Job_Title: '', Job_Number: '', EEO_Lvl1: '', Salary_Min: '', Salary_Max: '', Exempt: '' }]);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...manualRows];
        updatedRows[index][name] = value;
        setManualRows(updatedRows);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    const parseCSV = () => {
      Papa.parse(file, {
          complete: (results) => {
              // Assuming the CSV matches the manualRows structure
              setManualRows(results.data);
              // Remove setShowCsvModal(false); as it's not defined or needed based on the provided context
          },
          header: true,
      });
  };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Example logic for saving
            await Promise.all(manualRows.map(row => {
                return row.Job_ID ? updateJobDetail(row) : createJobDetail({...row, Company_ID: CompanyID});
            }));
            alert('Job details saved successfully.');
        } catch (error) {
            setError('Error saving job details: ' + error.message);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto my-4">
            <Spinner loading={isLoading} />
            {error && <div className="text-red-500">{error}</div>}
            <div className="flex justify-between items-center w-full mb-4">
                <Button color="blue" onClick={handleAddRow}>Add Job Detail</Button>
                <input type="file" onChange={handleFileChange} accept=".csv" />
                <Button color="green" onClick={handleSave}>Save</Button>
            </div>
            <div className="overflow-x-auto w-full">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3 px-6">Job ID</th>
                            <th scope="col" className="py-3 px-6">Job Title</th>
                            <th scope="col" className="py-3 px-6">Job Number</th>
                            <th scope="col" className="py-3 px-6">EEO Lvl1</th>
                            <th scope="col" className="py-3 px-6">Salary Min</th>
                            <th scope="col" className="py-3 px-6">Salary Max</th>
                            <th scope="col" className="py-3 px-6">Exempt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {manualRows.map((row, index) => (
                            <tr key={index} className="bg-white border-b">
                                <td className="py-4 px-6">{row.Job_ID}</td>
                                <td className="py-4 px-6">{row.Job_Title}</td>
                                <td className="py-4 px-6">{row.Job_Number}</td>
                                <td className="py-4 px-6">{row.EEO_Lvl1}</td>
                                <td className="py-4 px-6">{row.Salary_Min}</td>
                                <td className="py-4 px-6">{row.Salary_Max}</td>
                                <td className="py-4 px-6">{row.Exempt}</td>
                                {/* Add more cells for other attributes as needed */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StepSeven;
