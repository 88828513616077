import React, {useContext} from "react";
import {AccountContext} from "../contexts/Account";

const Profile = () => {
  const {logout} = useContext(AccountContext);

  return <div>Profile</div>;
};

export default Profile;
