import React from "react";
import Navbar from "../../global/NavbarHR";

const OrgHR = () => {
  return (
    <>
      <Navbar name="MY ORG"></Navbar>
      <div>My Org</div>
    </>
  );
};

export default OrgHR;
