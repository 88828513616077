import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import Main from "../../global/Main";
import Spinner from "../../global/Spinner";
import Home from "../../pages/Home";
import SignIn from "./SignIn";
import {StatusContext} from "../../contexts/Status";
import AuditorPage from "../../pages/Auditor";
import RecorderPage from "../../pages/Recorder";
import ExecutivePage from "../../pages/Executive";
import ApproverPage from "../../pages/approver/Dashboard";
import DashboardHR from "../../pages/HR/Dashboard";
import NoUser from "./NoUser";

const IsSignedIn = () => {
  const {AuthStatus, Approver, Auditor, HR, Executive, Recorder, isThereUser} =
    useContext(StatusContext);

  return AuthStatus === undefined ? (
    <Spinner />
  ) : AuthStatus ? (
    isThereUser ? (
      Approver ? (
        <ApproverPage />
      ) : Auditor ? (
        <AuditorPage />
      ) : HR ? (
        <DashboardHR />
      ) : Executive ? (
        <ExecutivePage />
      ) : Recorder ? (
        <RecorderPage />
      ) : (
        <Main />
      )
    ) : (
      <NoUser />
    )
  ) : (
    <Outlet />
  );
};

export default IsSignedIn;
