import React, {useState, useEffect, useContext} from "react";
import {getClients} from "../../global/api"; // Added import for getClients, make sure to add it in your actual API file
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Step,
  Stepper,
  Tab,
  Tabs,
  TabPanel,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";
import Sidebar from "../admin/Sidebar";
import LoadEmployeeComponent from "./LoadEmployeeComponent";
import {useParams} from "react-router-dom";
import Onboarding_Tabs from "./Onboarding_Tabs";
import { StatusContext } from "../../contexts/Status";
import TopNavigation from "../../components/common/TopNavigation";


const Onboarding = () => {
  const {     
    ClientID, 
    ClientName, 
    CompanyID, 
    CompanyName} = useContext(StatusContext);
  


  return (
    <div>
      {/* Render TopNavigation as a sibling, not as a parent */}
      <TopNavigation />
      <div className="flex justify-center items-center">
      </div>
      <Onboarding2 /> {/* Render the main onboarding content */}
    </div>
  );
};

const Onboarding2 = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(true);

  const data = [
    {
      label: "Employee",
      value: "employee",
    },
    {
      label: "Company",
      value: "company",
    },
    {
      label: "User",
      value: "user",
    },
    {
      label: "Location",
      value: "location",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Job",
      value: "job",
    },
  ];

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col items-center justify-center font-fabriga mt-10 mx-auto">
        <Onboarding_Tabs />
      </div>
    </div>
  );
};

export default Onboarding;
