import React, {useState,useContext, useEffect} from "react";
import {getClients} from "../../global/api"; // Added import for getClients, make sure to add it in your actual API file
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Step,
  Stepper,
  Tab,
  Tabs,
  TabPanel,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";
import Sidebar from "../admin/Sidebar";
import LoadEmployeeComponent from "./LoadEmployeeComponent";
import {useParams} from "react-router-dom";
import { StatusContext } from "../../contexts/Status";

const Onboarding_Tabs = () => {
  //const clientID = decodedClientID;  // You should define clientID or import it
  const {     
    ClientID, 
    ClientName, 
    CompanyID, 
    CompanyName} = useContext(StatusContext);
  




  return (
    <div>
      <div className="flex justify-center items-center">
      </div>
      <Onboarding2 />
    </div>
  );
};

const Onboarding2 = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(true);

  const data = [
    {
      label: "Employee",
      value: "employee",
    },
    {
      label: "Company",
      value: "company",
    },
    {
      label: "User",
      value: "user",
    },
    {
      label: "Location",
      value: "location",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Job",
      value: "job",
    },
  ];

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div className="flex flex-col items-center justify-center font-fabriga mt-10 mx-auto">
      <Card className="flex items-center justify-center">
        <Tabs value="employee">
          <TabsHeader>
            {data.map(({label, value}) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({value, desc}) => (
              <TabPanel key={value} value={value}>
                {value === "employee" ? (
                  <LoadEmployeeComponent />
                ) : value === "company" ? (
                  <StepThree />
                ) : value === "user" ? (
                  <StepFour />
                ) : value === "location" ? (
                  <StepFive />
                ) : value === "department" ? (
                  <StepSix />
                ) : value === "job" ? (
                  <StepSeven />
                ) : null}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </Card>
    </div>
  );
};

export default Onboarding_Tabs;
