import React, {useContext, useState} from "react";
import {StatusContext} from "../../contexts/Status";
import Navbar from "../../global/NavbarAdmin";
import DepartmentTableComponent from "./Components/Department/DepartmentTableComponent";
import {useEffect} from "react";
import Spinner from "../../global/Spinner";
import {getJobs} from "../../global/api";
import Sidebar from "./Sidebar";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import StepFive from "../onboarding/StepFive";
import {useParams} from "react-router-dom";

const ConfigJobs = () => {
  const [awaitJobs, setAwaitJobs] = useState(true);
  const [jobs, setJobs] = useState(null);

  const {
    Email, ClientID, ClientName, CompanyID, CompanyName,
  } = useContext(StatusContext);

  const fetchJobs = async () => {
    setAwaitJobs(true);

    const jobs = await getJobs(CompanyID);
    console.log("Jobs", jobs);
    // setJobs(jobs);

    // Convert ISO 8601 date strings to Date objects
    const jobsWithDateObjects = jobs.map((job) => ({
      ...job,
      Period_Date: new Date(job.Period_Date),
    }));

    // Find the maximum date in the array of jobs
    const maxDate = new Date(
      Math.max(...jobsWithDateObjects.map((job) => job.Period_Date.getTime()))
    );

    // Filter jobs with the maximum date
    const jobsWithMaxDate = jobsWithDateObjects.filter(
      (job) => job.Period_Date.getTime() === maxDate.getTime()
    );
    const newJobList = [];
    // Print the jobs with the last period date
    jobsWithMaxDate.forEach((job) => {
      // console.log(job);
      newJobList.push(job);
    });
    setCurrentJobEdit(newJobList[0]);
    setJobs(newJobList);
    setAwaitJobs(false);
  };

  const handleComplete = () => {
    console.log("complete");
  };

  useEffect(() => {
    if (CompanyID) {
      fetchJobs();
    }
  }, [CompanyID]);

  const ITEMS_PER_PAGE = 10;

  const [currentPage, setCurrentPage] = useState(1);

  // Function to get the data for the current page
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Filter the data based on the search query
    const filteredData = jobs.filter((employee) =>
      `${employee.Job_Title}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice(startIndex, endIndex);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const TABLE_HEAD = [
    "Job Title",
    "EEOLVL1",
    "Min Salary",
    "Max Salary",
    "Total Risk",
    "Edit",
  ];

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => setOpenEdit(!openEdit);

  const [currentEdit, setCurrentEdit] = useState(null);
  const [currentJobEdit, setCurrentJobEdit] = useState();

  const findEdit = (job) => {
    if (job.Job_ID === currentEdit) {
      setCurrentJobEdit(job);
    }
  };
  return (
    <>
      {/* <Navbar name="CRITICAL JOBS"></Navbar> */}
      {jobs === null ? (
        <Spinner />
      ) : (
        <div className="flex">
          <Sidebar />
          <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
            <div className="justify-center items-center m-auto w-4/5">
              <Card className="h-full w-4/5">
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="rounded-none"
                >
                  <div className="mb-8 flex items-center justify-between gap-8">
                    <div className="flex justify-between">
                      <Typography variant="h5" color="blue-gray">
                        Job List
                      </Typography>
                    </div>
                    <Button onClick={() => handleOpen()}>Load More</Button>
                    <Dialog open={open} handler={handleOpen}>
                      <DialogHeader>Load Department Data</DialogHeader>
                      <DialogBody divider>
                        <StepFive />
                      </DialogBody>
                      <DialogFooter>
                        <Button
                          variant="text"
                          color="red"
                          onClick={handleOpen}
                          className="mr-1"
                        >
                          <span>Cancel</span>
                        </Button>
                        <Button
                          variant="gradient"
                          color="green"
                          onClick={handleOpen}
                        >
                          <span>Finish</span>
                        </Button>
                      </DialogFooter>
                    </Dialog>
                    <Dialog open={openEdit} handler={handleOpenEdit}>
                      <DialogHeader>Edit Job</DialogHeader>
                      <DialogBody divider>
                        <Input
                          label="Job Title"
                          value={currentJobEdit.Job_Title}
                        />
                        <Input
                          label="EEOLVL1"
                          value={currentJobEdit.EEO_Lvl1}
                        />
                        <Input
                          label="Min Salary"
                          value={currentJobEdit.Salary_Min}
                        />
                        <Input
                          label="Max Salary"
                          value={currentJobEdit.Salary_Max}
                        />
                        <div className="flex flex-row items-center">
                          <Typography>Critical ? </Typography>
                          <Checkbox defaultChecked />
                        </div>
                      </DialogBody>
                      <DialogFooter>
                        <Button
                          variant="text"
                          color="red"
                          onClick={handleOpenEdit}
                          className="mr-1"
                        >
                          <span>Cancel</span>
                        </Button>
                        <Button
                          variant="gradient"
                          color="green"
                          onClick={handleOpenEdit}
                        >
                          <span>Confirm</span>
                        </Button>
                      </DialogFooter>
                    </Dialog>
                  </div>
                  <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <div className="w-full md:w-72">
                      <Input
                        label="Search"
                        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="overflow-scroll px-0">
                  <table className="mt-4 w-full table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={head}
                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                            >
                              {head}{" "}
                              {index !== TABLE_HEAD.length - 1 && (
                                <ChevronUpDownIcon
                                  strokeWidth={2}
                                  className="h-4 w-4"
                                />
                              )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentPageData().map(
                        (
                          {
                            Job_ID,
                            Job_Title,
                            EEO_Lvl1,
                            Salary_Min,
                            Salary_Max,
                            total_risk,
                          },
                          index
                        ) => {
                          const isLast = index === jobs.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <>
                              <tr
                                key={Job_ID}
                                className="hover:bg-blue-gray-50 hover:cursor-pointer"
                              >
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Job_Title}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {EEO_Lvl1}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Salary_Min}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Salary_Max}
                                    </Typography>
                                  </div>
                                </td>

                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {total_risk}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <td className={classes}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                      onClick={() => {
                                        findEdit(jobs);
                                        setCurrentEdit(Job_ID);
                                        handleOpenEdit();
                                      }}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                      />
                                    </svg>
                                  </td>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </CardBody>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page {currentPage} of{" "}
                    {Math.ceil(jobs.length / ITEMS_PER_PAGE)}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage === Math.ceil(jobs.length / ITEMS_PER_PAGE)
                      }
                    >
                      Next
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const modal = () => {
  return <div></div>;
};

export default ConfigJobs;
