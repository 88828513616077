import React, {useState} from "react";

export default function CSVTest() {
  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    // console.log(e.target);
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    var array = string.toString().split(" ");
    //  console.log(array); here we are getting the first rows which is our header rows to convert it into keys we are logging it here
    var data = [];
    // console.log(data);
    for (const r of array) {
      // console.log(r);
      let row = r.toString().split(",");
      data.push(row);
    }
    console.log(data);
    var heading = data[0];
    // console.log(heading); to get the column headers which will act as key
    var ans_array = [];
    // console.log(ans_array);
    for (var i = 1; i < data.length; i++) {
      var row = data[i];
      var obj = {};
      for (var j = 0; j < heading.length; j++) {
        if (!row[j]) {
          row[j] = "NA";
        }
        // console.log(row[j].toString())
        obj[heading[j].replaceAll(" ", "_")] = row[j]
          .toString()
          .replaceAll(" ", "_");
      }
      ans_array.push(obj);
    }
    console.log({ans_array});
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        //  console.log(event);
        //  console.log(event.target.result);
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <div style={{textAlign: "center"}}>
      <h1 style={{color: "red"}}>CSV PARSER</h1>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <button
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          IMPORT CSV
        </button>
      </form>

      <br />
    </div>
  );
}
