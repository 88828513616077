import React from 'react';
import { useCompanyData } from '../../contexts/CompanyContext';
import StatisticsChart from './DataChart';
import { prepareChartDataCompany } from '../data/prepareChartDataCompany';
import SkeletonChart from '../common/SkeletonChart';

export const RenderCompanyCharts = () => {
    const { companyData } = useCompanyData();

    if (!companyData) {
        return <SkeletonChart />;
    }
 
    const allChartData = prepareChartDataCompany(companyData);

    // Define chart configurations
    const chartConfig = [
        {
            title: "Total Employees Over Time",
            description: "Line chart showing total employees",
            seriesNames: ["count_total", "count_total_critical", "count_male", "count_female"],
            colors: ["#FF4560", "#00E396", "#008FFB", "#FEB019"]
        },
        {
            title: "Risk Analysis Over Time",
            description: "Line chart showing risk analysis",
            seriesNames: ["total_risk", "total_risk_critical"],
            colors: ["#775DD0", "#FEB019"]
        }
        // Add more configurations as needed
    ];

    // Styling for side-by-side layout
    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '20px'
    };

    return (
        <div style={chartContainerStyle}>
            {chartConfig.map((config, index) => {
                const series = config.seriesNames.map(name => ({
                    name: name,
                    data: allChartData[name],
                }));

                const chartOptions = {
                    chart: {
                        toolbar: {
                            show: false
                        },
                    },
                    colors: config.colors,
                    dataLabels: {
                        style: {
                            colors: ['#000']
                        }
                    },
                    title: {
                        style: {
                            color: '#000'
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            style: {
                                colors: ['#000'],
                            },
                            formatter: (value) => {
                                return new Date(value).toLocaleDateString();
                            },
                        }
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: ['#000']
                            }
                        }
                    },
                    tooltip: {
                        style: {
                            fontSize: '12px',
                            colors: ['#000']
                        }
                    }
                    // Additional chart options as needed
                };

                return (
                    <div key={`chart-${index}`} style={{ width: '48%' }}>
                        <StatisticsChart className="w-full flex flex-wrap md:w-1/2 lg:w-1/4 space-between -mx-2"
                            color="blue"
                            chart={{
                                options: chartOptions,
                                series: series,
                                type: 'line'
                            }}
                            title={config.title}
                            description={config.description}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default RenderCompanyCharts;
