import React from "react";
import Navbar from "../../../global/NavbarSuper";

const EmailDashboard = () => {
  return (
    <>
      <Navbar name="EMAIL" />
      <div className="bg-[#F2F2F2] flex flex-col min-h-screen items-center font-fabriga mx-auto">
        <div className="mt-10 ml-10 flex flex-row">
          <a href="/super/email/test">
            <button
              className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Test Email
            </button>
          </a>
          <a href="/super/email/config">
            <button
              className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Config
            </button>
          </a>
          <a href="/super/email/analytics">
            <button
              className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Analytics
            </button>
          </a>
        </div>
      </div>
      ;
    </>
  );
};

export default EmailDashboard;
