import React, {useState, useContext} from "react";
import Navbar from "../../global/NavbarAdmin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {StatusContext} from "../../contexts/Status";
import {useEffect} from "react";
import {v4 as uuidv4} from "uuid";
import Spinner from "../../global/Spinner";
import {
  createAction,
  createDepartment,
  deleteAction,
  getActions,
  updateAction,
} from "../../global/api";
import Sidebar from "./Sidebar";

const Actions = () => {
  const {
    Email, ClientID, ClientName, CompanyID, CompanyName,
  } = useContext(StatusContext);
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [currentEdit, setCurrentEdit] = useState("");

  const [awaitActions, setAwaitActions] = useState(true);
  const [actions, setActions] = useState(null);

  const [openTab, setOpenTab] = React.useState(1);

  const fetchActions = async () => {
    setAwaitActions(true);

    const actions = await getActions(CompanyID);
    console.log(actions);
    setActions(actions);

    setAwaitActions(false);
  };

  useEffect(() => {
    if (CompanyID) {
      fetchActions();
    }
  }, [CompanyID]);

  const handleDeleteAction = async (guid) => {
    await deleteAction(CompanyID, guid);
    window.location.reload(false);
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="flex flex-col bg-[#F2F2F2] min-h-screen items-center font-fabriga w-screen">
          <div className="mt-10"></div>
          <div className="flex flex-wrap w-4/5">
            <div className="w-full">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 1
                        ? "text-white bg-purple-600"
                        : "text-purple-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Free
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 2
                        ? "text-white bg-purple-600"
                        : "text-purple-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link2"
                    role="tablist"
                  >
                    Low
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 3
                        ? "text-white bg-purple-600"
                        : "text-purple-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                    }}
                    data-toggle="tab"
                    href="#link3"
                    role="tablist"
                  >
                    Medium
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 4
                        ? "text-white bg-purple-600"
                        : "text-purple-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(4);
                    }}
                    data-toggle="tab"
                    href="#link4"
                    role="tablist"
                  >
                    High
                  </a>
                </li>
              </ul>
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
                <div className="px-4 py-5 flex-auto">
                  <div className="tab-content tab-space">
                    <div
                      className={openTab === 1 ? "block" : "hidden"}
                      id="link1"
                    >
                      {actions === null ? (
                        <Spinner />
                      ) : (
                        <ul>
                          {actions
                            .filter(
                              (action) => action.Action_Group_ID === "Free"
                            )
                            .map((action) => (
                              <div className="my-10 w-full h-1/4 bg-white  font-fabriga rounded-2xl">
                                <div className="py-6 px-8 flex flex-row justify-between">
                                  <div className="flex flex-row items-center space-x-5 my-4">
                                    <div className="text-4xl font-bold">
                                      {action.Action_Name}
                                    </div>
                                    <div className="text-4xl">
                                      {action.Action_Variables.actionCost}
                                    </div>
                                  </div>

                                  <div className="flex flex-row space-x-3">
                                    <div
                                      className="cursor-pointer"
                                      data-modal-target="defaultModal"
                                      data-modal-toggle="defaultModal"
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className="text-2xl text-purple-600"
                                        onClick={() => {
                                          setShowModalEdit(true);
                                          setCurrentEdit(action.Action_ID);
                                        }}
                                      />
                                    </div>
                                    <div className="cursor-pointer">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-2xl text-red-500"
                                        onClick={() =>
                                          handleDeleteAction(action.Action_ID)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </ul>
                      )}
                    </div>
                    <div
                      className={openTab === 2 ? "block" : "hidden"}
                      id="link2"
                    >
                      {actions === null ? null : (
                        <ul>
                          {actions
                            .filter(
                              (action) => action.Action_Group_ID === "Low"
                            )
                            .map((action) => (
                              <div className="my-10 w-full h-1/4 bg-white  font-fabriga rounded-2xl">
                                <div className="py-6 px-8 flex flex-row justify-between">
                                  <div className="flex flex-row items-center space-x-5 my-4">
                                    <div className="text-4xl font-bold">
                                      {action.Action_Name}
                                    </div>
                                    <div className="text-4xl">
                                      {action.Action_Variables.actionCost}
                                    </div>
                                  </div>

                                  <div className="flex flex-row space-x-3">
                                    <div
                                      className="cursor-pointer"
                                      data-modal-target="defaultModal"
                                      data-modal-toggle="defaultModal"
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className="text-2xl text-purple-600"
                                        onClick={() => {
                                          setShowModalEdit(true);
                                          setCurrentEdit(action.Action_ID);
                                        }}
                                      />
                                    </div>
                                    <div className="cursor-pointer">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-2xl text-red-500"
                                        onClick={() =>
                                          handleDeleteAction(action.Action_ID)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </ul>
                      )}
                    </div>
                    <div
                      className={openTab === 3 ? "block" : "hidden"}
                      id="link3"
                    >
                      {actions === null ? null : (
                        <ul>
                          {actions
                            .filter(
                              (action) => action.Action_Group_ID === "Med"
                            )
                            .map((action) => (
                              <div className="my-10 w-full h-1/4 bg-white  font-fabriga rounded-2xl">
                                <div className="py-6 px-8 flex flex-row justify-between">
                                  <div className="flex flex-row items-center space-x-5 my-4">
                                    <div className="text-4xl font-bold">
                                      {action.Action_Name}
                                    </div>
                                    <div className="text-4xl">
                                      {action.Action_Variables.actionCost}
                                    </div>
                                  </div>

                                  <div className="flex flex-row space-x-3">
                                    <div
                                      className="cursor-pointer"
                                      data-modal-target="defaultModal"
                                      data-modal-toggle="defaultModal"
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className="text-2xl text-purple-600"
                                        onClick={() => {
                                          setShowModalEdit(true);
                                          setCurrentEdit(action.Action_ID);
                                        }}
                                      />
                                    </div>
                                    <div className="cursor-pointer">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-2xl text-red-500"
                                        onClick={() =>
                                          handleDeleteAction(action.Action_ID)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </ul>
                      )}
                    </div>
                    <div
                      className={openTab === 4 ? "block" : "hidden"}
                      id="link4"
                    >
                      {actions === null ? null : (
                        <ul>
                          {actions
                            .filter(
                              (action) => action.Action_Group_ID === "High"
                            )
                            .map((action) => (
                              <div className="my-10 w-full h-1/4 bg-white  font-fabriga rounded-2xl">
                                <div className="py-6 px-8 flex flex-row justify-between">
                                  <div className="flex flex-row items-center space-x-5 my-4">
                                    <div className="text-4xl font-bold">
                                      {action.Action_Name}
                                    </div>
                                    <div className="text-4xl">
                                      {action.Action_Variables.actionCost}
                                    </div>
                                  </div>

                                  <div className="flex flex-row space-x-3">
                                    <div
                                      className="cursor-pointer"
                                      data-modal-target="defaultModal"
                                      data-modal-toggle="defaultModal"
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className="text-2xl text-purple-600"
                                        onClick={() => {
                                          setShowModalEdit(true);
                                          setCurrentEdit(action.Action_ID);
                                        }}
                                      />
                                    </div>
                                    <div className="cursor-pointer">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-2xl text-red-500"
                                        onClick={() =>
                                          handleDeleteAction(action.Action_ID)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10"></div>
          <button
            className="fixed bottom-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Add New Action
          </button>

          {/* <Action
          department="Finance-HQ"
          cost="2400"
          modal="defaultModal"
        ></Action>
        <Action department="Manufacturing-MO" cost="400"></Action>
        <Action department="IT-Tech-NYC-B1" cost="40"></Action>
        <Action department="SAP-MM-SFO-O2" cost="40"></Action>
        <Action department="ERP-HQ-FPARK" cost="40"></Action>
        <button onClick={() => toggleModal()}>open</button> */}

          {showModal ? <ModalAddNew setShowModal={setShowModal} /> : null}
          {showModalEdit ? (
            <ModalEdit
              setShowModalEdit={setShowModalEdit}
              Action_ID={currentEdit}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const ModalAddNew = (props) => {
  const [actionName, setActionName] = useState("");
  const [actionCost, setActionCost] = useState("");
  const [actionStatus, setActionStatus] = useState(false);

  const [actionFree, setActionFree] = useState(false);
  const [actionLow, setActionLow] = useState(false);
  const [actionMed, setActionMed] = useState(false);
  const [actionHigh, setActionHigh] = useState(false);

  const {CompanyID} = useContext(StatusContext);

  const handleGroup = (actionGroup) => {
    if (actionGroup === "Free") {
      setActionFree(true);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
    } else if (actionGroup === "Low") {
      setActionFree(false);
      setActionLow(true);
      setActionMed(false);
      setActionHigh(false);
    } else if (actionGroup === "Med") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(true);
      setActionHigh(false);
    } else if (actionGroup === "High") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(true);
    }
  };

  const currentGroup = () => {
    if (actionFree) {
      return "Free";
    } else if (actionLow) {
      return "Low";
    } else if (actionMed) {
      return "Med";
    } else if (actionHigh) {
      return "High";
    }
  };

  const handleSubmit = async () => {
    await createAction(CompanyID, {
      Company_ID: CompanyID,
      Action_Group_ID: currentGroup(),
      Work_Location_ID: "456",
      Action_Name: actionName,
      Action_ID: "Act" + uuidv4().toString().slice(0, 3),
      Action_Variables: {actionCost},
      Action_Status: actionStatus,
      Action_Status_Date: "2023-04-18T00:00:00.000Z",
      cb_only: false,
    });

    window.location.reload(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add New Action</h3>
            </div>
            {/*body*/}
            <form className="relative p-6 flex-auto">
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Action Name
                  </label>
                </div>
                <div class="md:w-2/3">
                  <input
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    value={actionName}
                    onChange={(e) => setActionName(e.target.value)}
                  />
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Action Group
                  </label>
                </div>
                <div class="md:w-2/3">
                  <div className="w-2/3 justify-evenly">
                    <button
                      className={
                        actionFree
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("Free")}
                    >
                      Free
                    </button>
                    <button
                      className={
                        actionLow
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("Low")}
                    >
                      Low
                    </button>
                    <button
                      className={
                        actionMed
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("Med")}
                    >
                      Medium
                    </button>
                    <button
                      className={
                        actionHigh
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("High")}
                    >
                      High
                    </button>
                  </div>
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-password"
                  >
                    Cost ?
                  </label>
                </div>
                <div class="md:w-2/3">
                  <input
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-password"
                    type="text"
                    value={actionCost}
                    onChange={(e) => setActionCost(e.target.value)}
                  />
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={actionStatus}
                    onChange={() => setActionStatus(!actionStatus)}
                  />
                  <span class="text-sm">Enable Action</span>
                </label>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => props.setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleSubmit();
                    return props.setShowModal(false);
                  }}
                >
                  Add Action
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ModalEdit = (props) => {
  const [awaitAction, setAwaitAction] = useState(true);
  const [action, setAction] = useState();
  const {CompanyID, Loading} = useContext(StatusContext);

  const fetchActions = async () => {
    setAwaitAction(true);

    const actions = await getActions(CompanyID, props.Action_ID);
    setAction(actions[0]);
    setActionName(actions[0].Action_Name);
    handleGroup(actions[0].Action_Group_ID);
    setActionStatus(actions[0].Action_Status);
    setActionCost(actions[0].Action_Variables.actionCost);
    setWorkLocationID(actions[0].Work_Location_ID);
  };

  const handleComplete = () => {
    console.log("complete");
  };

  useEffect(() => {
    if (CompanyID) {
      fetchActions().finally(handleComplete);
    }
  }, [CompanyID]);

  const [actionName, setActionName] = useState("");
  const [actionCost, setActionCost] = useState("");
  const [actionStatus, setActionStatus] = useState(false);
  const [workLocationID, setWorkLocationID] = useState("");

  const [actionFree, setActionFree] = useState(false);
  const [actionLow, setActionLow] = useState(false);
  const [actionMed, setActionMed] = useState(false);
  const [actionHigh, setActionHigh] = useState(false);

  const handleGroup = (actionGroup) => {
    if (actionGroup === "Free") {
      setActionFree(true);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(false);
    } else if (actionGroup === "Low") {
      setActionFree(false);
      setActionLow(true);
      setActionMed(false);
      setActionHigh(false);
    } else if (actionGroup === "Med") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(true);
      setActionHigh(false);
    } else if (actionGroup === "High") {
      setActionFree(false);
      setActionLow(false);
      setActionMed(false);
      setActionHigh(true);
    }
  };

  const currentGroup = () => {
    if (actionFree) {
      return "Free";
    } else if (actionLow) {
      return "Low";
    } else if (actionMed) {
      return "Med";
    } else if (actionHigh) {
      return "High";
    }
  };

  const handleSubmit = async () => {
    await updateAction(CompanyID, props.Action_ID, {
      Company_ID: CompanyID,
      Action_ID: props.Action_ID,
      Action_Group_ID: currentGroup(),
      Work_Location_ID: workLocationID,
      Action_Name: actionName,
      Action_Variables: {actionCost},
      Action_Status: true,
      cb_only: false,
    });
    window.location.reload(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Edit Action</h3>
            </div>
            {/*body*/}
            <form className="relative p-6 flex-auto">
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Action Name
                  </label>
                </div>
                <div class="md:w-2/3">
                  <input
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    value={actionName}
                    onChange={(e) => setActionName(e.target.value)}
                  />
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Action Group
                  </label>
                </div>
                <div class="md:w-2/3">
                  <div className="w-2/3 justify-evenly">
                    <button
                      className={
                        actionFree
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("Free")}
                    >
                      Free
                    </button>
                    <button
                      className={
                        actionLow
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("Low")}
                    >
                      Low
                    </button>
                    <button
                      className={
                        actionMed
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("Med")}
                    >
                      Medium
                    </button>
                    <button
                      className={
                        actionHigh
                          ? "bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-gray-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      onClick={() => handleGroup("High")}
                    >
                      High
                    </button>
                  </div>
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-password"
                  >
                    Cost ?
                  </label>
                </div>
                <div class="md:w-2/3">
                  <input
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-password"
                    type="text"
                    value={actionCost}
                    onChange={(e) => setActionCost(e.target.value)}
                  />
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={actionStatus}
                    onChange={() => setActionStatus(!actionStatus)}
                  />
                  <span class="text-sm">Enable Action</span>
                </label>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => props.setShowModalEdit(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleSubmit();
                    return props.setShowModalEdit(false);
                  }}
                >
                  Edit Action
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Actions;
