export const fieldsConfig = {
  company: {
    total_risk: { type: 'currency' },
    total_risk_critical: { type: 'currency' },
    count_total: { type: 'number' },
    count_red: { type: 'number' },
    count_yellow: { type: 'number' },
    count_total: { type: 'number' },
    count_male: { type: 'number' },
    count_female: { type: 'number' },
    count_red_critical: { type: 'number' },
    count_yellow_critical: { type: 'number' },
    count_total_critical: { type: 'number' },
    count_male_critical: { type: 'number' },
    count_female_critical: { type: 'number' },  
  },
  location: {
    total_risk: { type: 'currency' },
    total_risk_critical: { type: 'currency' },
    count_total: { type: 'number' },
    count_red: { type: 'number' },
    count_yellow: { type: 'number' },
    count_total: { type: 'number' },
    count_male: { type: 'number' },
    count_female: { type: 'number' },
    count_red_critical: { type: 'number' },
    count_yellow_critical: { type: 'number' },
    count_total_critical: { type: 'number' },
    count_male_critical: { type: 'number' },
    count_female_critical: { type: 'number' },
  },
  department: {
    total_risk: { type: 'currency' },
    total_risk_critical: { type: 'currency' },
    count_total: { type: 'number' },
    count_red: { type: 'number' },
    count_yellow: { type: 'number' },
    count_total: { type: 'number' },
    count_male: { type: 'number' },
    count_female: { type: 'number' },
    count_red_critical: { type: 'number' },
    count_yellow_critical: { type: 'number' },
    count_total_critical: { type: 'number' },
    count_male_critical: { type: 'number' },
    count_female_critical: { type: 'number' },
  },
  job: {
    total_risk: { type: 'currency' },
    total_risk_critical: { type: 'currency' },
    count_total: { type: 'number' },
    count_red: { type: 'number' },
    count_yellow: { type: 'number' },
    count_total: { type: 'number' },
    count_male: { type: 'number' },
    count_female: { type: 'number' },
    count_red_critical: { type: 'number' },
    count_yellow_critical: { type: 'number' },
    count_total_critical: { type: 'number' },
    count_male_critical: { type: 'number' },
    count_female_critical: { type: 'number' },
  },
};
  
  