import React, { useState, useContext, useEffect, useCallback } from 'react';
import { getEmployee, getActions, initiateAction, getWorkflows } from '../../global/api';
import { AccountContext } from '../../contexts/Account';
import { v4 as uuidv4 } from 'uuid';

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    let date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
        date = new Date(parseInt(dateString));
    }
    
    if (isNaN(date.getTime())) {
        return dateString;
    }
    
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

const EmployeeDetailsModal = ({ CompanyId, employeeId, onClose }) => {
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [actions, setActions] = useState([]);
    const [approvals, setApprovals] = useState({});
    const [selectedAction, setSelectedAction] = useState('');
    const [regrettable, setRegrettable] = useState(false);
    const [regretComment, setRegretComment] = useState('');
    const [actionComment, setActionComment] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getSession } = useContext(AccountContext);
    const [userEmail, setUserEmail] = useState('');

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const session = await getSession();
            setUserEmail(session.idToken.payload.email);
            const [employeeData, actionsData, approvalData] = await Promise.all([
                getEmployee(CompanyId, employeeId),
                getActions(CompanyId),
                getWorkflows(CompanyId)
            ]);
            setEmployeeDetails(employeeData);
            setActions(actionsData);
            const mappedApprovals = approvalData.reduce((acc, curr) => ({
                ...acc,
                [curr.Action_Group_ID]: curr
            }), {});
            setApprovals(mappedApprovals);
        } catch (err) {
            console.error(err);
            setError(err.message || 'Failed to fetch data');
        } finally {
            setLoading(false);
        }
    }, [CompanyId, employeeId, getSession]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const initAction = async () => {
        if (!selectedAction || !employeeDetails) {
            setError("Please select an action and ensure employee details are loaded.");
            return;
        }

        const activeApprover = approvals[selectedAction.Action_Group_ID]?.Approval_Group_1;
        const timestamp = new Date().toISOString();

        const actionPayload = {
            Company_Id: CompanyId,
            Action_GUID: uuidv4(),
            Employee_Id: employeeDetails[0].Employee_ID,
            Work_Location_Id: employeeDetails[0].Work_Location_ID,
            Department_Id: employeeDetails[0].Department_ID,
            Job_Id: employeeDetails[0].Job_ID,
            Action_Id: selectedAction.Action_ID,
            Action_Init_Date: new Date(),
            user_id: userEmail,
            regret: regrettable,
            active_approver: activeApprover,
            Approval_Notes: actionComment,
            action_group_id: selectedAction.Action_Group_ID,
            regret_comment: regretComment,
            TimeStamp: timestamp
        };

        try {
            await initiateAction(actionPayload);
            alert('Action successfully initiated!');
            onClose();
        } catch (error) {
            console.error("Error initiating action:", error);
            setError('Failed to initiate action.');
        }
    };

    const handleActionChange = (e) => {
        const actionId = e.target.value;
        const action = actions.find(a => a.Action_ID === actionId);
        setSelectedAction(action);
    };

    const canSubmit = selectedAction && actionComment.trim() !== '';

    if (loading) return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
    );

    if (error) return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-xl">
                <h3 className="text-lg font-medium text-red-600 mb-4">Error</h3>
                <p>{error}</p>
                <button 
                    onClick={onClose}
                    className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );

    if (!employeeDetails) return null;

    console.log("Last Salary Adjustment Date:", employeeDetails[0]['Last_Salary_Adjustment_Date']);

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" role="dialog" aria-modal="true">
            <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {employeeDetails[0]['First_Name']} {employeeDetails[0]['Last_Name']}
                    </h3>
                    <div>
                        <button 
                            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 ${!canSubmit && 'opacity-50 cursor-not-allowed'}`}
                            onClick={initAction}
                            disabled={!canSubmit}
                            aria-label="Submit action"
                        >
                            Submit
                        </button>
                        <button 
                            onClick={onClose} 
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            aria-label="Close modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/2 pr-2">
                        <p className="text-sm text-gray-500">Employee ID: {employeeDetails[0]['Employee_ID']}</p>
                        <p className="text-sm text-gray-500">Annual Salary: {employeeDetails[0]['Annual_Salary']}</p>
                        <p className="text-sm text-gray-500">Last Salary Update: {formatDate(employeeDetails[0]['Last_Salary_Adjustment_Date'])}</p>
                        <p className="text-sm text-gray-500">Hire Date: {formatDate(employeeDetails[0]['Date_of_Hire'])}</p>
                    </div>
                    <div className="w-1/2 pl-2">
                        <div className="my-4">
                            <p className="text-md font-medium text-gray-700">Performance Chart Placeholder</p>
                        </div>
                        <div className="my-4">
                            <label className="inline-flex items-center">
                                <input 
                                    type="checkbox" 
                                    className="form-checkbox" 
                                    checked={regrettable} 
                                    onChange={(e) => setRegrettable(e.target.checked)} 
                                    aria-label="Regrettable"
                                />
                                <span className="ml-2 text-gray-700">Regrettable</span>
                            </label>
                            {regrettable && (
                                <textarea 
                                    className="form-textarea mt-1 block w-full" 
                                    rows="3" 
                                    placeholder="Add a note explaining the change"
                                    value={regretComment}
                                    onChange={(e) => setRegretComment(e.target.value)}
                                    aria-label="Regret comment"
                                ></textarea>
                            )}
                        </div>
                        <div className="my-4">
                            <select
                                className="form-select block w-full mt-1"
                                value={selectedAction?.Action_ID || ''}
                                onChange={handleActionChange}
                                aria-label="Select an action"
                            >
                                <option value="">Select an Action</option>
                                {actions.map((action) => (
                                    <option key={action.Action_ID} value={action.Action_ID}>{action.Action_Name}</option>
                                ))}
                            </select>
                            {selectedAction && (
                                <textarea
                                    className="form-textarea mt-1 block w-full"
                                    rows="3"
                                    placeholder="Add a note explaining your action"
                                    value={actionComment}
                                    onChange={(e) => setActionComment(e.target.value)}
                                    aria-label="Action comment"
                                ></textarea>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(EmployeeDetailsModal);