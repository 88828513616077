import React from 'react';

const SkeletonCard = () => {
  return (
    <div className="animate-pulse">
      <div className="rounded-md bg-gray-300 h-20 w-full mb-4"></div>
      <div className="rounded-md bg-gray-300 h-10 w-full mb-4"></div>
      <div className="rounded-md bg-gray-300 h-10 w-3/4 mb-4"></div>
    </div>
  );
};

export default SkeletonCard;
