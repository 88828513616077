import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../global/Spinner';
import { Button, IconButton } from '@material-tailwind/react';
import { FiPlus } from 'react-icons/fi'; // For the "+" icon
import { getClientComps, createClientComp } from '../../global/api';
import { StatusContext } from '../../contexts/Status';

const StepThree = () => {
    const { ClientID } = useContext(StatusContext);
    const [isLoading, setIsLoading] = useState(false);
    const [manualRows, setManualRows] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getClientComps(ClientID).then(response => {
            setIsLoading(false);
            if (response) {
                // Default ClientID for all rows
                const dataWithClientID = response.map(row => ({ ...row, Client_ID: ClientID }));
                setManualRows(dataWithClientID);
            } else {
                setError('Failed to fetch data');
            }
        }).catch(error => {
            console.error('Error fetching client companies:', error);
            setError('Failed to fetch data');
            setIsLoading(false);
        });
    }, [ClientID]);

    const handleAddRow = () => {
        setManualRows([...manualRows, { Client_ID: ClientID, Company_ID: '', Company_Name: '', ASIC: '' }]);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const rows = [...manualRows];
        rows[index][name] = value;
        setManualRows(rows);
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Assume createClientComp can handle an array of objects
            await createClientComp(manualRows);
            alert('Changes saved successfully');
        } catch (error) {
            console.error('Error during save:', error);
            alert('Save failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center my-4">
            {isLoading ? <Spinner /> : (
                <>
                    {error && <div className="text-red-500 mb-4">{error}</div>}
                    <div className="my-4 flex justify-end w-full">
                        <IconButton onClick={handleAddRow} size="sm" className="text-blue-500">
                            <FiPlus />
                        </IconButton>
                    </div>
                    <div className="container mx-auto">
                        <div className="font-bold flex justify-between mb-2">
                            <span>Company ID</span>
                            <span>Company Name</span>
                            <span>ASIC</span>
                        </div>
                        {manualRows.map((row, index) => (
                            <div key={index} className="flex justify-between items-center mb-2">
                                <input
                                    type="text"
                                    name="Company_ID"
                                    placeholder="Company ID"
                                    value={row.Company_ID}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className="input input-bordered w-full"
                                />
                                <input
                                    type="text"
                                    name="Company_Name"
                                    placeholder="Company Name"
                                    value={row.Company_Name}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className="input input-bordered w-full mx-2"
                                />
                                <input
                                    type="text"
                                    name="ASIC"
                                    placeholder="ASIC"
                                    value={row.ASIC}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className="input input-bordered w-full"
                                />
                            </div>
                        ))}
                    </div>
                    <Button color="green" onClick={handleSave} className="my-4">
                        Save Changes
                    </Button>
                </>
            )}
        </div>
    );
};

export default StepThree;
