import React, { useState } from 'react';
import { useTable } from 'react-table';
import ApprovalActionModal from './ApprovalActionModal';

const ApprovalGroupGrid = ({ groupName, data, refreshGrid }) => {
    const [selectedAction, setSelectedAction] = useState(null);

    const columns = React.useMemo(() => [
        { Header: 'Action Name', accessor: 'Action_Name' },
        { Header: 'Employee Name', accessor: 'Employee_Name' },
        {
            Header: 'Hire Date',
            accessor: 'Hire_Date',
            Cell: ({ value }) => value,
        },
    ], []);

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <>
            <div>
                <h2 className="text-xl font-semibold">{groupName}</h2>
                <div className="overflow-x-auto mt-4">
                    <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="cursor-pointer hover:bg-gray-100"
                                        onClick={() => setSelectedAction(row.original)}
                                    >
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {selectedAction && (
                <ApprovalActionModal
                    action={selectedAction}
                    onClose={() => {
                        setSelectedAction(null);
                        refreshGrid();
                    }}
                />
            )}
        </>
    );
};

export default ApprovalGroupGrid;