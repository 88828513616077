import React, {Fragment} from "react";
import {useState, useContext, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../global/NavbarSuper";
import Footer from "../../global/Footer";
import {v4 as uuidv4} from "uuid";
import {
  createUser,
  createUserEntitlement,
  deleteUser,
  deleteUserEntitlement,
  getClientComps,
  getClients,
  getDepartment,
  getLocDetails,
  getLocations,
  getUsers,
  getUsersEntitlements,
  updateUser,
  updateUserEntitlement,
} from "../../global/api";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Accordion} from "flowbite-react";
import {StatusContext} from "../../contexts/Status";
import Sidebar from "./Sidebar";
import {useParams} from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Input,
  Typography,
} from "@material-tailwind/react";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

const AdminUsers = () => {
  const [awaitUsers, setAwaitUsers] = useState(true);
  const [awaitData, setAwaitData] = useState(true);
  const [users, setUsers] = useState(null);
  const [entitleUsers, setEntitleUsers] = useState(null);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [showModalUser, setShowModalUser] = React.useState(false);
  const [showModalEditUser, setShowModalEditUser] = React.useState(false);

  const {
    Email, 
    ClientID, 
    ClientName, 
    CompanyID, 
    CompanyName,
  } = useContext(StatusContext);

  const [combinedUsers, setCombinedUsers] = useState(null);

  const fetchData = async () => {
    setAwaitData(true);

    const Users = await getUsers(ClientID);
    const EntitledUsers = await getUsersEntitlements();

    setUsers(
      Users.filter(
        (user) => user.Client_ID === ClientID && !user.Super_Admin
      )
    );

    const filteredUsers = Users.filter(
      (user) => user.Client_ID === ClientID && !user.Super_Admin
    );

    const filteredEntitleUsers = EntitledUsers.filter((entitledUser) =>
      filteredUsers.some(
        (user) => user.Email_Address === entitledUser.Email_Address
      )
    );

    setEntitleUsers(EntitledUsers);
    setAwaitData(false);

    function combineUsersByEmail(regularUsers, entitledUsers) {
      const combinedUsers = {};

      // Combine entitled users first
      for (const user of entitledUsers) {
        const email = user.Email_Address;
        combinedUsers[email] = {
          ...combinedUsers[email], // Existing combined user properties
          ...user, // New user properties (entitled user)
        };
      }

      // Then combine regular users
      for (const user of regularUsers) {
        const email = user.Email_Address;
        combinedUsers[email] = {
          ...combinedUsers[email], // Existing combined user properties
          ...user, // New user properties (regular user)
        };
      }

      return Object.values(combinedUsers); // Convert the combinedUsers object back to an array
    }

    console.log(combineUsersByEmail(filteredUsers, filteredEntitleUsers));
    setCombinedUsers(combineUsersByEmail(filteredUsers, filteredEntitleUsers));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteUser = async (email) => {
    await deleteUser(email);
    window.location.reload(false);
  };

  const handleDeleteUserEntitlement = async (email) => {
    await deleteUserEntitlement(email);
    window.location.reload(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredUsers = users
    ? users.filter((user) =>
        user.Email_Address.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];

  const filteredEntitleUsers = entitleUsers
    ? entitleUsers.filter((user) =>
        user.Email_Address.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Filter the data based on the search query
    const filteredData = combinedUsers.filter((user) =>
      `${user.Email_Address}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice(startIndex, endIndex);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const TABLE_HEAD = ["Email", "Client", "Company", "Roles", "Edit", "Delete"];

  const [edit, SetEdit] = useState(null);

  const [editEmail, setEditEmail] = useState(null);
  const [editClientID, setEditClientID] = useState(null);
  const [editCompanyID, setEditCompanyID] = useState(null);
  const [editAdmin, setEditAdmin] = useState(null);
  const [editSuper_Admin, setEditSuper_Admin] = useState(null);
  const [editManager, setEditManager] = useState(null);
  const [editApprover, setEditApprover] = useState(null);
  const [editAuditor, setEditAuditor] = useState(null);
  const [editHR, setEditHR] = useState(null);
  const [editExecutive, setEditExecutive] = useState(null);
  const [editRecorder, setEditRecorder] = useState(null);

  const handleSubmitChanges = async () => {
    await updateUserEntitlement({
      Email_Address: editEmail,
      Client_ID: editClientID,
      Company_ID: editCompanyID,
      Department_ID: null,
      Location_ID: null,
      Admin: editAdmin,
      Approver: editApprover,
      Executive: editExecutive,
      Recorder: editRecorder,
      Manager: editManager,
      Auditor: editAuditor,
      HR: editHR,
    });
    window.location.reload(false);
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="flex flex-col bg-[#F2F2F2] justify-center items-center font-fabriga w-screen">
          <div className="font-fabriga font-bold text-8xl mt-10">Users</div>
          <button
            className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModalUser(true)}
          >
            Add New User
          </button>
          {awaitData === false ? (
            <Card className="h-full w-4/5">
              <CardHeader
                floated={false}
                shadow={false}
                className="rounded-none"
              >
                <div className="mb-8 flex items-center justify-between gap-8">
                  <div>
                    <Typography variant="h5" color="blue-gray">
                      User list
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                      Click on the pencil to make changes, then click on it
                      again to confirm.
                    </Typography>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                  <div className="w-full md:w-72">
                    <Input
                      label="Search by Email Address"
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody className="overflow-scroll px-0">
                <table className="mt-4 w-full table-auto text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head, index) => (
                        <th
                          key={head}
                          className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                          >
                            {head}{" "}
                            {index !== TABLE_HEAD.length - 1 && (
                              <ChevronUpDownIcon
                                strokeWidth={2}
                                className="h-4 w-4"
                              />
                            )}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {getCurrentPageData().map(
                      (
                        {
                          Email_Address,
                          Client_ID,
                          Company_ID,
                          Admin,
                          Super_Admin,
                          Manager,
                          Approver,
                          Auditor,
                          HR,
                          Executive,
                          Recorder,
                        },
                        index
                      ) => {
                        const isLast = index === users.length - 1;
                        const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";

                        return (
                          // <Accordion open={open === Employee_ID}>
                          //   <AccordionHeader
                          //     onClick={() => handleOpen(Employee_ID)}
                          //   >
                          <>
                            {Email_Address !== edit ? (
                              <tr
                                key={Email_Address}
                                className="hover:bg-blue-gray-50 hover:cursor-pointer"
                              >
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Email_Address}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Client_ID}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Company_ID}
                                    </Typography>
                                  </div>
                                </td>
                                <td
                                  className={
                                    isLast
                                      ? "p-4 flex flex-row space-x-5"
                                      : "p-4 border-b border-blue-gray-50 flex flex-row space-x-5"
                                  }
                                >
                                  {Admin ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"Admin"}
                                        color={Admin ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}

                                  {Manager ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"Manager"}
                                        color={Manager ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}

                                  {Approver ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"Approver"}
                                        color={Approver ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}

                                  {Auditor ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"Auditor"}
                                        color={Auditor ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}

                                  {HR ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"HR"}
                                        color={HR ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}

                                  {Executive ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"Executive"}
                                        color={Executive ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}

                                  {Recorder ? (
                                    <div className="w-max">
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value={"Recorder"}
                                        color={Recorder ? "green" : "red"}
                                      />
                                    </div>
                                  ) : null}
                                </td>
                                <td className={classes}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                    onClick={() => {
                                      SetEdit(Email_Address);
                                      setEditEmail(Email_Address);
                                      setEditClientID(Client_ID);
                                      setEditCompanyID(Company_ID);
                                      setEditAdmin(Admin);
                                      setEditSuper_Admin(Super_Admin);
                                      setEditManager(Manager);
                                      setEditApprover(Approver);
                                      setEditAuditor(Auditor);
                                      setEditHR(HR);
                                      setEditExecutive(Executive);
                                      setEditRecorder(Recorder);
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            ) : (
                              <tr
                                key={Email_Address}
                                className="bg-blue-gray-50 hover:cursor-pointer"
                              >
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Email_Address}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Client_ID}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Company_ID}
                                    </Typography>
                                  </div>
                                </td>
                                <td
                                  className={
                                    isLast
                                      ? "p-4 flex flex-row space-x-5"
                                      : "p-4 border-b border-blue-gray-50 flex flex-row space-x-5"
                                  }
                                >
                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"Admin"}
                                      onClick={() => {
                                        setEditAdmin(!editAdmin);
                                      }}
                                      color={editAdmin ? "green" : "red"}
                                    />
                                  </div>

                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"Manager"}
                                      onClick={() => {
                                        setEditManager(!editManager);
                                      }}
                                      color={editManager ? "green" : "red"}
                                    />
                                  </div>

                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"Approver"}
                                      onClick={() => {
                                        setEditApprover(!editApprover);
                                      }}
                                      color={editApprover ? "green" : "red"}
                                    />
                                  </div>

                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"Auditor"}
                                      onClick={() => {
                                        setEditAuditor(!editAuditor);
                                      }}
                                      color={editAuditor ? "green" : "red"}
                                    />
                                  </div>

                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"HR"}
                                      onClick={() => {
                                        setEditHR(!editHR);
                                      }}
                                      color={editHR ? "green" : "red"}
                                    />
                                  </div>

                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"Executive"}
                                      onClick={() => {
                                        setEditExecutive(!editExecutive);
                                      }}
                                      color={editExecutive ? "green" : "red"}
                                    />
                                  </div>

                                  <div className="w-max">
                                    <Chip
                                      variant="ghost"
                                      size="sm"
                                      value={"Recorder"}
                                      onClick={() => {
                                        setEditRecorder(!editRecorder);
                                      }}
                                      color={editRecorder ? "green" : "red"}
                                    />
                                  </div>
                                </td>
                                <td className={classes}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 animate-pulse"
                                    onClick={() => {
                                      handleSubmitChanges();
                                      SetEdit(null);
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
              <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  Page {currentPage} of{" "}
                  {Math.ceil(users.length / ITEMS_PER_PAGE)}
                </Typography>
                <div className="flex gap-2">
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage === Math.ceil(users.length / ITEMS_PER_PAGE)
                    }
                  >
                    Next
                  </Button>
                </div>
              </CardFooter>
            </Card>
          ) : null}

          {showModalUser ? (
            <ModalAddNewUser setShowModalUser={setShowModalUser} />
          ) : null}

          <Footer />
        </div>
      </div>
    </>
  );
};

const ModalAddNewUser = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [email, setEmail] = useState("");
  const [clientID, setClientID] = useState("");
  const [client, setClient] = useState("Client Name");
  const [companyID, setCompanyID] = useState("");
  const [company, setCompany] = useState("");
  const [admin, setAdmin] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [manager, setManager] = useState(false);
  const [approver, setApprover] = useState(false);
  const [auditor, setAuditor] = useState(false);
  const [HR, setHR] = useState(false);
  const [executive, setExecutive] = useState(false);
  const [recorder, setRecorder] = useState(false);

  const [clients, setClients] = useState(null);
  const [companies, setCompanies] = useState(null);

  const [locatDept, setLocDept] = useState(null);
  const [location, setLoacation] = useState(null);
  const [locationID, setLocationID] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departmentID, setDepartmentID] = useState(null);

  const {Client_ID} = useContext(StatusContext);

  useEffect(() => {
    const fetchClients = async () => {
      let clients = await getClients(Client_ID);
      clients = clients.filter((x) => x.Client_ID === Client_ID);
      console.log(clients[0]);
      setClientID(clients[0].Client_ID);
      setClient(clients[0].Client_Name);
      const company = await getClientComps(clients[0].Client_ID);
      setCompanies(company);
    };
    fetchClients();
  }, []);

  const findLocations = async (compID) => {
    const locations = await getLocDetails(compID);
    console.log("Locations", locations);
    setLoacation(locations);
  };

  const findDepartments = async (compID) => {
    const departments = await getDepartment(compID);
    console.log("Departments", departments);
    setDepartment(departments);
  };

  const setComp = async (company) => {
    setCompanyID(company.Company_ID);
    setCompany(company.Company_Name);
  };

  const handleCheckbox = (x, setX) => {
    setX(!x);
  };

  const handleSubmit = async () => {
    const fetchTryUser = await getUsers(`Email_Address=${email}`);
    if (fetchTryUser.length === 0) {
      await createUser({
        Email_Address: email,
        Client_ID: clientID,
        User_Status: "A",
        User_Role: "admin",
        Super_Admin: superAdmin,
      });
    } else {
      console.log(fetchTryUser);
      console.log("User already created in user table");
    }
    // console.log((await getUsers(`Email_Address=${email}`))[0]);

    await submitUserEntititlement({
      // console.log({
      ID: uuidv4(),
      Email_Address: email,
      Client_ID: clientID,
      Company_ID: companyID,
      Department_ID: departmentID,
      Location_ID: locationID,
      Admin: admin,
      Approver: approver,
      Executive: executive,
      Recorder: recorder,
      Manager: manager,
      Auditor: auditor,
      HR: HR,
    });
  };

  const submitUserEntititlement = async (x) => {
    await createUserEntitlement(x);
    window.location.reload(false);
  };

  const submitUser = async () => {
    // window.location.reload(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add New User</h3>
            </div>
            {/*body*/}
            <form className="relative p-6 flex-auto">
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                  >
                    Email Address
                  </label>
                </div>
                <div class="md:w-2/3">
                  <input
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label
                    class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                    for="inline-password"
                  >
                    Company
                  </label>
                </div>
                {!companies ? (
                  <div
                    class="md:w-2/3 appearance-none border-2 border-white rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                    id="inline-full-name"
                    type="text"
                  >
                    Select a Client
                  </div>
                ) : (
                  <>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {company}
                          <ChevronDownIcon
                            className="-mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {companies.map((comp) => (
                              <Menu.Item>
                                <div
                                  onClick={() => {
                                    setComp(comp);
                                    findDepartments(comp.Company_ID);
                                    findLocations(comp.Company_ID);
                                  }}
                                  className={
                                    "bg-white text-gray-900 cursor-pointer block px-4 py-2 text-sm"
                                  }
                                >
                                  {comp.Company_Name}
                                </div>
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <div class="md:w-1/3">
                      <label
                        class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        for="inline-password"
                      >
                        {companyID}
                      </label>
                    </div>
                  </>
                )}
              </div>
              {!companyID ? null : !location ? null : (
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label
                      class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      for="inline-password"
                    >
                      {locationID}
                    </label>
                  </div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Location ID
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {location.map((loc) => (
                            <Menu.Item>
                              <div
                                onClick={() =>
                                  setLocationID(loc.Work_Location_ID)
                                }
                                className={
                                  "bg-white text-gray-900 cursor-pointer block px-4 py-2 text-sm"
                                }
                              >
                                {loc.Location_Name}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
              {!companyID ? null : !department ? null : (
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label
                      class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      for="inline-password"
                    >
                      {departmentID}
                    </label>
                  </div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Department ID
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {department.map((dept) => (
                            <Menu.Item>
                              <div
                                onClick={() =>
                                  setLocationID(dept.Department_ID)
                                }
                                className={
                                  "bg-white text-gray-900 cursor-pointer block px-4 py-2 text-sm"
                                }
                              >
                                {dept.Department_ID}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={admin}
                    onChange={() => handleCheckbox(admin, setAdmin)}
                  />
                  <span class="text-sm">Admin</span>
                </label>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={manager}
                    onChange={() => handleCheckbox(manager, setManager)}
                  />
                  <span class="text-sm">Manager</span>
                </label>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={approver}
                    onChange={() => handleCheckbox(approver, setApprover)}
                  />
                  <span class="text-sm">Approver</span>
                </label>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={auditor}
                    onChange={() => handleCheckbox(auditor, setAuditor)}
                  />
                  <span class="text-sm">Auditor</span>
                </label>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={HR}
                    onChange={() => handleCheckbox(HR, setHR)}
                  />
                  <span class="text-sm">HR</span>
                </label>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={executive}
                    onChange={() => handleCheckbox(executive, setExecutive)}
                  />
                  <span class="text-sm">Executive</span>
                </label>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3"></div>
                <label class="md:w-2/3 block text-gray-500 font-bold">
                  <input
                    class="mr-2 leading-tight"
                    type="checkbox"
                    value={recorder}
                    onChange={() => handleCheckbox(recorder, setRecorder)}
                  />
                  <span class="text-sm">Recorder</span>
                </label>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModalUser(false);
                    console.log("canceled");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    props.setShowModalUser(false);
                    handleSubmit();
                  }}
                >
                  Add User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AdminUsers;
