import React from "react";
import Navbar from "../../global/NavbarHR";

const ClientHR = () => {
  return (
    <>
      <Navbar name="CLIENT"></Navbar>
      <div>Client</div>
    </>
  );
};

export default ClientHR;
