import Table, {SelectColumnFilter} from "./Table";
import React, {useMemo} from "react";

function TableComponent(props) {
  const columns = React.useMemo(() => props.columns, []);

  const data = React.useMemo(() => {
    const data = props.data;

    return [...data];
  }, []);

  return (
    <div className="w-4/5 bg-gray-100 text-gray-900 justify-center mx-auto">
      <main className="w-full mx-auto sm:px-6 lg:px-8 pt-4">
        <div className="mt-4">
          <Table columns={columns} data={data} />
        </div>
      </main>
    </div>
  );
}

export default TableComponent;
