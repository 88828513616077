import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import Spinner from "../../global/Spinner";
import SignIn from "./SignIn";
import {StatusContext} from "../../contexts/Status";

const ProtectedRoutes = () => {
  const {AuthStatus} = useContext(StatusContext);

  return AuthStatus === undefined ? (
    <Spinner />
  ) : AuthStatus ? (
    <Outlet />
  ) : (
    <SignIn />
  );
};

export default ProtectedRoutes;
