import React, { useContext } from "react";
import { StatusContext } from "../../contexts/Status";
import { Navigate, Outlet } from "react-router-dom";
import Spinner from "../../global/Spinner";

const IsSuper = () => {
  const { SuperAdmin, Loading } = useContext(StatusContext);

  console.log("IsSuper: SuperAdmin =", SuperAdmin, "Loading =", Loading);

  if (Loading) {
    return <Spinner />;
  }

  if (SuperAdmin) {
    return <Outlet />;
  } else {
    console.log("IsSuper: Not a super admin, redirecting");
    return <Navigate to="/unauthorized" replace />;
  }
};

export default IsSuper;