import React, {useState} from "react";
import ProgressBar from "./ProgressBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import TableComponent from "./TableComponent";
import {SelectColumnFilter} from "../manager/Components/Table";
import Papa from "papaparse";
import AWS from "aws-sdk";

const StepTwo = () => {
  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    // console.log(e.target);
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const [data, setData] = useState();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(file);
    if (file) {
      setData();
      console.log(file);
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          console.log("Finished:", results.data);
          setData(results.data);
        },
      });
    }
  };

  const handleUploadData = (e) => {
    AWS.config.update({
      region: "us-east-1",
      accessKeyId: "AKIAXYAJO73XJC7OXQTR",
      secretAccessKey: "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",
    });
    const dynamodb = new AWS.DynamoDB();

    for (let item of data) {
      const params = {
        TableName: "Department",
        Item: {
          Client_ID: {S: String(item.Client_ID)},
          Company_ID: {S: String(item.Company_ID)},
          Department_ID: {S: String(item.Dept_ID)},
          Department_Name: {S: String(item.Dept_Name)},
        },
      };
      dynamodb.putItem(params, (err, data) => {
        if (err) {
          console.error(err);
        } else {
          console.log("Item added successfully:", data);
        }
      });
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center font-fabriga mx-auto">
        <form action="">
          <div className="flex flex-row w-4/5 justify-center">
            <div className="bg-white h-2/3 flex mt-10 px-3 py-4 justify-center items-center rounded-l-xl">
              <input
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
              />
              <div className="text-2xl">
                Pick a file to upload Department mapping information
              </div>
            </div>
            <div
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              className={
                file
                  ? "bg-purple-600 text-white h-2/3 flex mt-10 px-3 py-4 justify-center items-center rounded-r-xl cursor-pointer"
                  : "bg-gray-400 h-2/3 flex mt-10 px-3 py-4 justify-center items-center rounded-r-2xl"
              }
            >
              <div className="text-2xl">Upload File</div>
            </div>
          </div>
        </form>

        {data ? (
          <TableComponent
            data={data}
            columns={[
              {
                Header: "Client ID",
                accessor: "Client_ID",
              },
              {
                Header: "Company ID",
                accessor: "Company_ID",
              },
              {
                Header: "Department ID",
                accessor: "Deptartment_ID",
              },
              {
                Header: "Department Name",
                accessor: "Dept_Name",
              },
            ]}
          />
        ) : null}
        {data ? (
          <div className="w-4/5">
            <div className="flex flex-row justify-center items-center">
              <div className="flex-1">
                <a href="/onboarding/stepone">
                  <div>Back</div>
                </a>
              </div>
              <div className="flex flex-row space-x-5">
                <a href="/onboarding/stepthree">
                  <div className="text-2xl font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal text-purple-600 bg-white">
                    <div>Skip this step</div>
                  </div>
                </a>
                <a href="/onboarding/stepthree">
                  <div
                    className="text-2xl font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal text-white bg-purple-600"
                    onClick={(e) => {
                      handleUploadData(e);
                    }}
                  >
                    <div>Next</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default StepTwo;
