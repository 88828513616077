import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/common/sidebar_new";
import Footer from "../../global/Footer";
import TopNavigation from "../../components/common/TopNavigation";
import { CompanyDataProvider } from '../../contexts/CompanyContext';
import { CompanyChartDataProvider } from "../../contexts/CompanyChartDataContext";
import { EmployeeProvider } from "../../contexts/EmployeeContext";
import EmployeeGrid from "../../components/dashboard/EmployeeGrid";
import { StatusContext } from "../../contexts/Status";
import { RenderCompanyCards } from "../../components/dashboard/RenderCompanyCards";
import { RenderCompanyCharts } from '../../components/dashboard/RenderCompanyCharts';
import { RenderCompanySpark } from "../../components/dashboard/RenderCompanySpark";
import ApprovalsGrid from "../../components/approver/ApprovalsGrid";
import { getCompanies, getClients, getClientComps } from "../../global/api";
import ActionStatsGrid from "../../components/dashboard/actionStatsGrid";
import { AccountContext } from "../../contexts/Account";

const DashboardAdmin = () => {
    const { Email, ClientID, ClientName, CompanyID, CompanyName, updateClientAndCompany } = useContext(StatusContext);
    const { getSession } = useContext(AccountContext);
    const [companyData, setCompanyData] = useState(null);
    const [loadingCompanyData, setLoadingCompanyData] = useState(true);
    const [availableClients, setAvailableClients] = useState([]);
    const [availableCompanies, setAvailableCompanies] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            try {
                const fetchedCompanyData = await getCompanies(CompanyID);
                if (fetchedCompanyData && fetchedCompanyData.length > 0) {
                    setCompanyData(fetchedCompanyData);
                }
                
                // Fetch available clients and companies for the current user
                const fetchedClients = await getClients();
                const fetchedCompanies = await getClientComps(ClientID);
                setAvailableClients(fetchedClients || []);
                setAvailableCompanies(fetchedCompanies || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoadingCompanyData(false);
            }
        };
        fetchData();
    }, [CompanyID, ClientID]);

    const handleClientCompanyChange = (newClientID, newCompanyID) => {
        updateClientAndCompany(newClientID, newCompanyID);
        // Optionally, you might want to refetch data here
    };

    return (
        <EmployeeProvider companyId={CompanyID}>
            <CompanyDataProvider companyId={CompanyID}>
                <div className="flex h-screen">
                    <div className="fixed w-16">
                        <Sidebar />
                    </div>
    
                    <div className="flex flex-col bg-[#F2F2F2] min-h-screen font-fabriga ml-16">
                        <TopNavigation 
                            clientName={ClientName}
                            companyName={CompanyName}
                            availableClients={availableClients}
                            availableCompanies={availableCompanies}
                            onClientCompanyChange={handleClientCompanyChange}
                        />
                        <div className="flex flex-wrap justify-between items-start -mx-2">
                            <RenderCompanyCards companyData={companyData} />
                        </div>
                        <CompanyChartDataProvider>
                            <>
                                <div className="flex flex-wrap justify-between items-start -mx-2">
                                    <RenderCompanyCharts companyData={companyData} />
                                </div>
                                <div className="flex flex-wrap justify-between items-start -mx-2"></div>
                                <RenderCompanySpark companyData={companyData} />
                            </>
                        </CompanyChartDataProvider>
                        <div className=" w-full px-4">
                            <EmployeeGrid />
                            <ActionStatsGrid />
                            <ApprovalsGrid />
                        </div>
                        <Footer />
                    </div>
                </div>
            </CompanyDataProvider>
        </EmployeeProvider>
    );
};

export default DashboardAdmin;