import React, { useMemo, useContext, useState, useEffect } from 'react';
import { StatusContext } from '../../contexts/Status';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Tooltip } from 'react-tooltip';
import { actionStat, getActions } from "../../global/api";


const ActionStatsGrid = () => {
    const [actionStats, setActionStats] = useState([]);
    const { CompanyID, CompanyName } = useContext(StatusContext);
    
    useEffect(() => {
        const fetchData = async () => {
            if (!CompanyID) return;

            try {
                const [actionsData, actionStatsData] = await Promise.all([
                    getActions(CompanyID),
                    actionStat(CompanyID)
                ]);

                // Create a map for quick lookup. Key: Action_ID, Value: Action_Name
                const actionsMap = new Map(actionsData.map(action => [action.Action_ID, action.Action_Name]));

                // Enrich actionStats with ActionName
                const enrichedActionStats = actionStatsData.map(stat => ({
                    ...stat,
                    ActionName: actionsMap.get(stat.Action_ID) || 'Unknown Action',
                }));

                setActionStats(enrichedActionStats.sort((a, b) => b.approved_count - a.approved_count));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [CompanyID]);

    const columns = useMemo(() => [
        { Header: 'Action Name', accessor: 'ActionName' },
        { Header: 'Approved Count', accessor: 'approved_count' },
        { Header: 'Rejected Count', accessor: 'rejected_count' },
        { Header: 'Min Approval Time (Approved)', accessor: 'min_approval_time_approved' },
        { Header: 'Max Approval Time (Approved)', accessor: 'max_approval_time_approved' },
        { Header: 'Avg Approval Time (Approved)', accessor: 'avg_approval_time_approved' },
        { Header: 'Min Approval Time (Rejected)', accessor: 'min_approval_time_rejected' },
        { Header: 'Max Approval Time (Rejected)', accessor: 'max_approval_time_rejected' },
        { Header: 'Avg Approval Time (Rejected)', accessor: 'avg_approval_time_rejected' },
        // Add more columns as needed
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable({
        columns,
        data: actionStats,
        initialState: { pageIndex: 0, pageSize: 10 },
    }, useGlobalFilter, usePagination);

    return (
        <>
            <div className="mb-4">
                <h2 className="text-xl font-semibold text-gray-800">Actions Taken Stats for {CompanyName}</h2>
            </div>
            <div className="overflow-x-auto">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/* Pagination and other UI components as in the EmployeeGrid example */}
        </>
    );
};

export default ActionStatsGrid;
