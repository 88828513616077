import React from "react";

const Footer = () => {
  return (
    <>
      <div className="py-8"></div>
      <footer class="bg-neutral-200 text-center dark:bg-neutral-700 lg:text-left">
        <div class="p-4 text-center text-neutral-700 dark:text-neutral-200">
          © 2024 Copyright:
          <a
            class="text-neutral-800 dark:text-neutral-400"
            href="https://tailwind-elements.com/"
          >
            HappyHippo AI
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
