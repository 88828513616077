import React from "react";
import Navbar from "../../global/NavbarManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faMale,
  faCircleUp,
  faBell,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";

const ManagerActions = () => {
  return (
    <>
      <Navbar name="My Actions"></Navbar>
      <div className="bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga">
        <div className="h-6"></div>
        <div className="w-4/5 flex flex-row space-x-4 mx-auto">
          <div className="w-4/5 h-52 bg-white mx-auto flex justify-center items-center rounded-2xl">
            <div className="w/2 text-center">
              <div className="flex flex-row items-center space-x-4">
                <div className="font-bold text-6xl text-center ">$441,245</div>
                <FontAwesomeIcon
                  icon={faCircleUp}
                  className="text-6xl text-red-600"
                />
              </div>
              <p className="text-gray-700 text-4xl text-center">
                Savings Attributable To You
              </p>
            </div>
          </div>
          <div className="w-4/5 h-52 bg-white mx-auto flex justify-center items-center rounded-2xl">
            <div className="w/2 text-center">
              <div className="font-bold text-6xl text-center">2 days</div>
              <p className="text-gray-700 text-4xl text-center">
                Average Time to Approve
              </p>
            </div>
          </div>
        </div>
        <Action
          name="Kristen Doe"
          action="Movie tickets for two"
          time="few seconds ago"
        ></Action>
        <Action
          name="Kirin Duluth"
          action="Have a face-to-face discusssion with the employee "
          time="Nov, 2022"
        ></Action>
        <Action
          name="Jeremy Dolores"
          action="Dinner for two"
          time="Oct, 2022"
        ></Action>
        <Action
          name="Kirsten Doe"
          action="Have a face-to-face discusssion with the employee "
          time="June, 2022"
        ></Action>
      </div>
    </>
  );
};

export default ManagerActions;

const Action = (props) => {
  return (
    <>
      <div className="h-6"></div>
      <div className="w-4/5 space-x4 mx-auto">
        <div className="bg-gray-300 w-full h-36 flex flex-row items-center justify-between px-10 rounded-2xl">
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faSquareCheck} className="text-7xl pr-8" />
            <div className="flex flex-col">
              <div className="text-4xl">{props.name}</div>
              <div className="font-bold text-4xl">{props.action}</div>
            </div>
          </div>

          <div className="text-4xl">{props.time}</div>
        </div>
      </div>
    </>
  );
};
