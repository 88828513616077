import React from "react";
import Navbar from "../../global/NavbarHR";

const WorkflowHR = () => {
  return (
    <>
      <Navbar name="WORKFLOW"></Navbar>
      <div>Workflow</div>
    </>
  );
};

export default WorkflowHR;
