import {Dialog, Menu, Transition} from "@headlessui/react";
import React, {Fragment, useRef, useState} from "react";
import {useContext} from "react";
import {AccountContext} from "../contexts/Account";
import {StatusContext} from "../contexts/Status";

const Navbar = (props) => {
  const {AuthStatus, HR, SuperAdmin} = useContext(StatusContext);

  let links = [
    {name: "DASHBOARD", link: "/hr/dashboard"},
    {name: "CLIENT", link: "/hr/dashboard/client"},
    {name: "DEPARTMENT", link: "/hr/dashboard/department"},
    {name: "ACTION", link: "/hr/dashboard/action"},
    {name: "WORKFLOW", link: "/hr/dashboard/workflow"},
    {name: "APPROVER", link: "/hr/dashboard/approver"},
    {name: "MY ORG", link: "/hr/dashboard/myorg"},
  ];

  const [openSignOut, setOpenSignOut] = useState(false);

  const cancelButtonRef = useRef(null);

  const {logout} = useContext(AccountContext);

  return (
    <div className="shadow-md w-full sticky top-0 left-0">
      <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
        <a href={SuperAdmin ? "/super/dashboard" : HR ? "/hr/dashboard" : "/"}>
          <div className="font-bold text-2xl cursor-pointer flex items-center font-fabriga text-gray-800 hover:text-gray-400">
            Happy Hippo
          </div>
        </a>
        <ul className="md:flex md:items-center">
          {links.map((Link) =>
            props.name === Link.name ? (
              <li key={Link.name} className="md:ml-8 text-xl">
                <a
                  href={Link.link}
                  className="font-bold text-gray-800 hover:text-gray-400 border-b-2 border-purple-600"
                >
                  {Link.name}
                </a>
              </li>
            ) : (
              <li key={Link.name} className="md:ml-8 text-xl">
                <a
                  href={Link.link}
                  className="text-gray-800 hover:text-gray-400"
                >
                  {Link.name}
                </a>
              </li>
            )
          )}
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({active}) => (
                    <a
                      href="/profile"
                      className={
                        "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      }
                    >
                      Your Profile
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({active}) => (
                    <a
                      href="/settings"
                      className={
                        "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      }
                    >
                      Settings
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({active}) => (
                    <a
                      onClick={() => setOpenSignOut(true)}
                      className={
                        "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer"
                      }
                    >
                      Sign out
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </ul>
      </div>
      <Transition.Root show={openSignOut} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenSignOut}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Are you sure you want to sign out?
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={logout}
                    >
                      Sign Out
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpenSignOut(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Navbar;
