import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../global/Spinner';
import { Button, IconButton } from '@material-tailwind/react';
import { FiPlus } from 'react-icons/fi';
import { getLocations, createLocDetail, updateLocDetail, deleteLocDetail } from '../../global/api';
import { StatusContext } from '../../contexts/Status';

const StepFive = () => {
    const { ClientID } = useContext(StatusContext);
    const [isLoading, setIsLoading] = useState(true);
    const [manualRows, setManualRows] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        getLocations(ClientID).then(response => {
            if (response) {
                // Assuming the response structure matches your expected format
                const rows = response.map(location => ({
                    Client_ID: ClientID,
                    Work_Location_ID: location.Work_Location_ID || '',
                    Location_Name: location.Location_Name || '',
                    Address: location.Address || '',
                    Post_Code: location.Post_Code || '',
                    Country: location.Country || '',
                    Impact_Modifier: location.Impact_Modifier || '',
                }));
                setManualRows(rows);
            } else {
                setError('Failed to fetch locations');
            }
            setIsLoading(false);
        }).catch(err => {
            setError(`Error fetching locations: ${err.message}`);
            setIsLoading(false);
        });
    }, [ClientID]);

    const handleAddRow = () => {
        setManualRows([...manualRows, { Client_ID: ClientID, Work_Location_ID: '', Location_Name: '', Address: '', Post_Code: '', Country: '', Impact_Modifier: '' }]);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...manualRows];
        updatedRows[index] = { ...updatedRows[index], [name]: value };
        setManualRows(updatedRows);
    };

    const handleSaveChanges = async () => {
        setIsLoading(true);
        try {
            // Assuming createLocDetail can handle batch operations or you may need to iterate
            await Promise.all(manualRows.map(row => updateLocDetail(row)));
            alert('Location details updated successfully.');
        } catch (err) {
            setError(`Error updating location details: ${err.message}`);
            console.error(err);
        }
        setIsLoading(false);
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto p-4">
            {isLoading ? (
                <Spinner />
            ) : error ? (
                <div className="text-red-500">{error}</div>
            ) : (
                <>
                    <h2 className="text-xl font-semibold mb-4">Location Details for Client: {ClientID}</h2>
                    <div className="mb-4 flex justify-end w-full">
                        <IconButton onClick={handleAddRow} size="sm">
                            <FiPlus className="text-green-500" />
                        </IconButton>
                    </div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location ID</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post Code</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Impact Modifier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {manualRows.map((row, index) => (
                                <tr key={index} className="bg-white">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><input type="text" value={row.Work_Location_ID} onChange={(e) => handleInputChange(index, e)} name="Work_Location_ID" className="input input-bordered w-full" /></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><input type="text" value={row.Location_Name} onChange={(e) => handleInputChange(index, e)} name="Location_Name" className="input input-bordered w-full" /></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><input type="text" value={row.Address} onChange={(e) => handleInputChange(index, e)} name="Address" className="input input-bordered w-full" /></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><input type="text" value={row.Post_Code} onChange={(e) => handleInputChange(index, e)} name="Post_Code" className="input input-bordered w-full" /></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><input type="text" value={row.Country} onChange={(e) => handleInputChange(index, e)} name="Country" className="input input-bordered w-full" /></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><input type="text" value={row.Impact_Modifier} onChange={(e) => handleInputChange(index, e)} name="Impact_Modifier" className="input input-bordered w-full" /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <Button onClick={handleSaveChanges} color="green">Save Changes</Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default StepFive;
