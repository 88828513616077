import React, {useState} from "react";
import {useContext} from "react";
import {AccountContext} from "../../contexts/Account";
import {Navigate, useNavigate} from "react-router-dom";
import {CognitoUser} from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import {SendEmail} from "../../global/SendMail";

const Reset = () => {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [code, setCode] = useState(false);

  const [error, setError] = useState(null);

  const [checkPassword, setCheckPassword] = useState("");
  const [password, setPassword] = useState("");

  const {resetPassword, confirmPassword} = useContext(AccountContext);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    // resetPassword(email)
    //   .then((data) => {
    //     console.log("Code sent!", data);
    //     setCode(true);
    //     // window.location.reload(false);
    //   })
    //   .catch((err) => {
    //     console.error("Failed to send Code ", err);
    //     setError(err.message);
    //   });
    const user = new CognitoUser({
      Username: email,
      Pool: Pool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        console.log(data);
        setCode(true);
      },
      onFailure: (err) => {
        console.error(err);
        setError(err.message);
      },
    });

    console.log("submitted");
  };

  const submitNewPass = (e) => {
    e.preventDefault();
    // confirmPassword(email, verificationCode, password)
    //   .then((data) => {
    //     console.log("Password Reset!", data);
    //     navigate("/signin");
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    const user = new CognitoUser({
      Username: email,
      Pool: Pool,
    });

    user.confirmPassword(verificationCode, password, {
      onSuccess: (data) => {
        console.log(data);
        navigate("/signin");
      },
      onFailure: (err) => {
        console.error("error", err);
        setError(err.message);
      },
    });
  };

  return (
    <div className="flex h-screen ">
      <div className="max-w-5xl w-3/5 m-auto ">
        <div className="flex flex-col items-center md:flex-row">
          <div className="w-full mt-16 md:mt-0 ">
            {!code ? (
              <form onSubmit={onSubmit}>
                <div
                  className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7"
                  data-rounded="rounded-lg"
                  data-rounded-max="rounded-full"
                >
                  <h3 className="mb-6 text-2xl font-medium text-center">
                    Reset Password
                  </h3>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                    data-rounded="rounded-lg"
                    data-primary="blue-500"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="text-md text-red-600">{error}</div>
                  <div className="block">
                    <button
                      className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg"
                      data-primary="blue-600"
                      data-rounded="rounded-lg"
                    >
                      Send Code
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={submitNewPass}>
                <div
                  className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7"
                  data-rounded="rounded-lg"
                  data-rounded-max="rounded-full"
                >
                  <h3 className="mb-6 text-2xl font-medium text-center">
                    Reset Password
                  </h3>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                    data-rounded="rounded-lg"
                    data-primary="blue-500"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <>
                    <input
                      type="text"
                      name="checkPassword"
                      id="checkPassword"
                      className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                      data-rounded="rounded-lg"
                      data-primary="blue-500"
                      placeholder="Verification Code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                      data-rounded="rounded-lg"
                      data-primary="blue-500"
                      placeholder="Confirm Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </>
                  <div className="text-md text-red-600">{error}</div>
                  <div className="block">
                    <button
                      className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg"
                      data-primary="blue-600"
                      data-rounded="rounded-lg"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
