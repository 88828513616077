import {
  ArrowDownIcon,
  BeakerIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faMale,
  faCircleUp,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {Bar} from "react-chartjs-2";
import Navbar from "../../global/NavbarAdmin";
import AdminTableComponent from "./Components/AdminTableComponent";
import LineChart from "./Components/LineChart";

import React, {useContext, useEffect, useState} from "react";
import {SelectColumnFilter} from "./Components/AdminTable";
import Status, {StatusContext} from "../../contexts/Status";

import {AdminContext} from "./Admin";
import BarChart from "./Components/BarChart";
import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import Sidebar from "./Sidebar";
import {
  getCompanies,
  getDepartment,
  getEmployees,
  getJobDetails,
} from "../../global/api";
import StatisticsChart from "./Components/Dashboard/DataChart";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Collapse,
  IconButton,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import {
  BanknotesIcon,
  ChartBarIcon,
  ClockIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import chartsConfig from "./Components/Org/charts-config";
import StatisticsCard from "./Components/Dashboard/DataCard";
import Chart from "react-apexcharts";
import lineChartsConfig from "./Components/Org/line-charts-config";
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "./Components/Breadcrumbs";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);


const AdminOrg = () => {
  const navigate = useNavigate();

  const {selectedEmp, setSelectedEmp} = useContext(AdminContext);

  const {
    Email, 
    ClientID, 
    ClientName, 
    CompanyID, 
    CompanyName,
  } = useContext(StatusContext);

  const [company, setCompany] = useState(null);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [updatedEmp, setUpdatedEmp] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [managers, setManagers] = useState(null);

  const fetchComps = async () => {
    const fetchedComps = await getCompanies(CompanyID);
    console.log("Fetched Company", fetchedComps);
    setCompany(fetchedComps);
  };

  const fetchEmployees = async () => {
    const emps = await getEmployees(CompanyID);
    const departments = await getDepartment(CompanyID);
    const jobDetails = await getJobDetails(CompanyID);
    // console.log("departments", departments);
    setDepartments(departments);
    setSelectedEmp(emps[0]);
    setDepartments([1, 2, 3, 4, 5]);

    function getAllManagers(employees) {
      const managerIds = new Set();

      employees.forEach((employee) => {
        if (employee.Reports_To !== null) {
          managerIds.add(employee.Reports_To);
        }
      });

      return [...managerIds];
    }

    function getManagersData(allManagers, employees) {
      const managersData = [];

      allManagers.forEach((managerId) => {
        const manager = employees.find(
          (employee) => employee.Employee_ID === managerId
        );
        if (manager) {
          managersData.push(manager);
        }
      });

      return managersData;
    }

    // Function to calculate green, yellow, and red cards for managers
    function assignFlightRiskStatusToManagers(managersData, employees) {
      managersData.forEach((manager) => {
        // Initialize counters for green, yellow, and red cards
        let greenCount = 0;
        let yellowCount = 0;
        let redCount = 0;

        // Iterate through employees to calculate the counts
        employees.forEach((employee) => {
          if (employee.Reports_To === manager.Employee_ID) {
            const flightRiskScore = employee.Flight_Risk_Score;

            if (flightRiskScore > 0.8) {
              greenCount++;
            } else if (flightRiskScore > 0.5) {
              yellowCount++;
            } else {
              redCount++;
            }
          }
        });

        // Assign the counts to the manager's data
        manager.green = greenCount;
        manager.yellow = yellowCount;
        manager.red = redCount;
      });
    }

    // Example usage:
    const allManagers = getAllManagers(emps);
    const managersData = getManagersData(allManagers, emps);
    assignFlightRiskStatusToManagers(managersData, emps);
    // console.log(managersData); // Managers' data with green, yellow, and red cards

    setManagers(managersData);

    setUpdatedEmp(
      emps.map((emp) => {
        const department = departments.find(
          (dept) => dept.Department_ID === emp.Department_ID
        );

        const job = jobDetails.find((job) => job.Job_ID === emp.Job_ID);

        return {
          ...emp,
          Date_of_Hire: emp.Date_of_Hire.substring(0, 10),
          Department_Name: department
            ? department.Department_Name
            : "Unknown Department",
          Title: job ? job.Job_Title : "Unkown Title",
        };
      })
    );
    console.log(updatedEmp);
    calcGender(emps);
  };
  const calcGender = (emps) => {
    // Count the number of female employees
    let femaleCount = emps.filter(
      (employee) => employee.Gender === "Female"
    ).length;

    // Calculate the percentage of female employees
    let femalePercentage = (femaleCount / emps.length) * 100;

    setMale(100 - femalePercentage.toFixed(2));
    setFemale(femalePercentage.toFixed(2));
  };
  useEffect(() => {
    fetchEmployees();
    fetchComps();
  }, []);

  const websiteViewsChart = managers
    ? {
        type: "bar",
        height: 220,
        series: [
          {
            name: "High Risk",
            data: managers.map((manager) => manager.red),
          },
          {
            name: "Medium Risk",
            data: managers.map((manager) => manager.yellow),
          },
          {
            name: "Low Risk",
            data: managers.map((manager) => manager.green),
          },
        ],
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        options: {
          ...chartsConfig,
          colors: ["#B81D13", "#EFB700", "#008450"],
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            stackType: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true, // Set horizontal to true for a horizontal stacked bar chart
              barHeight: "80%", // Adjust barHeight for better spacing between bars
              borderRadius: 5,
            },
          },

          xaxis: {
            ...chartsConfig.yaxis, // Use the yaxis config for the horizontal X-axis
            categories: managers.map(
              (manager) => manager.First_Name + " " + manager.Last_Name
            ),
          },
        },
      }
    : null;
  const statisticsChartsData = [
    {
      color: "white",
      title: "Stackup by Manager",
      description: "Manager levels",
      footer: "updated today",
      chart: websiteViewsChart,
    },
  ];

  const statisticsCardsData = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Total Risk",
      value: company ? `${company.slice(-1)[0].total_risk}` : "0",
      footer: {
        color: "text-red-500",
        value: "+20%",
        label: "than last week",
      },
    },
    {
      color: "pink",
      icon: UserIcon,
      title: "Male/Female",
      value: `${male}%/${female}%`,
      footer: {
        // color: "text-red-500",
        // value: "+3%",
        // label: "than last month",
      },
    },
    {
      color: "green",
      icon: UserPlusIcon,
      title: "Since Last Quarter",
      value: "9.7%",
      footer: {
        color: "text-red-500",
        value: "-2%",
        label: "than yesterday",
      },
    },
    {
      color: "orange",
      icon: ChartBarIcon,
      title: "YTD",
      value: "11.3%",
      footer: {
        color: "text-green-500",
        value: "+5%",
        label: "than yesterday",
      },
    },
  ];

  const employee_data_chart = {
    type: "line",
    height: 220,
    series: [
      {
        name: "Sales",
        data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
      },
    ],
    options: {
      ...lineChartsConfig,
      colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...lineChartsConfig.xaxis,
        categories: [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
  };

  const TABLE_HEAD = [
    "Name",
    "Flight Risk Score",
    "Title",
    "Department",
    "Date Of Hire",
  ];

  const ITEMS_PER_PAGE = 10;

  const [currentPage, setCurrentPage] = useState(1);

  // Function to get the data for the current page
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Filter the data based on the search query
    const filteredData = updatedEmp.filter((employee) =>
      `${employee.First_Name} ${employee.Last_Name}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    return filteredData.slice(startIndex, endIndex);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [open, setOpen] = React.useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      {/* <Navbar name="ORG"></Navbar> */}
      {updatedEmp === null ? (
        <Spinner />
      ) : (
        <div className="flex">
          <Sidebar />
          <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
            <Breadcrumb
              link={"/admin/Dashboard/"}
              linkCrumb={"/Dashboard/Org"}
              title={"Organization"}
            ></Breadcrumb>
            <div className="h-6"></div>
            <div className="w-4/5">
              <div className="my-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
                {statisticsCardsData.map(({icon, title, footer, ...rest}) => (
                  <StatisticsCard
                    key={title}
                    {...rest}
                    title={title}
                    icon={React.createElement(icon, {
                      className: "w-6 h-6 text-white",
                    })}
                    // footer={
                    //   <Typography className="font-normal text-blue-gray-600">
                    //     <strong className={footer.color}>{footer.value}</strong>
                    //     &nbsp;{footer.label}
                    //   </Typography>
                    // }
                  />
                ))}
              </div>
              <div className="mt-20 mb-6">
                {statisticsChartsData.map((props) => (
                  <StatisticsChart
                    key={props.title}
                    {...props}
                    footer={
                      <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                      >
                        <ClockIcon
                          strokeWidth={2}
                          className="h-4 w-4 text-inherit"
                        />
                        &nbsp;{props.footer}
                      </Typography>
                    }
                  />
                ))}
              </div>
            </div>
            <div className="h-7"></div>
            <Card className="h-full w-4/5">
              <CardHeader
                floated={false}
                shadow={false}
                className="rounded-none"
              >
                <div className="mb-8 flex items-center justify-between gap-8">
                  <div>
                    <Typography variant="h5" color="blue-gray">
                      Employee list
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                      Click on an Employee to see more info
                    </Typography>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                  <div className="w-full md:w-72">
                    <Input
                      label="Search"
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody className="overflow-scroll px-0">
                <table className="mt-4 w-full table-auto text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head, index) => (
                        <th
                          key={head}
                          className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                          >
                            {head}{" "}
                            {index !== TABLE_HEAD.length - 1 && (
                              <ChevronUpDownIcon
                                strokeWidth={2}
                                className="h-4 w-4"
                              />
                            )}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {getCurrentPageData().map(
                      (
                        {
                          Employee_ID,
                          First_Name,
                          Last_Name,
                          Flight_Risk_Score,
                          Title,
                          Department_Name,
                          Date_of_Hire,
                          Annual_Salary,
                        },
                        index
                      ) => {
                        const isLast = index === updatedEmp.length - 1;
                        const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";

                        const employee_data = [
                          {
                            color:
                              Flight_Risk_Score > 0.8
                                ? "red"
                                : Flight_Risk_Score > 0.5
                                ? "yellow"
                                : "blue-gray",
                            title: "Total Compensation",
                            // description: "Flight Risk Score",
                            footer: "just updated",
                            chart: employee_data_chart,
                          },
                        ];

                        const employee_data_more_data = [
                          {
                            color: "blue-gray",
                            title: Annual_Salary,
                            description: "Current Base Pay",
                            // footer: "just updated",
                            chart: employee_data_chart,
                          },
                          {
                            color: "blue-gray",
                            title: "$16,280",
                            description: "Current Performance Incentive",
                            // footer: "just updated",
                            chart: employee_data_chart,
                          },
                          {
                            color: "blue-gray",
                            title: ".78",
                            description: "Current Comp. Ratio",
                            // footer: "just updated",
                            chart: employee_data_chart,
                          },
                        ];

                        const TripleStatitics = [
                          {
                            color: "blue-gray",
                            icon: BanknotesIcon,
                            title: "Bonus Eligible",
                            value: "12%",
                            footer: {
                              color: "text-green-500",
                              value: "+55%",
                              label: "than last week",
                            },
                          },
                          {
                            color: "pink",
                            icon: UserIcon,
                            title: "Date of Joining",
                            value: Date_of_Hire,
                            footer: {
                              color: "text-green-500",
                              value: "+3%",
                              label: "than last month",
                            },
                          },
                          {
                            color: "green",
                            icon: UserPlusIcon,
                            title: "Annual PTO",
                            value: "15 Days",
                            footer: {
                              color: "text-red-500",
                              value: "-2%",
                              label: "than yesterday",
                            },
                          },
                          {
                            color: "orange",
                            icon: ChartBarIcon,
                            title: "Last Salary Raise",
                            value: "16 Jan, 2020",
                            footer: {
                              color: "text-green-500",
                              value: "+5%",
                              label: "than yesterday",
                            },
                          },
                        ];

                        return (
                          // <Accordion open={open === Employee_ID}>
                          //   <AccordionHeader
                          //     onClick={() => handleOpen(Employee_ID)}
                          //   >
                          <>
                            <tr
                              key={Employee_ID}
                              className="hover:bg-blue-gray-50 hover:cursor-pointer"
                              onClick={() => {
                                handleOpen(Employee_ID);
                                navigate(
                                  `/admin/dashboard/org/emp/${Employee_ID}`
                                );
                              }}
                            >
                              <td className={classes}>
                                <div className="">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {First_Name + " " + Last_Name}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="w-max">
                                  <Chip
                                    variant="ghost"
                                    size="sm"
                                    value={
                                      Flight_Risk_Score > 0.8
                                        ? "High"
                                        : Flight_Risk_Score > 0.5
                                        ? "Med"
                                        : "Low"
                                    }
                                    color={
                                      Flight_Risk_Score > 0.8
                                        ? "red"
                                        : Flight_Risk_Score > 0.5
                                        ? "yellow"
                                        : "green"
                                    }
                                  />
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {Title}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {Department_Name}
                                  </Typography>
                                </div>
                              </td>

                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {Date_of_Hire}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              {/* <td colSpan="5">
                                <div className="w-full">
                                  <Collapse open={open === Employee_ID}>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-6">
                                      <div className="flex flex-col">
                                        {employee_data.map((props) => (
                                          <StatisticsChart
                                            key={props.title}
                                            {...props}
                                            footer={
                                              <Typography
                                                variant="small"
                                                className="flex items-center font-normal text-blue-gray-600"
                                              >
                                                <ClockIcon
                                                  strokeWidth={2}
                                                  className="h-4 w-4 text-inherit"
                                                />
                                                &nbsp;{props.footer}
                                              </Typography>
                                            }
                                          />
                                        ))}

                                        <div className="my-10 flex flex-row">
                                          {employee_data_more_data.map(
                                            (props) => (
                                              <TripleStatisticsChart
                                                key={props.title}
                                                {...props}
                                                footer={
                                                  <Typography
                                                    variant="small"
                                                    className="flex items-center font-normal text-blue-gray-600"
                                                  >
                                                    <ClockIcon
                                                      strokeWidth={2}
                                                      className="h-4 w-4 text-inherit"
                                                    />
                                                    &nbsp;{props.footer}
                                                  </Typography>
                                                }
                                              />
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-2 md:grid-cols-1">
                                        {TripleStatitics.map(
                                          ({icon, title, footer, ...rest}) => (
                                            <div className="w-1/2 h-auto">
                                              <QuadStatisticsCard
                                                key={title}
                                                {...rest}
                                                title={title}
                                                icon={React.createElement(
                                                  icon,
                                                  {
                                                    className:
                                                      "w-6 h-6 text-white",
                                                  }
                                                )}
                                                footer={
                                                  <Typography className="font-normal text-blue-gray-600">
                                                    <strong
                                                      className={footer.color}
                                                    >
                                                      {footer.value}
                                                    </strong>
                                                    &nbsp;{footer.label}
                                                  </Typography>
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </td> */}
                            </tr>
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
              <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  Page {currentPage} of{" "}
                  {Math.ceil(updatedEmp.length / ITEMS_PER_PAGE)}
                </Typography>
                <div className="flex gap-2">
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(updatedEmp.length / ITEMS_PER_PAGE)
                    }
                  >
                    Next
                  </Button>
                </div>
              </CardFooter>
            </Card>
            <Footer></Footer>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminOrg;

function TripleStatisticsChart({color, chart, title, description, footer}) {
  return (
    <Card>
      <CardHeader variant="gradient" color={color}>
        <Chart {...chart} />
      </CardHeader>
      <CardBody className="p-6">
        <Typography variant="h6" color="blue-gray">
          {title}
        </Typography>
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {description}
        </Typography>
      </CardBody>
    </Card>
  );
}

function QuadStatisticsCard({color, icon, title, value, footer}) {
  return (
    <Card>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        <Typography variant="h4" color="blue-gray">
          {value}
        </Typography>
      </CardBody>
    </Card>
  );
}
