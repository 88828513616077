import React, { useState, useContext } from 'react';
import { AccountContext } from '../../contexts/Account';
import { useNavigate } from 'react-router-dom';
import { StatusContext } from '../../contexts/Status';

const SignIn = () => {
    const { authenticate } = useContext(AccountContext);
    const { refreshUserStatus, SuperAdmin, Admin, Manager } = useContext(StatusContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const onSubmit = async (e) => {
      e.preventDefault();
      try {
          const sessionData = await authenticate(email, password);
          console.log('Authentication Success: ', sessionData);

          await refreshUserStatus();

          // Navigate based on user role
          if (SuperAdmin) {
            console.log("Navigating to super dashboard");
            navigate('/super/dashboard');
          } else if (Admin) {
            navigate('/admin/dashboard');
          } else if (Manager) {
            navigate('/manager/dashboard');
          } else {
            navigate('/dashboard'); // or some default route
          }
      } catch (err) {
          console.error("onFailure: ", err);
          setError(err.message);
      }
    };

    return (
        <div className="flex h-screen">
            <div className="max-w-5xl m-auto">
                <div className="flex flex-col items-center md:flex-row">
                    <div className="w-full space-y-5 md:w-3/5 md:pr-16">
                        <h2 className="text-2xl font-extrabold leading-none text-black sm:text-3xl md:text-5xl">
                            HappyHippo
                        </h2>
                        <p className="text-xl text-gray-600 md:pr-16">
                            Transform your talent retention and DEI initiatives into strategic competitive advantages.
                        </p>
                    </div>
                    <div className="w-full mt-16 md:mt-0 md:w-3/5">
                        <form onSubmit={onSubmit}>
                            <div className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
                                <h3 className="mb-6 text-2xl font-medium text-center">
                                    Sign in to your Account
                                </h3>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="block w-full px-4 py-3 mb-4 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {error && <div className="text-md text-red-600">{error}</div>}
                                <div className="block">
                                    <button
                                        type="submit"
                                        className="w-full px-3 py-4 font-medium text-white bg-blue-600 rounded-lg"
                                    >
                                        Log In
                                    </button>
                                </div>
                                <p className="w-full mt-4 text-sm text-center text-gray-500">
                                    Don't have an account?{' '}
                                    <a href="/signup" className="text-blue-500 underline">
                                        Sign up here
                                    </a>
                                </p>
                                <p className="w-full mt-4 text-sm text-center text-gray-500">
                                    Forgot your password?{' '}
                                    <a href="/reset" className="text-blue-500 underline">
                                        Reset Password
                                    </a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;