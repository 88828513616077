import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./auth/Components/Signup";
import SignIn from "./auth/Components/SignIn";
import DashboardManager from "./pages/manager/Dashboard";
import { Account } from "./contexts/Account";
import ManagerOrg from "./pages/manager/Org";
import CSVTest from "./pages/CSVTest";
import ProtectedRoutes from "./auth/Components/ProtectedRoutes";
import DashboardAdmin from "./pages/admin/DashboardAdmin";
import AdminOrg from "./pages/admin/Org";
import Profile from "./pages/Profile";
import IsAdmin from "./auth/Components/IsAdmin";
import IsSuper from "./auth/Components/IsSuper";
import IsAuditor from "./auth/Components/IsAuditor";
import IsHR from "./auth/Components/IsHR";
import IsExecutive from "./auth/Components/IsExecutive";
import IsRecorder from "./auth/Components/IsRecorder";
import { Status } from "./contexts/Status";
import IsSignedIn from "./auth/Components/IsSignedIn";
import Main from "./global/Main";
import NotFound from "./global/NotFound";
import { Admin } from "./pages/admin/Admin";
import Workflows from "./pages/admin/Workflows";
import Actions from "./pages/admin/Actions";
import ManagerActions from "./pages/manager/MyActions";
import Reset from "./auth/Components/Reset";
import DashboardSuper from "./pages/super/Dashboard";
import SuperUsers from "./pages/super/Users";
import SuperClients from "./pages/super/Clients";
import IsManager from "./auth/Components/IsManager";
import ApproverPage from "./pages/approver/Dashboard";
import AuditorPage from "./pages/Auditor";
import ExecutivePage from "./pages/Executive";
import RecorderPage from "./pages/Recorder";
import IsApprover from "./auth/Components/IsApprover";
import Users from "./pages/admin/Users";
import ApproverActions from "./pages/approver/MyActions";
import ApproverOrg from "./pages/approver/Org";
import Settings from "./pages/Settings";
import DashboardHR from "./pages/HR/Dashboard";
import ClientHR from "./pages/HR/Client";
import DepartmentHR from "./pages/HR/Department";
import ActionsHR from "./pages/HR/Actions";
import WorkflowHR from "./pages/HR/Workflow";
import ApproverHR from "./pages/HR/Approver";
import OrgHR from "./pages/HR/Org";
import EmailConfig from "./pages/super/Email/EmailConfig";
import EmailDashboard from "./pages/super/Email/Email";
import OnboardingWelcome from "./pages/onboarding/Welcome";
import AdminDashboardLocation from "./pages/admin/DashboardLocation";
import AdminDashoardDepartment from "./pages/admin/DashboardDepartment";
import AdminDashboardJob from "./pages/admin/DashboardJob";
import { Manager } from "./pages/manager/Manager";
import Approvals from "./pages/admin/Approvals";
import EmployeeDetails from "./pages/admin/Components/Org/EmployeeDetails";
import Onboarding from "./pages/onboarding/Onboarding";
import ConfigDepartments from "./pages/admin/ConfigDepartments";
import ConfigJobs from "./pages/admin/ConfigJobs";
import ApprovalGroups from "./pages/admin/ApprovalGroups";
import AttritionHeatmap from "./pages/admin/Heatmap";
import { ActionsProvider } from "./contexts/ActionContext";

function App() {
    return (
     
      <Account>
        <Status>
  
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/SignIn" element={<SignIn />} />
              <Route path="/reset" element={<Reset />} />
              <Route element={<IsSignedIn />}>
                <Route path="/SignIn" element={<SignIn />} />
              </Route>

              {/* Protected Routes */}

              <Route element={<ProtectedRoutes />}>
                {/* manager */}

                <Route element={<IsManager />}>
                  <Route
                    path="/manager/dashboard"
                    element={
                      <Manager>
                        <DashboardManager />
                      </Manager>
                    }
                  />
                  <Route
                    path="/manager/dashboard/org"
                    element={
                      <Manager>
                        <ManagerOrg />
                      </Manager>
                    }
                  />
                  <Route
                    path="/manager/dashboard/myactions"
                    element={
                      <Manager>
                        <ManagerActions />
                      </Manager>
                    }
                  />
                </Route>

                {/* admin */}
                <Route element={<IsAdmin />}>
                  <Route
                    path="/admin/dashboard"
                    element={
                      <Admin>
                        <DashboardAdmin />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/org"
                    element={
                      <Admin>
                        <AdminOrg />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/org/emp/:Employee_ID"
                    element={
                      <Admin>
                        <EmployeeDetails />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/approvalgroups"
                    element={
                      <Admin>
                        <ApprovalGroups />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/users"
                    element={
                      <Admin>
                        <Users />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/location"
                    element={
                      <Admin>
                        <AdminDashboardLocation />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/department"
                    element={
                      <Admin>
                        <AdminDashoardDepartment />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/job"
                    element={
                      <Admin>
                        <AdminDashboardJob />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/departments"
                    element={
                      <Admin>
                        <ConfigDepartments />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/jobs"
                    element={
                      <Admin>
                        <ConfigJobs />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/criticaljobs"
                    element={
                      <Admin>
                        <AttritionHeatmap />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/workflows"
                    element={
                      <Admin>
                        <Workflows />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/actions"
                    element={
                      <Admin>
                        <Actions />
                      </Admin>
                    }
                  />
                  <Route
                    path="/admin/dashboard/approvals"
                    element={
                      <Admin>
                        <Approvals />
                      </Admin>
                    }
                  />
                  <Route
                    path="/onboarding"
                    element={
                      <Admin>
                        <Onboarding />
                      </Admin>
                    }
                  />

                </Route>
                <Route element={<IsSuper />}>
                  <Route
                    path="/super/dashboard"
                    element={
                      <div>
                        {console.log("Rendering Super Dashboard")}
                        <DashboardSuper />
                      </div>
                    }
                  />
                  <Route
                    path="/super/dashboard/users"
                    element={<SuperUsers />}
                  />
                  <Route
                    path="/super/dashboard/clients"
                    element={<SuperClients />}
                  />
                  <Route path="/super/email" element={<EmailDashboard />} />
                  <Route
                    path="/super/email/config"
                    element={<EmailConfig />}
                  />
                </Route>
                <Route element={<IsApprover />}>
                  <Route
                    path="/approver/dashboard"
                    element={<ApproverPage />}
                  />
                  <Route
                    path="/approver/dashboard/org"
                    element={<ApproverOrg />}
                  />
                  <Route
                    path="/approver/dashboard/myactions"
                    element={<ApproverActions />}
                  />
                </Route>
                <Route element={<IsAuditor />}>
                  <Route path="/auditor" element={<AuditorPage />} />
                </Route>
                <Route element={<IsHR />}>
                  <Route path="/hr/dashboard" element={<DashboardHR />} />
                  <Route
                    path="/hr/dashboard/client"
                    element={<ClientHR />}
                  />
                  <Route
                    path="/hr/dashboard/department"
                    element={<DepartmentHR />}
                  />
                  <Route
                    path="/hr/dashboard/action"
                    element={<ActionsHR />}
                  />
                  <Route
                    path="/hr/dashboard/workflow"
                    element={<WorkflowHR />}
                  />
                  <Route
                    path="/hr/dashboard/approver"
                    element={<ApproverHR />}
                  />
                  <Route path="/hr/dashboard/myorg" element={<OrgHR />} />
                </Route>
                <Route element={<IsExecutive />}>
                  <Route path="/executive" element={<ExecutivePage />} />
                </Route>
                <Route element={<IsRecorder />}>
                  <Route path="/recorder" element={<RecorderPage />} />
                </Route>
                {/* etc */}
                <Route path="/main" element={<Main />} />
                <Route
                  path="/onboarding/welcome"
                  element={<OnboardingWelcome />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/settings" element={<Settings />} />
              </Route>
              <Route path="/csv" element={<CSVTest />} />
              <Route path="*" element={
                <div>
                  <h1>404 - Not Found</h1>
                  <p>The requested page does not exist.</p>
                </div>
              } />
            </Routes>
          </Router>
        </Status>
      </Account>
    

   );
}

export default App;