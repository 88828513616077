// CompanyChartDataContext.js

import { useContext, useMemo, createContext } from 'react';
import { useCompanyData } from './CompanyContext';
import { prepareChartDataCompany } from '../components/data/prepareChartDataCompany';

const CompanyChartDataContext = createContext();

export const CompanyChartDataProvider = ({ children }) => {

  const { companyData } = useCompanyData();
  
  const chartData = useMemo(() => {
    return prepareChartDataCompany(companyData); 
  }, [companyData]);
  console.log("ChartContext",chartData);

  return (
    <CompanyChartDataContext.Provider value={chartData}>
       {children}
    </CompanyChartDataContext.Provider>
  );

}
export const useCompanyChartData = () => useContext(CompanyChartDataContext);