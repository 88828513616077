import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUpload } from 'react-icons/fi'; // Import for the upload icon
import logo from '../../assets/logo512.png'; // Verify the path is correct
import { StatusContext } from '../../contexts/Status';

const TopNavigation = () => {
  const {
    Email, SuperAdmin, ClientID, ClientName, CompanyID, CompanyName,
    refreshUserStatus // Assuming refreshUserStatus is a function defined within StatusContext for updating user status
  } = useContext(StatusContext);
  const navigate = useNavigate();
  
  // Directly using the context values without local state duplicates unless needed for edits before update
  const handleContextChange = () => {
    // Assuming this function is meant to trigger an update based on selected values
    // This could be handling form submissions or similar actions
    refreshUserStatus(); // Call the refresh method, which presumably updates the context based on current state
    navigate('/'); // Navigate to a home or dashboard page, modify as needed
    // Consider if you need to force a reload, which might not be necessary with proper React state management
  };

  return (
    <div className="top-navigation flex items-center justify-between p-4 bg-white shadow-md">
      <img src={logo} alt="Logo" className="logo h-12 cursor-pointer" onClick={() => navigate('/admin/dashboard')} />

      <div className="flex items-center gap-4">
        <span>Email: {Email}</span>
        <span className="cursor-pointer" onClick={() => navigate('/admin/dashboard')}>Client: {ClientName || 'All'}</span>
        <span className="cursor-pointer" onClick={() => navigate('/admin/dashboard')}>Company: {CompanyName || 'All'}</span>
        {/* Assuming `/admin/dashboard/location` & `/admin/dashboard/department` are correct paths */}
        <span className="cursor-pointer" onClick={() => navigate('/admin/dashboard/location')}>Location: All</span>
        <span className="cursor-pointer" onClick={() => navigate('/admin/dashboard/department')}>Department: All</span>

        {/* Upload Data Icon and Navigation */}
        <button 
          onClick={() => navigate('/onboarding')} 
          title="Upload Data"
          className="flex items-center justify-center p-2 rounded hover:bg-gray-100"
        >
          <FiUpload className="h-6 w-6" />
        </button>

        {/* SuperAdmin Dashboard Access Button */}
        {SuperAdmin && (
          <button 
            onClick={() => navigate('/super/dashboard')}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Super Dashboard
          </button>
        )}
      </div>
    </div>
  );
};

export default TopNavigation;
