import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../global/Spinner';
import { Button } from '@material-tailwind/react';
import { getDepartment, getDeptDetails, createDeptDetail, deleteDeptDetail, updateDeptDetail } from '../../global/api';
import { StatusContext } from '../../contexts/Status';

const StepSix = () => {
    const { CompanyID } = useContext(StatusContext);
    const [isLoading, setIsLoading] = useState(true);
    const [manualRows, setManualRows] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDeptDetails(CompanyID);
                if (response) {
                    setManualRows(response);
                } else {
                    setError('No department details found');
                }
            } catch (error) {
                setError(`Error fetching department details: ${error}`);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [CompanyID]);

    const handleAddRow = () => {
        setManualRows([...manualRows, { Department_ID: '', Department_Name: '', Impact_Modifier: '' }]);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...manualRows];
        updatedRows[index][name] = value;
        setManualRows(updatedRows);
    };

    const handleSaveOrUpdateRow = async (row, index) => {
        setIsLoading(true);
        try {
            if (row.Department_ID) {
                await updateDeptDetail(row);
            } else {
                const newDetail = await createDeptDetail({ ...row, Company_ID: CompanyID });
                const updatedRows = [...manualRows];
                updatedRows[index] = newDetail;
                setManualRows(updatedRows);
            }
            setError('');
        } catch (error) {
            setError(`Error updating/creating department detail: ${error}`);
        }
        setIsLoading(false);
    };

    const handleDeleteRow = async (index) => {
        setIsLoading(true);
        try {
            const row = manualRows[index];
            if (row.Department_ID) {
                await deleteDeptDetail(row.Department_ID);
                const updatedRows = manualRows.filter((_, idx) => idx !== index);
                setManualRows(updatedRows);
            }
            setError('');
        } catch (error) {
            setError(`Error deleting department detail: ${error}`);
        }
        setIsLoading(false);
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto my-4">
            <Spinner loading={isLoading} />
            {error && <div className="text-red-500">{error}</div>}
            <Button color="blue" onClick={handleAddRow} className="mb-4">Add Department</Button>
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Department ID</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Department Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Impact Modifier</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {manualRows.map((row, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <input type="text" value={row.Department_ID} onChange={(e) => handleInputChange(index, e)} name="Department_ID" className="input input-bordered w-full" />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <input type="text" value={row.Department_Name} onChange={(e) => handleInputChange(index, e)} name="Department_Name" className="input input-bordered w-full" />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <input type="text" value={row.Impact_Modifier} onChange={(e) => handleInputChange(index, e)} name="Impact_Modifier" className="input input-bordered w-full" />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <Button color="green" onClick={() => handleSaveOrUpdateRow(row, index)} className="mr-2">Save</Button>
                                <Button color="red" onClick={() => handleDeleteRow(index)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StepSix;
