import React, {useEffect, useContext} from "react";
import Sidebar from "./Sidebar";
import {useParams} from "react-router-dom";
import {
  createApprovalGroupEmail,
  deleteApprovalGroupEmail,
  getApprovalGroups,
  getApprovalGroupsEmail,
  getUsers,
} from "../../global/api";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Avatar,
  Tooltip,
  Progress,
  Breadcrumbs,
  Chip,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Select,
  Option,
  DialogFooter,
} from "@material-tailwind/react";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import {CheckIcon} from "lucide-react";
import Spinner from "../../global/Spinner";
import {StatusContext} from "../../contexts/Status";

const ApprovalGroups = () => {
  const {
    Email, ClientID, ClientName, CompanyID, CompanyName,
  } = useContext(StatusContext);

  const [approvalGroups, setApprovalGroups] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [approvalGroupEmails, setApprovalGroupEmails] = React.useState(null);

  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedUserOutsideGroup, setSelectedUserOutsideGroup] =
    React.useState(null);

  const fetchApprovalGroups = async () => {
    const fetchedGroups = await getApprovalGroups(CompanyID);
    console.log("Fetched Approval Groups", fetchedGroups);
    setApprovalGroups(fetchedGroups);
    setSelectedGroup(fetchedGroups[0]);
  };

  const fetchEmails = async () => {
    const fetchedEmails = await getApprovalGroupsEmail(CompanyID);
    const filteredFetchedEmails = fetchedEmails.filter(
      (groupEmail) =>
        groupEmail.Approval_Group_ID === selectedGroup.Approval_Group_ID
    );

    console.log("Fetched Emails", filteredFetchedEmails);
    setApprovalGroupEmails(filteredFetchedEmails);

    const Users = await getUsers();
    const filteredUsers = Users.filter(
      (user) =>
        user.Client_ID === ClientID &&
        !user.Super_Admin &&
        !filteredFetchedEmails.some(
          (email) => email.Email_Address === user.Email_Address
        )
    );

    console.log("Users", filteredUsers);
    setUsers(filteredUsers);

    setSelectedUserOutsideGroup(filteredUsers[0]);
  };
  useEffect(() => {
    fetchApprovalGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      fetchEmails();
    }
  }, [selectedGroup]);

  const handleAddUser = async () => {
    await createApprovalGroupEmail({
      Company_ID: CompanyID,
      Approval_Group_ID: selectedGroup.Approval_Group_ID,
      Email_Address: selectedUserOutsideGroup.Email_Address,
    });
    window.location.reload(false);
  };

  const handleDelete = async (user) => {
    await deleteApprovalGroupEmail(
      CompanyID,
      selectedGroup.Approval_Group_ID,
      user.Email_Address
    );
    window.location.reload(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div className="flex">
      <Sidebar />

      <div className="flex flex-col bg-[#F2F2F2] items-center justify-center min-h-screen font-fabriga w-screen">
        {approvalGroups && users && approvalGroupEmails ? (
          <div className="flex flex-row space-x-5">
            <Card className="overflow-hidden xl:col-span-2">
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 flex items-center justify-between p-6"
              >
                <div>
                  <Typography variant="h6" color="blue-gray" className="mb-1">
                    Approval Groups
                  </Typography>
                  <Typography
                    variant="small"
                    className="flex items-center gap-1 font-normal text-blue-gray-600"
                  >
                    Click on a group to view details
                  </Typography>
                </div>
                <Menu placement="left-start">
                  <MenuHandler>
                    <IconButton size="sm" variant="text" color="blue-gray">
                      <EllipsisVerticalIcon
                        strokeWidth={3}
                        fill="currenColor"
                        className="h-6 w-6"
                      />
                    </IconButton>
                  </MenuHandler>
                  <MenuList>
                    <MenuItem>Action</MenuItem>
                    <MenuItem>Another Action</MenuItem>
                    <MenuItem>Something else here</MenuItem>
                  </MenuList>
                </Menu>
              </CardHeader>
              <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                <table className="w-full min-w-[640px] table-auto">
                  <thead>
                    <tr>
                      {["Name", "ID", "Location"].map((el) => (
                        <th
                          key={el}
                          className="border-b border-blue-gray-50 py-3 px-6 text-left"
                        >
                          <Typography
                            variant="small"
                            className="text-[11px] font-medium uppercase text-blue-gray-400"
                          >
                            {el}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {approvalGroups.map((group) => {
                      const className = `py-3 px-5 ${
                        group.key === approvalGroups.length - 1
                          ? ""
                          : "border-b border-blue-gray-50"
                      }`;
                      const isLast = approvalGroups.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr
                          key={group.Approval_Group_ID}
                          className={
                            selectedGroup.Approval_Group_ID ===
                            group.Approval_Group_ID
                              ? "bg-blue-gray-50 hover:bg-blue-gray-50, cursor-pointer"
                              : " hover:bg-blue-gray-50, cursor-pointer"
                          }
                          onClick={() => {
                            handleOpen();
                            setSelectedGroup(group);
                          }}
                        >
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              {/* <Avatar
                                          src={img}
                                          alt={name}
                                          size="sm"
                                        /> */}
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {group.Approval_Group_Display_Name}
                              </Typography>
                            </div>
                          </td>
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {group.Approval_Group_ID}
                              </Typography>
                            </div>
                          </td>
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {group.Location_ID}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            <Dialog open={open} handler={handleOpen}>
              <Card className="overflow-hidden xl:col-span-2">
                <CardHeader
                  floated={false}
                  shadow={false}
                  color="transparent"
                  className="m-0 flex items-center justify-between p-6"
                >
                  <div>
                    <Typography variant="h6" color="blue-gray" className="mb-1">
                      Users
                    </Typography>
                    <Typography
                      variant="small"
                      className="flex items-center gap-1 font-normal text-blue-gray-600"
                    >
                      Click on a user to move to an Approval Group
                    </Typography>
                  </div>
                  <Menu placement="left-start">
                    <MenuHandler>
                      <IconButton size="sm" variant="text" color="blue-gray">
                        <EllipsisVerticalIcon
                          strokeWidth={3}
                          fill="currenColor"
                          className="h-6 w-6"
                        />
                      </IconButton>
                    </MenuHandler>
                    <MenuList>
                      <MenuItem>Action</MenuItem>
                      <MenuItem>Another Action</MenuItem>
                      <MenuItem>Something else here</MenuItem>
                    </MenuList>
                  </Menu>
                </CardHeader>
                <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <table className="w-full min-w-[320px] table-auto">
                        <thead>
                          <tr>
                            {["All Users"].map((el) => (
                              <th
                                key={el}
                                className="border-b border-blue-gray-50 py-3 px-6 text-left"
                              >
                                <Typography
                                  variant="small"
                                  className="text-[11px] font-medium uppercase text-blue-gray-400"
                                >
                                  {el}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user) => {
                            const className = `py-3 px-5 ${
                              user.key === user.length - 1
                                ? ""
                                : "border-b border-blue-gray-50"
                            }`;
                            const isLast = approvalGroups.length - 1;
                            const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-blue-gray-50";

                            return (
                              <tr
                                // key={user.Email_Address}
                                className={
                                  selectedUserOutsideGroup.Email_Address ===
                                  user.Email_Address
                                    ? "bg-blue-gray-50 hover:bg-blue-gray-50, cursor-pointer"
                                    : " hover:bg-blue-gray-50, cursor-pointer"
                                }
                                onClick={() => {
                                  setSelectedUserOutsideGroup(user);
                                }}
                              >
                                <td className={className}>
                                  <div className="flex items-center gap-4">
                                    {/* <Avatar
                                        src={img}
                                        alt={name}
                                        size="sm"
                                      /> */}
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-bold"
                                    >
                                      {user.Email_Address}
                                    </Typography>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <table className="w-full min-w-[320px] table-auto">
                        <thead>
                          <tr>
                            {[
                              selectedGroup.Approval_Group_Display_Name,
                              "Delete",
                            ].map((el) => (
                              <th
                                key={el}
                                className="border-b border-blue-gray-50 py-3 px-6 text-left"
                              >
                                <Typography
                                  variant="small"
                                  className="text-[11px] font-medium uppercase text-blue-gray-400"
                                >
                                  {el}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {approvalGroupEmails.map((user) => {
                            const className = `py-3 px-5 ${
                              user.key === user.length - 1
                                ? ""
                                : "border-b border-blue-gray-50"
                            }`;
                            const isLast = approvalGroups.length - 1;
                            const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-blue-gray-50";

                            return (
                              <tr
                                key={user.Email_Address}
                                className=""
                                onClick={() => {
                                  setSelectedUserOutsideGroup(user);
                                }}
                              >
                                <td className={className}>
                                  <div className="flex items-center gap-4">
                                    {/* <Avatar
                                        src={img}
                                        alt={name}
                                        size="sm"
                                      /> */}
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-bold"
                                    >
                                      {user.Email_Address}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={className}>
                                  <Button
                                    onClick={() => {
                                      handleDelete(user);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                                      />
                                    </svg>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Button
                      onClick={() => {
                        handleAddUser(selectedUserOutsideGroup);
                      }}
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-medium uppercase text-white-400"
                      >
                        Add User
                      </Typography>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Dialog>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default ApprovalGroups;
