import React from "react";
import Navbar from "../../global/NavbarHR";

const DashboardHR = () => {
  return (
    <>
      <Navbar name="DASHBOARD"></Navbar>
      <div>HR</div>
    </>
  );
};

export default DashboardHR;
