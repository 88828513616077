import React, { useContext } from "react";
import SidebarComponent, { SidebarItem } from "../../global/SidebarComponent";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../contexts/Status";
import { createElement } from "react";

const Sidebar = () => {
  const navigate = useNavigate();
  const { Email, ClientID, CompanyID, ClientName, CompanyName, ApprovalGroup } = useContext(StatusContext);

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(`/${path}`);
  }

  // Dashboard Icon
    const DashboardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
      <path fillRule="evenodd" d="M10 2a8 8 0 100 16 8 8 0 000-16zM5 9a1 1 0 011-1h3v3H6a1 1 0 01-1-1V9zm4 4a1 1 0 011-1h3v3h-3a1 1 0 01-1-1v-1zm1-9a1 1 0 011-1h3a1 1 0 010 2h-3a1 1 0 01-1-1zM5 12a1 1 0 100 2h3v-2H5z" clipRule="evenodd" />
    </svg>
  );
  
  // Department Icon
  const DepartmentIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
      <path d="M2 5a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V5z" />
      <path fillRule="evenodd" d="M4 9a1 1 0 011-1h2v4H5a1 1 0 01-1-1V9zm4 0a1 1 0 011-1h2v4H9a1 1 0 01-1-1V9z" clipRule="evenodd" />
    </svg>
  );
  
  // Location Icon
  const LocationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
      <path fillRule="evenodd" d="M5.05 4.55a7 7 0 019.9 0l.707.707a7 7 0 010 9.9l-5.657 5.657a1 1 0 01-1.414 0L4.343 15.16a7 7 0 010-9.9l.707-.707zM7 10a3 3 0 116 0 3 3 0 01-6 0z" clipRule="evenodd" />
    </svg>
  );
  
  // Job Icon
  const JobIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
      <path fillRule="evenodd" d="M6 3a1 1 0 00-1 1v2a1 1 0 001 1v10a1 1 0 001 1h6a1 1 0 001-1V7a1 1 0 001-1V4a1 1 0 00-1-1H6zm2 4a1 1 0 112 0 1 1 0 01-2 0zm-1 4a1 1 0 100 2h4a1 1 0 100-2H7z" clipRule="evenodd" />
    </svg>
  );

  // Org Icon
    const OrgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path d="M12 12a3 3 0 100-6 3 3 0 000 6zm0 1.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9z" />
      <path fillRule="evenodd" d="M1.5 18a3 3 0 013-3h15a3 3 0 013 3v3a3 3 0 01-3 3h-15a3 3 0 01-3-3v-3zm3-1.5a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5h-15z" clipRule="evenodd" />
    </svg>
  );
  
  // Approvals Icon
  const ApprovalsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
      <path fillRule="evenodd" d="M4.293 1.293A1 1 0 015 1h10a1 1 0 011 1v5a1 1 0 11-2 0V3H6v11h3a1 1 0 110 2H5a1 1 0 01-1-1V2a1 1 0 01.293-.707zM14 11a1 1 0 011-1h1.586l-1.293-1.293a1 1 0 111.414-1.414l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 13H15a1 1 0 01-1-1z" clipRule="evenodd" />
    </svg>
  );
  
  // Departments Icon
  const DepartmentsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path d="M2.25 4.5a.75.75 0 01.75-.75h18a.75.75 0 010 1.5h-18a.75.75 0 01-.75-.75z" />
      <path fillRule="evenodd" d="M3 9a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 9zm.75 6a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z" clipRule="evenodd" />
    </svg>
  );
  
  // Critical Jobs Icon
  const CriticalJobsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6z" />
      <path fillRule="evenodd" d="M6 9a1 1 0 011-1h2v4H7a1 1 0 01-1-1V9zm4 0a1 1 0 011-1h2v4h-2a1 1 0 01-1-1V9z" clipRule="evenodd" />
    </svg>
  );
  
  // Actions Icon
  const ActionsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path d="M1.5 6.75A.75.75 0 013 6h18a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zM3 12a.75.75 0 100 1.5h18a.75.75 0 100-1.5H3zM3 18a.75.75 0 01.75-.75H21a.75.75 0 010 1.5H3.75A.75.75 0 013 18z" />
    </svg>
  );
  
  // Approval Groups Icon
  const ApprovalGroupsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path fillRule="evenodd" d="M3 4.5a1.5 1.5 0 011.5-1.5h15a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5v-15zm1.5 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2zm4 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2zm4 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2zm4 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2zM6 11a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H6zm4 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2zm4 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2zm4 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2z" clipRule="evenodd" />
    </svg>
  );
  
  // Workflows Icon
  const WorkflowsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path d="M9.75 3a3.75 3.75 0 00-3.75 3.75v8.5a3.75 3.75 0 007.5 0v-8.5A3.75 3.75 0 009.75 3z" />
      <path fillRule="evenodd" d="M2.25 9.75A2.25 2.25 0 014.5 7.5h5.25a2.25 2.25 0 012.25 2.25v8.5A2.25 2.25 0 019.75 21H4.5a2.25 2.25 0 01-2.25-2.25v-8.5zM16.5 7.5a2.25 2.25 0 012.25-2.25h5.25A2.25 2.25 0 0124 7.5v8.5a2.25 2.25 0 01-2.25 2.25h-5.25a2.25 2.25 0 01-2.25-2.25v-8.5z" clipRule="evenodd" />
    </svg>
  );
  
  // Users Icon
  const UsersIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
      <path d="M12 12a3 3 0 100-6 3 3 0 000 6zm0 1.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9z" />
      <path fillRule="evenodd" d="M1.5 18a3 3 0 013-3h15a3 3 0 013 3v3a3 3 0 01-3 3h-15a3 3 0 01-3-3v-3zm3-1.5a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5h-15z" clipRule="evenodd" />
    </svg>
  );

  const OnboardingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 7c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4zm6 5H6v-.99C7.2 17.29 9.6 16 12 16s4.8 1.29 6 1.01V19z"/>
    </svg>
  );

  // List of sidebar items
  const sidebarItems = [
    { name: "Dashboard", path: "admin/dashboard", icon: DashboardIcon },
    { name: "Department", path: "admin/dashboard/department", icon: DepartmentIcon },
    { name: "Location", path: "admin/dashboard/location", icon: LocationIcon },
    { name: "Job", path: "admin/dashboard/job", icon: JobIcon },
    { name: "Org", path: "admin/dashboard/org", icon: OrgIcon },
    { name: "Approvals", path: "admin/dashboard/approvals", icon: ApprovalsIcon },
    { name: "Departments", path: "admin/dashboard/departments", icon: DepartmentsIcon },
    { name: "Critical Jobs", path: "admin/dashboard/criticaljobs", icon: CriticalJobsIcon },
    { name: "Actions", path: "admin/dashboard/actions", icon: ActionsIcon },
    { name: "Approval Groups", path: "admin/dashboard/approvalgroups", icon: ApprovalGroupsIcon },
    { name: "Workflows", path: "admin/dashboard/workflows", icon: WorkflowsIcon },
    { name: "Users", path: "admin/dashboard/users", icon: UsersIcon },
    { name: "Onboarding", path: `${ClientID}/${CompanyID}/onboarding`, icon: OnboardingIcon }
  ];

  return (
    <SidebarComponent>
      {sidebarItems.map((item) => (
        <SidebarItem
          key={item.name}
          onClick={() => handleNavigation(item.path)}
          icon= {React.createElement(item.icon)}
          text={item.name}
        />
      ))}
    </SidebarComponent>
  );
};

export default Sidebar;