import React, {createContext, useContext, useEffect, useState} from "react";
import {StatusContext} from "../../contexts/Status";
import AWS from "aws-sdk";
import {getEmployees} from "../../global/api";

const AdminContext = createContext();

const Admin = (props) => {
  const [employees, setEmployees] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [count, setCount] = useState(0);
  const [costOfRisk, setCostOfRisk] = useState(0);
  const [awaitEmployees, setAwaitEmployees] = useState(true);
  const [awaitDepartments, setAwaitDepartments] = useState(true);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);

  const {CompanyID, Loading} = useContext(StatusContext);

  return (
    <AdminContext.Provider
      value={{
        employees,
        setEmployees,
        awaitEmployees,
        count,
        costOfRisk,
        male,
        female,
        selectedEmp,
        setSelectedEmp,
        selectedDept,
        setSelectedDept,
        departments,
        awaitDepartments,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export {Admin, AdminContext};
