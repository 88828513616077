import React from 'react';
import { 
  BanknotesIcon, UserGroupIcon, ChartBarIcon, ArrowUpIcon, ArrowDownIcon 
} from "@heroicons/react/24/outline";

// Icon mapping based on the card type
const iconMapping = {
  "Total Risk": BanknotesIcon,
  "Employees at Risk": UserGroupIcon,
  "Headcount": UserGroupIcon,
  "Total Critical Risk": BanknotesIcon,
  // ... add other mappings as necessary
};

const DataCard = ({ 
  type = "N/A", 
  amount = "N/A", 
  change, 
  lastUpdate 
}) => {
  const Icon = iconMapping[type] || (() => <span>No Icon</span>); // Default icon when no match found
  const ChangeIcon = (change >= 0 && !isNaN(change)) ? ArrowUpIcon : ArrowDownIcon;
  const changeColor = (change >= 0 && !isNaN(change)) ? 'text-green-500' : 'text-red-500';
  const changeText = (isNaN(change) || change === null || change === undefined) ? "N/A" : `${change}%`;
  const formattedDate = lastUpdate ? new Date(lastUpdate).toLocaleDateString() : "N/A";

  // Define Tailwind CSS classes for the card layout to match the design
  const cardClasses = "bg-white shadow-md rounded-lg overflow-hidden w-full md:w-64 lg:w-96 px-2 mb-4 ";
  const cardHeaderClasses = "px-4 py-2 flex justify-between items-center bg-gradient-to-r from-cyan-500 to-blue-500";
  const cardBodyClasses = "p-2";
  const cardFooterClasses = "px-2 py-2 bg-gray-100 text-gray-600 text-sm";

  return (
    <div className={cardClasses}>
      <div className={cardHeaderClasses}>
        <Icon className="h-5 w-5 text-white" />
        <h5 className="text-white font-bold">{type}</h5>
      </div>
      <div className={cardBodyClasses}>
        <h4 className="text-2xl font-bold">{amount}</h4>
        <div className={`flex items-center ${changeColor} mt-2`}>
          <ChangeIcon className="h-5 w-5" />
          <span className="ml-1">{changeText}</span>
        </div>
        <p className="text-gray-500 text-sm mt-2">since {formattedDate}</p>
      </div>
      <div className={cardFooterClasses}>
        Last updated: {formattedDate}
      </div>
    </div>
  );
};

export default DataCard;
