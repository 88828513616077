import React, { useState, useEffect } from 'react';
import {
  createClient,
  deleteClient,
  updateClient,
  getClientComps,
  getClients,
  createClientComp,
  updateClientComp,
  deleteClientComp,
} from '../../global/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../../global/NavbarSuper';
import Footer from '../../global/Footer';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Typography,
} from '@material-tailwind/react';

const formatDate = (date) => {
  const d = new Date(date);
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
};

const SuperClients = () => {
  const [editMode, setEditMode] = useState(false);
  const [awaitUsers, setAwaitUsers] = useState(true);
  const [awaitData, setAwaitData] = useState(true);
  const [clients, setClients] = useState(null);
  const [clientComps, setClientComps] = useState(null);
  const [showModalClient, setShowModalClient] = useState(false);
  const [showModalClientComp, setShowModalClientComp] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [newClient, setNewClient] = useState({
    Client_ID: '',
    Client_Name: '',
    Client_Type: '',
  });

  const fetchData = async () => {
    setAwaitData(true);
    const fetchedClients = await getClients();
    setClients(fetchedClients);
    setAwaitData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitUpdatedClient = async () => {
    await updateClient(newClient); // Assuming updateClient is an API function
    fetchData();
    setShowModalClient(false);
    setEditMode(false);
  };
  

  const openEditClientModal = (client) => {
    setNewClient(client);
    setEditMode(true);
    setShowModalClient(true);
  };

  const addNewClient = async () => {
    await createClient(newClient);
    fetchData();
    setShowModalClient(false);
  };

  const openClientCompModal = async (clientID) => {
    setCurrentClientId(clientID);
    const clientCompanies = await getClientComps(clientID);
    setClientComps(clientCompanies);
    setShowModalClientComp(true);
  };

  const addNewComp = () => {
    const newComp = {
      Client_ID: currentClientId,
      Company_ID: '',
      Company_Name: '',
      Create_Date: new Date(),
      Last_Updated: new Date(),
      isNew: true,
    };
    setClientComps([...clientComps, newComp]);
  };

  const refreshClientComps = async (clientId) => {
    const updatedClientComps = await getClientComps(clientId);
    setClientComps(updatedClientComps);
  };

  const handleUpdateOrCreate = async (comp) => {
    if (comp.isNew) {
      await createClientComp({
        Client_ID: comp.Client_ID,
        Company_ID: comp.Company_ID,
        Company_Name: comp.Company_Name,
        Create_Date: new Date().toISOString(),
        Last_Updated: new Date().toISOString(),
      });
    } else {
      await updateClientComp({
        Client_ID: comp.Client_ID,
        Company_ID: comp.Company_ID,
        Company_Name: comp.Company_Name,
        Last_Updated: new Date().toISOString(),
      });
    }
    refreshClientComps(comp.Client_ID); // Refresh the client companies
  };

  const handleDeleteClientComp = async (clientId, companyId) => {
    await deleteClientComp(clientId, companyId);
    refreshClientComps(clientId); // Refresh the client companies
  };

  const handleDeleteClient = async (clientId) => {
    await deleteClient(clientId);
    fetchData();
  };

  return (
    <div className='container mx-auto p-4'>
      <Navbar />
      <div className='flex justify-between items-center'>
        <Typography variant='h4'>Client Management</Typography>
        <Button color='green' onClick={() => setShowModalClient(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add Client
        </Button>
      </div>

      {/* Add Client Modal */}
      {showModalClient && (
        <Card>
          <CardHeader color="blue">
            <Typography variant='h4'>{editMode ? "Edit Client" : "Add Client"}</Typography>
          </CardHeader>
          <CardBody>
          <Input
            type="text"
            value={newClient.Client_ID}
            placeholder="Client ID"
            onChange={(e) => setNewClient({ ...newClient, Client_ID: e.target.value })}
          />
          <Input
            type="text"
            value={newClient.Client_Name}
            placeholder="Client Name"
            onChange={(e) => setNewClient({ ...newClient, Client_Name: e.target.value })}
          />
          <Input
            type="text"
            value={newClient.Client_Type}
            placeholder="Client Type"
            onChange={(e) => setNewClient({ ...newClient, Client_Type: e.target.value })}
          />
          </CardBody>
          <CardFooter>
          <Button color="green" onClick={editMode ? updateClient : addNewClient}>
              {editMode ? "Update" : "Add"}
            </Button>
            <Button color="red" onClick={() => { setShowModalClient(false); setEditMode(false); }}>
              Close
            </Button>
          </CardFooter>
        </Card>
      )}
      {/* Existing Client Table */}
      <table className='min-w-full bg-white mt-4'>
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Client Name</th>
            <th>Client Type</th>
            <th>Create Date</th>
            <th>Last Updated</th>
            <th>Client Status</th>
            <th>Client Status Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients && clients.map((client) => (
            <tr key={client.Client_ID}>
              <td>{client.Client_ID}</td>
              <td>{client.Client_Name}</td>
              <td>{client.Client_Type}</td>
              <td>{formatDate(client.Create_Date)}</td>
              <td>{formatDate(client.Last_Updated)}</td>
              <td>{client.Client_Status}</td>
              <td>{formatDate(client.Client_Status_Date)}</td>
              <td>
                <div className='flex items-center justify-center'>
                  <FontAwesomeIcon
                    icon={faPencil}
                    onClick={() => openEditClientModal(client)}
                    className='text-blue-500 cursor-pointer mx-2'
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDeleteClient(client.Client_ID)}
                    className='text-red-500 cursor-pointer mx-2'
                  />
                  <FontAwesomeIcon
                    icon={faPlus}
                    onClick={() => openClientCompModal(client.Client_ID)}
                    className='text-green-500 cursor-pointer mx-2'
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* New Client Company Modal */}
      {showModalClientComp && (
        <Card>
          <CardHeader color="blue">
            <Typography variant='h4'>Client Companies</Typography>
          </CardHeader>
          <CardBody>
            <Button color='green' onClick={addNewComp}>
              <FontAwesomeIcon icon={faPlus} /> Add Company
            </Button>
            <table className='min-w-full bg-white mt-4'>
              <thead>
                <tr>
                  <th>Client ID</th>
                  <th>Company ID</th>
                  <th>Company Name</th>
                  <th>Create Date</th>
                  <th>Last Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {clientComps && clientComps.map((comp, index) => (
                  <tr key={index}>
                    <td>{comp.Client_ID}</td>
                    <td>
                      <Input type="text" defaultValue={comp.Company_ID} onChange={(e) => comp.Company_ID = e.target.value} />
                    </td>
                    <td>
                      <Input type="text" defaultValue={comp.Company_Name} onChange={(e) => comp.Company_Name = e.target.value} />
                    </td>
                    <td>{formatDate(comp.Create_Date)}</td>
                    <td>{formatDate(comp.Last_Updated)}</td>
                    <td>
                      <Button color='green' onClick={() => handleUpdateOrCreate(comp)}>Save</Button>
                      <Button color='red' onClick={() => deleteClientComp(comp.Client_ID, comp.Company_ID)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
          <Button color='red' onClick={() => setShowModalClientComp(false)}>Close</Button>
          </CardFooter>
        </Card>
      )}
      <Footer />
    </div>
  );
};

export default SuperClients;
