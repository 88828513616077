import React from 'react';

const SkeletonChart = () => {
  return (
    <div className="animate-pulse">
      <div className="rounded-md bg-gray-300 h-40 w-full mb-4"></div>
    </div>
  );
};

export default SkeletonChart;
