import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Sidebar from "../../Sidebar";
import StatisticsChart from "../Dashboard/DataChart";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Collapse,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Input,
  Option,
  Tab,
  Tabs,
  TabsHeader,
  Tooltip,
  Typography,
  Select,
  Checkbox,
} from "@material-tailwind/react";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import Chart from "react-apexcharts";
import {
  BanknotesIcon,
  ChartBarIcon,
  ClockIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import lineChartsConfig from "./line-charts-config";
import chartsConfig from "./charts-config";
import {
  getActions,
  getCompletedActions,
  getEmployees,
  initiateAction,
} from "../../../../global/api";
import {StatusContext} from "../../../../contexts/Status";
import Spinner from "../../../../global/Spinner";
import Breadcrumb from "../Breadcrumbs";
import {AccountContext} from "../../../../contexts/Account";

const EmployeeDetails = () => {
  const {Employee_ID} = useParams();
  const {clientID, companyID} = useParams();
  const decodedClientID = window.atob(clientID);
  const decodedCompanyID = window.atob(companyID);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [email, setEmail] = useState(null);
  const {getSession} = useContext(AccountContext);

  const fetchEmployees = async () => {
    const emps = await getEmployees(decodedCompanyID);
    console.log(emps);
    setCurrentEmployee(
      emps.find((employee) => employee.Employee_ID === Employee_ID)
    );
    console.log("current", currentEmployee);
  };
  const fetchActions = async () => {
    const actions = await getActions(decodedCompanyID);
    console.log(actions);

    setActions(actions);
  };

  const fetchCompletedActions = async () => {
    const actions = await getCompletedActions(decodedCompanyID, Employee_ID);
    console.log("Completed Actions", actions);
    getSession()
      .then((session) => {
        console.log("Session: ", session);

        setEmail(session.idToken.payload.email);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchEmployees();
    fetchActions();
    fetchCompletedActions();
  }, []);

  const [actions, setActions] = useState(null);

  const websiteViewsChart = {
    type: "bar",
    height: 220,
    series: [
      {
        name: "High Risk",
        data: [50, 20, 10, 22, 50, 10, 40],
      },
      {
        name: "Med Risk",
        data: [50, 20, 10, 22, 50, 10, 40],
      },
      {
        name: "Low Risk",
        data: [50, 20, 10, 22, 50, 10, 40],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    options: {
      ...chartsConfig,
      colors: ["#B81D13", "#EFB700", "#008450"],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      plotOptions: {
        bar: {
          horizontal: true, // Set horizontal to true for a horizontal stacked bar chart
          barHeight: "80%", // Adjust barHeight for better spacing between bars
          borderRadius: 5,
        },
      },

      xaxis: {
        ...chartsConfig.yaxis, // Use the yaxis config for the horizontal X-axis
        categories: [
          "Enrichetta Baddam",
          "Bar",
          "Merc",
          "Lewis",
          "Lando",
          "Charles",
          "Ford",
        ],
      },
    },
  };
  const statisticsChartsData = [
    {
      color: "white",
      title: "Stackup by Manager",
      description: "Manager levels",
      footer: "updated today",
      chart: websiteViewsChart,
    },
  ];

  const employee_data_chart = {
    type: "line",
    height: 220,
    series: [
      {
        name: "Employee Comp.",
        data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
        color: "#FFFFFF",
      },
      {
        name: "Avarage Comp.",
        data: [50, 60, 70, 100, 150, 300, 350, 350, 400],
        color: "#000000",
      },
    ],
    options: {
      ...lineChartsConfig,
      colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...lineChartsConfig.xaxis,
        categories: [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
  };

  const employee_more_data_chart = {
    type: "line",
    height: 220,
    series: [
      {
        name: "Avarage Comp.",
        data: [50, 60, 70, 100, 150, 300, 350, 350, 400],
        color: "#FFFFFF",
      },
    ],
    options: {
      ...lineChartsConfig,
      colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...lineChartsConfig.xaxis,
        categories: [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
  };

  const employee_data = currentEmployee
    ? [
        {
          color:
            currentEmployee.Flight_Risk_Score > 0.8
              ? "red"
              : currentEmployee.Flight_Risk_Score > 0.5
              ? "yellow"
              : "blue-gray",
          title: "Total vs Average Compensation",
          // description: "Flight Risk Score",
          footer: "just updated",
          chart: employee_data_chart,
        },
      ]
    : null;

  const employee_data_more_data = currentEmployee
    ? [
        {
          color: "blue-gray",
          title: currentEmployee.Annual_Salary,
          description: "Current Base Pay",
          // footer: "just updated",
          chart: employee_more_data_chart,
        },
        {
          color: "blue-gray",
          title: "$16,280",
          description: "Current Performance Incentive",
          // footer: "just updated",
          chart: employee_more_data_chart,
        },
        {
          color: "blue-gray",
          title: ".78",
          description: "Current Comp. Ratio",
          // footer: "just updated",
          chart: employee_more_data_chart,
        },
      ]
    : null;

  const TripleStatitics = currentEmployee
    ? [
        {
          color: "blue-gray",
          icon: BanknotesIcon,
          title: "Bonus Eligible",
          value: "12%",
          footer: {
            color: "text-green-500",
            value: "+55%",
            label: "than last week",
          },
        },
        {
          color: "pink",
          icon: UserIcon,
          title: "Date of Joining",
          value: currentEmployee.Date_of_Hire.slice(0, 10),
          footer: {
            color: "text-green-500",
            value: "+3%",
            label: "than last month",
          },
        },
        {
          color: "green",
          icon: UserPlusIcon,
          title: "Annual PTO",
          value: "15 Days",
          footer: {
            color: "text-red-500",
            value: "-2%",
            label: "than yesterday",
          },
        },
        {
          color: "orange",
          icon: ChartBarIcon,
          title: "Last Salary Raise",
          value: "16 Jan, 2020",
          footer: {
            color: "text-green-500",
            value: "+5%",
            label: "than yesterday",
          },
        },
      ]
    : null;

  const projectsTableData = [
    {
      id: 1,
      img: "/img/logo-xd.svg",
      name: "Day Off",
      employee_name: "John Doe",
      members: [
        {
          img: "/img/team-1.jpeg",
          name: "Romina Hadid",
          role: "CEO",
          approved: true,
          comment:
            "Lorem ipsum dolor sit amet. Sit quibusdam suscipit qui velit minima et delectus totam ea voluptatem architecto aut voluptatibus reprehenderit et accusantium veniam. Id ipsum rerum ut voluptas animi et quidem sunt et officia voluptatem id voluptatem cupiditate qui quisquam debitis.",
        },
        {
          img: "/img/team-2.jpeg",
          name: "Ryan Tompson",
          role: "Manager",
          approved: true,
          comment:
            "Lorem ipsum dolor sit amet. Sit quibusdam suscipit qui velit minima et delectus totam ea voluptatem architecto aut voluptatibus reprehenderit et accusantium veniam. Id ipsum rerum ut voluptas animi et quidem sunt et officia voluptatem id voluptatem cupiditate qui quisquam debitis.",
        },
        {
          img: "/img/team-3.jpeg",
          name: "Jessica Doe",
          role: "Supervisor",
          approved: false,
          comment:
            "Lorem ipsum dolor sit amet. Sit quibusdam suscipit qui velit minima et delectus totam ea voluptatem architecto aut voluptatibus reprehenderit et accusantium veniam. Id ipsum rerum ut voluptas animi et quidem sunt et officia voluptatem id voluptatem cupiditate qui quisquam debitis.",
        },
        {
          img: "/img/team-4.jpeg",
          name: "Alexander Smith",
          role: "CEO",
          approved: null,
          comment: null,
        },
      ],
      cost: "HIGH",
      completion: 60,
      completion_num: "6/10",
    },
    {
      id: 2,
      img: "/img/logo-atlassian.svg",
      name: "Call from CEO",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-2.jpeg", name: "Ryan Tompson"},
        {img: "/img/team-4.jpeg", name: "Alexander Smith"},
      ],
      cost: "MED",
      completion: 10,
      completion_num: "1/10",
    },
    {
      id: 3,
      img: "/img/logo-slack.svg",
      name: "Pay Raise",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-3.jpeg", name: "Jessica Doe"},
        {img: "/img/team-1.jpeg", name: "Romina Hadid"},
      ],
      cost: "Free",
      completion: 100,
      completion_num: "10/10",
    },
    {
      id: 4,
      img: "/img/logo-spotify.svg",
      name: "Half Day",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-4.jpeg", name: "Alexander Smith"},
        {img: "/img/team-3.jpeg", name: "Jessica Doe"},
        {img: "/img/team-2.jpeg", name: "Ryan Tompson"},
        {img: "/img/team-1.jpeg", name: "Romina Hadid"},
      ],
      cost: "Free",
      completion: 100,
      completion_num: "10/10",
    },
    {
      id: 5,
      img: "/img/logo-jira.svg",
      name: "Day Off",
      employee_name: "John Doe",
      members: [{img: "/img/team-4.jpeg", name: "Alexander Smith"}],
      cost: "LOW",
      completion: 20,
      completion_num: "2/10",
    },
    {
      id: 6,
      img: "/img/logo-invision.svg",
      name: "Promotion",
      employee_name: "John Doe",
      members: [
        {img: "/img/team-1.jpeg", name: "Romina Hadid"},
        {img: "/img/team-4.jpeg", name: "Alexander Smith"},
      ],
      cost: "MED",
      completion: 40,
      completion_num: "4/10",
    },
  ];

  const [openAction, setOpenAction] = React.useState(false);
  const handleOpenAction = () => setOpenAction(!openAction);

  const [selectedAction, setSelectedAction] = useState(null);
  const [regret, setRegret] = useState(true);

  const initAction = async () => {
    await initiateAction(
      // console.log({
      {
        Company_Id: decodedCompanyID,
        Employee_Id: Employee_ID,
        Work_Location_Id: currentEmployee.Work_Location_ID,
        Department_Id: currentEmployee.Department_ID,
        Job_Id: currentEmployee.Job_ID,
        Action_Id: selectedAction.Action_ID,
        Action_Init_Date: new Date(),
        user_id: email,
        regret: regret,
      }
    );
  };

  return currentEmployee === null ? (
    <Spinner />
  ) : (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
        <Breadcrumb
          link={"/admin/Dashboard/org/"}
          linkCrumb={"/Org/Employee Details"}
          title={"Employee Details"}
        ></Breadcrumb>
        <div className="h-6"></div>
        <div className="w-4/5">
          <div className="my-3">
            <Typography variant="h1">
              {currentEmployee.First_Name + " " + currentEmployee.Last_Name}
            </Typography>
          </div>
          <div className="mb-10">
            <Typography variant="paragraph">
              This Employee is under{" "}
              {currentEmployee.Flight_Risk_Score > 0.8
                ? "High"
                : currentEmployee.Flight_Risk_Score > 0.5
                ? "Medium"
                : "Low"}{" "}
              Risk. Would you like to take an action?
            </Typography>
            <Button
              onClick={() => {
                handleOpenAction();
              }}
            >
              Take an Action
            </Button>
            <Dialog open={openAction} handler={handleOpenAction}>
              <DialogHeader>Select an Action to Take</DialogHeader>
              <DialogBody divider>
                This Employee is under{" "}
                {currentEmployee.Flight_Risk_Score > 0.8
                  ? "High"
                  : currentEmployee.Flight_Risk_Score > 0.5
                  ? "Medium"
                  : "Low"}{" "}
                Risk. Please take an action.
                {actions === null ? (
                  <div></div>
                ) : (
                  <div className="w-72">
                    <Select label="Select Action">
                      {actions.map((action) => (
                        <Option
                          onClick={() => {
                            setSelectedAction(action);
                            console.log(action);
                          }}
                        >
                          {action.Action_Name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                <Checkbox
                  value={regret}
                  onChange={() => {
                    setRegret(!regret);
                  }}
                />
              </DialogBody>
              <DialogFooter>
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpenAction}
                  className="mr-1"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  variant="gradient"
                  color="green"
                  onClick={() => {
                    handleOpenAction();
                    // console.log({
                    initAction();
                  }}
                >
                  <span>Take Action</span>
                </Button>
              </DialogFooter>
            </Dialog>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col">
              {employee_data.map((props) => (
                <StatisticsChart
                  key={props.title}
                  {...props}
                  footer={
                    <Typography
                      variant="small"
                      className="flex items-center font-normal text-blue-gray-600"
                    >
                      <ClockIcon
                        strokeWidth={2}
                        className="h-4 w-4 text-inherit"
                      />
                      &nbsp;{props.footer}
                    </Typography>
                  }
                />
              ))}

              <div className="my-10 flex flex-row justify-between">
                {employee_data_more_data.map((props) => (
                  <TripleStatisticsChart
                    className="mx-5"
                    key={props.title}
                    {...props}
                    footer={
                      <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                      >
                        <ClockIcon
                          strokeWidth={2}
                          className="h-4 w-4 text-inherit"
                        />
                        &nbsp;{props.footer}
                      </Typography>
                    }
                  />
                ))}
                <Card>
                  <CardHeader variant="gradient" color="">
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          {["Action", "cost"].map((el) => (
                            <th
                              key={el}
                              className="border-b border-blue-gray-50 py-3 px-6 text-left"
                            >
                              <Typography
                                variant="small"
                                className="text-[11px] font-medium uppercase text-blue-gray-400"
                              >
                                {el}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {projectsTableData.map((action) => {
                          const className = `py-3 px-5 ${
                            action.key === projectsTableData.length - 1
                              ? ""
                              : "border-b border-blue-gray-50"
                          }`;

                          return (
                            <tr key={action.id}>
                              <td className={className}>
                                <div className="flex items-center gap-4">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold"
                                  >
                                    {action.name}
                                  </Typography>
                                </div>
                              </td>
                              <td>
                                <div className="w-max">
                                  <Chip
                                    variant="ghost"
                                    size="sm"
                                    value={action.cost}
                                    color={
                                      action.cost === "HIGH"
                                        ? "red"
                                        : action.cost === "MED"
                                        ? "yellow"
                                        : action.cost === "LOW"
                                        ? "green"
                                        : "purple"
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </CardHeader>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    <Typography variant="h6" className="p-4">
                      Previous Actions Taken
                    </Typography>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="flex flex-row space-x-2">
              {TripleStatitics.map(({icon, title, footer, ...rest}) => (
                <div className="w-1/2 h-auto">
                  <QuadStatisticsCard
                    key={title}
                    {...rest}
                    title={title}
                    icon={React.createElement(icon, {
                      className: "w-6 h-6 text-white",
                    })}
                    footer={
                      <Typography className="font-normal text-blue-gray-600">
                        <strong className={footer.color}>{footer.value}</strong>
                        &nbsp;{footer.label}
                      </Typography>
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;

function TripleStatisticsChart({color, chart, title, description, footer}) {
  return (
    <Card>
      <CardHeader variant="gradient" color={color}>
        <Chart {...chart} />
      </CardHeader>
      <CardBody className="p-6">
        <Typography variant="h6" color="blue-gray">
          {title}
        </Typography>
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {description}
        </Typography>
      </CardBody>
    </Card>
  );
}

function QuadStatisticsCard({color, icon, title, value, footer}) {
  return (
    <Card>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        <Typography variant="h4" color="blue-gray">
          {value}
        </Typography>
      </CardBody>
    </Card>
  );
}
