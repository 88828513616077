import Card from "./Components/Card";
import TripleCard from "./Components/TripleCard";
import Navbar from "../../global/NavbarManager";
import LineChart from "./Components/LineChart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faMale,
  faCircleUp,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {Line} from "react-chartjs-2";
import {AdminContext} from "../admin/Admin";
import {useContext} from "react";
import {useState} from "react";
import {useEffect} from "react";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import {ManagerContext} from "./Manager";

const DashboardManager = () => {
  const {employees, awaitEmployees} = useContext(ManagerContext);

  const [count, setCount] = useState(0);
  const [costOfRisk, setCostOfRisk] = useState(0);

  useEffect(() => {
    function calcEmployeesAtRisk() {
      let count = 0;
      for (let item of employees) {
        if (item.Flight_Risk_Score >= 0.75) {
          count += 1;
        }
      }
      // console.log("cont", count);
      setCount(count);
    }

    const calcRiskCost = () => {
      let count = 0;
      for (let emp of employees) {
        let cost = 0;
        if (emp.Flight_Risk_Score >= 0.8) {
          cost = parseFloat(emp.Annual_Salary.replace(/[$,]/g, "")) * 1.5;
        } else if (emp.Flight_Risk_Score >= 0.5) {
          cost = parseFloat(emp.Annual_Salary.replace(/[$,]/g, "")) * 0.75;
        }
        count += cost;
      }
      setCostOfRisk(count);
    };
    if (employees !== null) {
      calcEmployeesAtRisk();
      calcRiskCost();
    }
  }, [employees, awaitEmployees]);

  return (
    <>
      <Navbar name="DASHBOARD"></Navbar>
      <div className="bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga">
        <div className="h-6"></div>
        <div className="w-4/5 flex flex-row space-x-4 mx-auto">
          <div className="w-4/5 h-52 bg-white mx-auto flex justify-center items-center rounded-2xl">
            <div className="w/2 text-center">
              <div className="flex flex-row items-center space-x-4">
                <div className="font-bold text-6xl text-center ">
                  ${costOfRisk}
                </div>
                <FontAwesomeIcon
                  icon={faCircleUp}
                  className="text-6xl text-red-600"
                />
              </div>
              <p className="text-gray-700 text-4xl text-center">
                Total Cost Of Flight Risk
              </p>
            </div>
          </div>
          <div className="w-4/5 h-52 bg-white mx-auto flex justify-center items-center rounded-2xl">
            <div className="w/2 text-center">
              <div className="font-bold text-6xl text-center">{count}</div>
              <p className="text-gray-700 text-4xl text-center">
                Employees At Flight Risk
              </p>
            </div>
          </div>
        </div>

        <div className=" px-5 py-20 flex flex-col space-y-5 w-4/5 h-2/3 items-center justify-center mx-auto">
          <div className="h-full w-full bg-white rounded-md">
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: false,
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    stacked: true,
                    ticks: {
                      stepSize: 2,
                      callback: (value) => value + "K",
                    },
                    grid: {},
                  },
                },
              }}
              data={{
                labels: ["Jan", "Feb", "March", "Apr", "May", "Jun", "Jul"],
                datasets: [
                  {
                    label: "High",
                    data: [8, 7.8, 6, 8, 7, 9, 9.9],
                    fill: true,
                    backgroundColor: "#FF9696",
                    borderColor: "#FF9696",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                  {
                    label: "Med",
                    data: [9, 8.8, 7, 9, 8, 10, 10],
                    fill: true,
                    backgroundColor: "#F8D57E",
                    borderColor: "#F8D57E",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                  {
                    label: "Low",
                    data: [7, 6.8, 6, 6, 8, 8, 9.9],
                    fill: true,
                    backgroundColor: "#80E6D1",
                    borderColor: "#80E6D1",
                    pointBorderColor: "transparent",
                    pointBorderWidth: 4,
                    tension: 0.4,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className=" px-5 pt-20 flex flex-col space-y-5 w-4/5 min-h-fit items-center justify-center mx-auto">
          {employees === null ? (
            <Spinner />
          ) : (
            <div className="flex grid-cols-3 gap-7 w-full h-full">
              <TripleCard
                first={employees[0].First_Name}
                last={employees[0].Last_Name}
                department={employees[0].Department_ID}
                flightrisk={employees[0].Flight_Risk_Score * 100}
                flightriskscore={"$" + employees[0].Annual_Salary}
              />
              <TripleCard
                first={employees[1].First_Name}
                last={employees[1].Last_Name}
                department={employees[1].Department_ID}
                flightrisk={employees[1].Flight_Risk_Score * 100}
                flightriskscore={"$" + employees[1].Annual_Salary}
              />
              <TripleCard
                first={employees[2].First_Name}
                last={employees[2].Last_Name}
                department={employees[2].Department_ID}
                flightrisk={employees[2].Flight_Risk_Score * 100}
                flightriskscore={"$" + employees[2].Annual_Salary}
              />
            </div>
          )}
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default DashboardManager;
