import React, { useContext, useEffect, useState } from "react";
import { BanknotesIcon, ClockIcon } from "@heroicons/react/24/solid";
import { StatusContext } from "../../contexts/Status";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import { getJobDetails, getJobs } from "../../global/api";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Button,
} from "@material-tailwind/react";
import Sidebar from "../../components/common/sidebar_new";
import TopNavigation from "../../components/common/TopNavigation";
import StatisticsChart from "../../components/dashboard/DataChart";
import chartsConfig from "../../components/dashboard/charts-config";
import { CompanyDataProvider } from '../../contexts/CompanyContext';
import { CompanyChartDataProvider } from "../../contexts/CompanyChartDataContext";
import { EmployeeProvider } from "../../contexts/EmployeeContext";
import EmployeeGrid from "../../components/dashboard/EmployeeGrid";

const DashboardJob = () => {
  const { CompanyID } = useContext(StatusContext);
  const [costOfRisk, setCostOfRisk] = useState(0);
  const [jobs, setJobs] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [jobBars, setJobBars] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    if (CompanyID) {
      fetchJobs();
    }
  }, [CompanyID]);

  const fetchJobs = async () => {
    try {
      const jobDetails = await getJobDetails(CompanyID);
      setJobDetails(jobDetails);
      const jobs = await getJobs(CompanyID);
      setJobs(jobs);
      processJobData(jobs);
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

  const processJobData = (jobs) => {
    const processedJobs = jobs.map(job => ({
      ...job,
      redPercentage: (job.count_red / job.count_total) * 100,
      yellowPercentage: (job.count_yellow / job.count_total) * 100,
      greenPercentage: 100 - ((job.count_red / job.count_total) * 100) - ((job.count_yellow / job.count_total) * 100)
    }));

    processedJobs.sort((a, b) => b.redPercentage - a.redPercentage);
    setJobBars(processedJobs);

    const totalRisk = processedJobs.reduce((sum, job) => sum + parseFloat(job.total_risk.replace(/[$,]/g, "")), 0);
    setCostOfRisk(totalRisk);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return jobBars.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const jobsChart = jobBars ? {
    type: "bar",
    height: 220,
    series: [
      {
        name: "High Risk",
        data: getCurrentPageData().map((job) => job.redPercentage),
      },
      {
        name: "Med Risk",
        data: getCurrentPageData().map((job) => job.yellowPercentage),
      },
      {
        name: "Low Risk",
        data: getCurrentPageData().map((job) => job.greenPercentage),
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#B81D13", "#EFB700", "#008450"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
          borderRadius: 5,
          stacked: true,
          stackType: "100%",
        },
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: getCurrentPageData().map((job) => job.Job_Title),
      },
    },
  } : null;

  const jobChartsData = [
    {
      color: "white",
      title: "Job Risk Distribution",
      description: "Risk levels across different job titles",
      footer: "Updated today",
      chart: jobsChart,
    },
  ];

  const statisticsCardsData = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Total Risk",
      value: `$${costOfRisk.toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
      footer: {
        color: "text-green-500",
        value: "+55%",
        label: "than last week",
      },
    },
  ];

  if (!jobs) return <Spinner />;

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col bg-[#F2F2F2] min-h-screen w-full font-fabriga">
        <TopNavigation />
        <div className="p-4">
          <Typography variant="h4" color="blue-gray" className="mb-4">
            Jobs Dashboard
          </Typography>
          <div className="mb-6">
            {jobChartsData.map((props) => (
              <StatisticsChart
                key={props.title}
                {...props}
                footer={
                  <Typography variant="small" className="flex items-center font-normal text-blue-gray-600">
                    <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                    &nbsp;{props.footer}
                  </Typography>
                }
                pagination={
                  <div className="flex gap-2 p-2">
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === Math.ceil(jobBars.length / ITEMS_PER_PAGE)}
                    >
                      Next
                    </Button>
                  </div>
                }
              />
            ))}
          </div>
          <div className="mb-6">
            {statisticsCardsData.map(({ icon, title, footer, ...rest }) => (
              <StatisticsCard
                key={title}
                {...rest}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-6 h-6 text-white",
                })}
                footer={
                  <Typography className="font-normal text-blue-gray-600">
                    <strong className={footer.color}>{footer.value}</strong>
                    &nbsp;{footer.label}
                  </Typography>
                }
              />
            ))}
          </div>
          <EmployeeProvider companyId={CompanyID}>
            <CompanyDataProvider companyId={CompanyID}>
              <CompanyChartDataProvider>
                <EmployeeGrid />
              </CompanyChartDataProvider>
            </CompanyDataProvider>
          </EmployeeProvider>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const StatisticsCard = ({ color, icon, title, value, footer }) => {
  return (
    <Card>
      <CardHeader
        variant="gradient"
        color={color}
        className="absolute -mt-4 grid h-16 w-16 place-items-center"
      >
        {icon}
      </CardHeader>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        <Typography variant="h4" color="blue-gray">
          {value}
        </Typography>
      </CardBody>
      {footer && (
        <CardFooter className="border-t border-blue-gray-50 p-4">
          {footer}
        </CardFooter>
      )}
    </Card>
  );
};

export default DashboardJob;