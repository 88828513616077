import React, {createContext, useContext, useEffect, useState} from "react";
import {getEmployees, getOrgs} from "../../global/api";
import {StatusContext} from "../../contexts/Status";

const ManagerContext = createContext();

const Manager = (props) => {
  const [employees, setEmployees] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [count, setCount] = useState(0);
  const [costOfRisk, setCostOfRisk] = useState(0);
  const [awaitEmployees, setAwaitEmployees] = useState(true);
  const [awaitDepartments, setAwaitDepartments] = useState(true);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);

  const {CompanyID, Email} = useContext(StatusContext);

  const fetchEmployees = async () => {
    setAwaitEmployees(true);

    const org = await getOrgs(CompanyID, Email);
    console.log(org);
    const emps = await getEmployees(CompanyID, org[0].Dept_ID);
    console.log(emps);
    setEmployees(emps);

    setDepartments([1, 2, 3, 4, 5]);

    setAwaitEmployees(false);
  };

  useEffect(() => {
    if (CompanyID) {
      fetchEmployees();
    }
  }, [CompanyID]);

  return (
    <ManagerContext.Provider
      value={{
        employees,
        setEmployees,
        awaitEmployees,
        count,
        costOfRisk,
        male,
        female,
        selectedEmp,
        setSelectedEmp,
        selectedDept,
        setSelectedDept,
        departments,
        awaitDepartments,
      }}
    >
      {props.children}
    </ManagerContext.Provider>
  );
};

export {Manager, ManagerContext};
