import React, {useContext, useEffect, useState} from "react";
import Navbar from "../../../global/NavbarSuper";
import {
  createEmailTemplate,
  deleteEmailTemplate,
  getClientComps,
  getClients,
  getEmailTemplates,
} from "../../../global/api";
import {StatusContext} from "../../../contexts/Status";
import Spinner from "../../../global/Spinner";
import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";

const EmailConfig = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [templates, setTemplates] = useState(null);
  const [currentEdit, setCurrentEdit] = useState(null);

  const fetchEmailTemplates = async () => {
    const fetchedTemplates = await getEmailTemplates();
    console.log("templates", fetchedTemplates);
    setTemplates(fetchedTemplates);
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  const handleDelete = async (templateId) => {
    await deleteEmailTemplate(templateId);
    window.location.reload(false);
  };

  return (
    <>
      <Navbar name="EMAIL" />

      {templates === null ? (
        <Spinner />
      ) : (
        <div className="bg-[#F2F2F2] flex flex-col min-h-screen items-center font-fabriga mx-auto">
          <div className="font-fabriga font-bold text-6xl mt-10">
            Email Config
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Email Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email Subject
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {templates.map((template) => {
                  return (
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td className="px-6 py-4">{template.Template_Name}</td>
                      <td className="px-6 py-4">{template.Subject}</td>
                      <td className="px-6 py-4">
                        <a
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                          onClick={() => {
                            setCurrentEdit(template.Template_ID);
                            setShowModalEdit(true);
                          }}
                        >
                          Edit
                        </a>
                      </td>
                      <td className="px-6 py-4">
                        <a
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                          onClick={() => {
                            handleDelete(template.Template_ID);
                          }}
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <button
            className="fixed bottom-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Add New Email Template
          </button>
        </div>
      )}
      {showModal ? <ModalAddNew setShowModal={setShowModal} /> : null}
      {showModalEdit ? (
        <ModalEdit
          setShowModalEdit={setShowModalEdit}
          Template_ID={currentEdit}
        />
      ) : null}
    </>
  );
};

const ModalAddNew = (props) => {
  const [emailName, setEmailName] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailBody, setEmailBody] = useState("");
  const [awaitData, setAwaitData] = useState(true);
  const [clients, setClients] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const saveEmail = async () => {
    const fetchedClientComp = await getClientComps(selectedClient.Client_ID);

    await createEmailTemplate({
      Company_ID: fetchedClientComp[0].Company_ID,
      Template_Name: emailName,
      Template_Subject: emailSubject,
      Template_Body: emailBody,
    });
    window.location.reload(false);
  };

  const fetchData = async () => {
    setAwaitData(true);
    const Clients = await getClients();
    console.log(Clients);
    setClients(Clients);
    setAwaitData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="font-fabriga justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-5/6">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add New Action</h3>
            </div>
            {/*content*/}
            <div className="grid grid-cols-2 w-full space-x-10 mx-20">
              <div className="flex flex-col">
                <form className="w-full ">
                  <div class="grid gap-6 my-6 md:grid-cols-3">
                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Name"
                        required
                        value={emailName}
                        onChange={(e) => setEmailName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Subject"
                        required
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Company
                      </label>
                      <Menu as="div" className="w-full text-left">
                        <div>
                          <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            {selectedClient === null
                              ? "Select a Company"
                              : selectedClient.Client_Name}
                            <ChevronDownIcon
                              className="-mr-1 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          {clients === null ? null : (
                            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {clients.map((client) => (
                                  <Menu.Item>
                                    {({active}) => (
                                      <a
                                        href="#"
                                        onClick={() =>
                                          setSelectedClient(client)
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        {client.Client_Name}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          )}
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                    <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                      <label for="comment" class="sr-only">
                        Body
                      </label>
                      <textarea
                        id="emailBody"
                        rows="4"
                        class="w-full max-h-fit px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        placeholder="Write a body..."
                        required
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex flex-col space-y-4">
                <div>{emailName}</div>
                <div>{emailSubject}</div>
                <div
                  className="email-body"
                  dangerouslySetInnerHTML={{__html: emailBody}}
                />
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  saveEmail();
                  props.setShowModal(false);
                }}
              >
                Save Template
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ModalEdit = (props) => {
  const [emailName, setEmailName] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailBody, setEmailBody] = useState("");

  const {CompanyID} = useContext(StatusContext);

  const fetchTemplate = async () => {
    const template = await getEmailTemplates(props.Template_ID);
    setEmailName(template[0].Name);
    setEmailSubject(template[0].Subject);
    setEmailBody(template[0].Body);
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const saveEmail = () => {
    createEmailTemplate({
      Template_Name: emailName,
      Template_Subject: emailSubject,
      Template_Body: emailBody,
    });
  };

  return (
    <>
      <div className="font-fabriga justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-5/6">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add New Action</h3>
            </div>
            {/*content*/}
            <div className="grid grid-cols-2 w-full space-x-10 mx-20">
              <div className="flex flex-col">
                <form className="w-full ">
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Name"
                        required
                        value={emailName}
                        onChange={(e) => setEmailName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Subject"
                        required
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                      />
                    </div>
                  </div>

                  <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                    <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                      <label for="comment" class="sr-only">
                        Body
                      </label>
                      <textarea
                        id="emailBody"
                        rows="4"
                        class="w-full max-h-fit px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        placeholder="Write a body..."
                        required
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex flex-col space-y-4">
                <div>{emailName}</div>
                <div>{emailSubject}</div>
                <div
                  className="email-body"
                  dangerouslySetInnerHTML={{__html: emailBody}}
                />
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.setShowModalEdit(false)}
              >
                Cancel
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  // saveEmail();
                  props.setShowModalEdit(false);
                }}
              >
                Save Template
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default EmailConfig;
