import React, { useContext, useEffect, useState } from "react";
import { BanknotesIcon, ClockIcon } from "@heroicons/react/24/solid";
import { StatusContext } from "../../contexts/Status";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import { getDepartment, getDeptDetails } from "../../global/api";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Button,
} from "@material-tailwind/react";
import Sidebar from "../../components/common/sidebar_new";
import TopNavigation from "../../components/common/TopNavigation";
import StatisticsChart from "../../components/dashboard/DataChart";
import chartsConfig from "../../components/dashboard/charts-config";
import { CompanyDataProvider } from '../../contexts/CompanyContext';
import { CompanyChartDataProvider } from "../../contexts/CompanyChartDataContext";
import { EmployeeProvider } from "../../contexts/EmployeeContext";
import EmployeeGrid from "../../components/dashboard/EmployeeGrid";

const DashboardDepartment = () => {
  const { CompanyID } = useContext(StatusContext);
  const [costOfRisk, setCostOfRisk] = useState(0);
  const [departments, setDepartments] = useState(null);
  const [departmentDetails, setDepartmentDetails] = useState(null);
  const [departmentBars, setDepartmentBars] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    if (CompanyID) {
      fetchDepartments();
    }
  }, [CompanyID]);

  const fetchDepartments = async () => {
    try {
      const deptDetails = await getDeptDetails(CompanyID);
      setDepartmentDetails(deptDetails);
      const depts = await getDepartment(CompanyID);
      setDepartments(depts);
      processDepartmentData(depts);
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };

  const processDepartmentData = (departments) => {
    const processedDepartments = departments.map(department => ({
      ...department,
      redPercentage: (department.count_red / department.count_total) * 100,
      yellowPercentage: (department.count_yellow / department.count_total) * 100,
      greenPercentage: 100 - ((department.count_red / department.count_total) * 100) - ((department.count_yellow / department.count_total) * 100)
    }));

    processedDepartments.sort((a, b) => b.redPercentage - a.redPercentage);
    setDepartmentBars(processedDepartments);

    const totalRisk = processedDepartments.reduce((sum, department) => sum + parseFloat(department.total_risk.replace(/[$,]/g, "")), 0);
    setCostOfRisk(totalRisk);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return departmentBars.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const departmentsChart = departmentBars ? {
    type: "bar",
    height: 220,
    series: [
      {
        name: "High Risk",
        data: getCurrentPageData().map((department) => department.redPercentage),
      },
      {
        name: "Med Risk",
        data: getCurrentPageData().map((department) => department.yellowPercentage),
      },
      {
        name: "Low Risk",
        data: getCurrentPageData().map((department) => department.greenPercentage),
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#B81D13", "#EFB700", "#008450"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
          borderRadius: 5,
          stacked: true,
          stackType: "100%",
        },
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: getCurrentPageData().map((department) => department.Department_Name),
      },
    },
  } : null;

  const departmentChartsData = [
    {
      color: "white",
      title: "Department Risk Distribution",
      description: "Risk levels across different departments",
      footer: "Updated today",
      chart: departmentsChart,
    },
  ];

  const statisticsCardsData = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Total Risk",
      value: `$${costOfRisk.toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
      footer: {
        color: "text-green-500",
        value: "+55%",
        label: "than last week",
      },
    },
  ];

  if (!departments) return <Spinner />;

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col bg-[#F2F2F2] min-h-screen w-full font-fabriga">
        <TopNavigation />
        <div className="p-4">
          <Typography variant="h4" color="blue-gray" className="mb-4">
            Departments Dashboard
          </Typography>
          <div className="mb-6">
            {departmentChartsData.map((props) => (
              <StatisticsChart
                key={props.title}
                {...props}
                footer={
                  <Typography variant="small" className="flex items-center font-normal text-blue-gray-600">
                    <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                    &nbsp;{props.footer}
                  </Typography>
                }
                pagination={
                  <div className="flex gap-2 p-2">
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === Math.ceil(departmentBars.length / ITEMS_PER_PAGE)}
                    >
                      Next
                    </Button>
                  </div>
                }
              />
            ))}
          </div>
          <div className="mb-6">
            {statisticsCardsData.map(({ icon, title, footer, ...rest }) => (
              <StatisticsCard
                key={title}
                {...rest}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-6 h-6 text-white",
                })}
                footer={
                  <Typography className="font-normal text-blue-gray-600">
                    <strong className={footer.color}>{footer.value}</strong>
                    &nbsp;{footer.label}
                  </Typography>
                }
              />
            ))}
          </div>
          <EmployeeProvider companyId={CompanyID}>
            <CompanyDataProvider companyId={CompanyID}>
              <CompanyChartDataProvider>
                <EmployeeGrid />
              </CompanyChartDataProvider>
            </CompanyDataProvider>
          </EmployeeProvider>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const StatisticsCard = ({ color, icon, title, value, footer }) => {
  return (
    <Card>
      <CardHeader
        variant="gradient"
        color={color}
        className="absolute -mt-4 grid h-16 w-16 place-items-center"
      >
        {icon}
      </CardHeader>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        <Typography variant="h4" color="blue-gray">
          {value}
        </Typography>
      </CardBody>
      {footer && (
        <CardFooter className="border-t border-blue-gray-50 p-4">
          {footer}
        </CardFooter>
      )}
    </Card>
  );
};

export default DashboardDepartment;