import React from "react";
import Footer from "../../global/Footer";

const OnboardingWelcome = () => {
  return (
    <>
      <div className="bg-[#F2F2F2] flex flex-col min-h-screen items-center font-fabriga mx-auto">
        <div className="mt-20 text-4xl font-bold">ONBOARDING</div>
        <div className="bg-white w-4/5 h-2/3 flex mt-10 px-3 py-4 justify-center items-center rounded-xl">
          <div className="flex flex-row space-x-5">
            <div className="">
              {/* <FontAwesomeIcon icon={faInfo} className="text-6xl" /> */}
            </div>

            <div className="text-2xl">
              Welcome to HappyHippo! 
              Getting started on your HappyHippo journey is easy. 
              Uploading your Employee data in a CSV file using our template
              gets you started quickly.  if you have multiple CSV files, possibly
              your historical data over time, we recommend uploading them oldest to newest 
              this will allow our algorithm to learn about your employees.

              once you load all your historical employee data we will move on to help
              upload reference data like your job titles, compensation ranges, departments
              and location details.
            </div>
          </div>
        </div>
        <div className="mt-10 ml-10 flex flex-row items-center justify-center">
          <a href="/onboarding/stepone">
            <button
              className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Start Process
            </button>
          </a>
          <a href="/onboarding/errors">
            <button
              className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Import History
            </button>
          </a>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OnboardingWelcome;
