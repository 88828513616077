import React, {useContext, useState, useEffect} from "react";
import Navbar from "../../global/NavbarAdmin";
import Spinner from "../../global/Spinner";
import {AdminContext} from "./Admin";
import DepartmentTableComponent from "./Components/Department/DepartmentTableComponent";
import {StatusContext} from "../../contexts/Status";
import AWS from "aws-sdk";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {getDepartment} from "../../global/api";
import Sidebar from "./Sidebar";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import StepTwo from "../onboarding/StepTwo";
import {useParams} from "react-router-dom";

const ConfigDepartments = () => {
  const {selectedDept} = useContext(AdminContext);
  const {
    Email, ClientID, ClientName, CompanyID, CompanyName
  } = useContext(StatusContext);

  const [departments, setDepartments] = useState(null);
  const [awaitUsers, setAwaitUsers] = useState(true);
  const [awaitData, setAwaitData] = useState(true);
  const [users, setUsers] = useState(null);
  const [approvalGroups, setApprovalGroups] = useState(null);
  const [currentEdit, setCurrentEdit] = useState(null);

  const [showModalUser, setShowModalUser] = React.useState(false);

  const fetchData = async () => {
    const depts = await getDepartment(CompanyID);
    setDepartments(depts);
    setAwaitData(false);
    console.log("Departments", depts);
  };

  useEffect(() => {
    if (CompanyID) {
      fetchData();
    }
    // .finally(fetchData());
  }, [CompanyID, ClientID]);

  const handleDelete = (approvalGroupID, email) => {};

  const ITEMS_PER_PAGE = 10;

  const [currentPage, setCurrentPage] = useState(1);

  // Function to get the data for the current page
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Filter the data based on the search query
    const filteredData = departments.filter((employee) =>
      `${employee.Job_Title}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice(startIndex, endIndex);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const TABLE_HEAD = ["Department Name", "Impact Modifier"];

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      {/* <Navbar name="DEPARTMENTS"></Navbar> */}

      {awaitData === true ? (
        <Spinner />
      ) : (
        <div className="flex">
          <Sidebar />
          <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
            <div className="justify-center items-center m-auto w-4/5">
              <Card className="h-full w-4/5">
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="rounded-none"
                >
                  <div className="mb-8 flex items-center justify-between gap-8">
                    <div className="flex justify-between">
                      <Typography variant="h5" color="blue-gray">
                        Department List
                      </Typography>
                    </div>
                    <Button onClick={() => handleOpen()}>Load More</Button>
                    <Dialog open={open} handler={handleOpen}>
                      <DialogHeader>Load Department Data</DialogHeader>
                      <DialogBody divider>
                        <StepTwo />
                      </DialogBody>
                      <DialogFooter>
                        <Button
                          variant="text"
                          color="red"
                          onClick={handleOpen}
                          className="mr-1"
                        >
                          <span>Cancel</span>
                        </Button>
                        <Button
                          variant="gradient"
                          color="green"
                          onClick={handleOpen}
                        >
                          <span>Finish</span>
                        </Button>
                      </DialogFooter>
                    </Dialog>
                  </div>
                  <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <div className="w-full md:w-72">
                      <Input
                        label="Search"
                        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="overflow-scroll px-0">
                  <table className="mt-4 w-full table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={head}
                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                            >
                              {head}{" "}
                              {index !== TABLE_HEAD.length - 1 && (
                                <ChevronUpDownIcon
                                  strokeWidth={2}
                                  className="h-4 w-4"
                                />
                              )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentPageData().map(
                        (
                          {Department_ID, Department_Name, Impact_Modifier},
                          index
                        ) => {
                          const isLast = index === departments.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <>
                              <tr
                                key={Department_ID}
                                className="hover:bg-blue-gray-50 hover:cursor-pointer"
                              >
                                <td className={classes}>
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Department_Name}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {Impact_Modifier}
                                    </Typography>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </CardBody>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page {currentPage} of{" "}
                    {Math.ceil(departments.length / ITEMS_PER_PAGE)}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      size="sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(departments.length / ITEMS_PER_PAGE)
                      }
                    >
                      Next
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfigDepartments;
