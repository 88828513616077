import React from 'react';
import Chart from 'react-apexcharts';

const SparkChart = ({ data, height = '100px', color = '#22c55e' }) => {
  const chartOptions = {
    chart: {
      type: 'area',
      sparkline: { enabled: true },
      height,
      toolbar: { show: false }
    },
    colors: [color],
    stroke: { curve: 'smooth', width: 2 },
    fill: {
      type: 'gradient', 
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45, 
        opacityTo: 0.05,
        stops: [20, 100]
      }
    },
    tooltip: {
      enabled: true,
      x: {
        formatter: function (val) {
          return new Date(val).toLocaleDateString(); // Format the date for display
        }
      },
      theme: 'dark' // Choose theme as per your design
    }
  };

  return (
    <Chart 
      options={chartOptions}
      series={[{ data: data }]}
      type='area' 
      height={height}
    />
  );
}

export default SparkChart;
