import React, {useContext, useState} from "react";
import {AccountContext} from "../contexts/Account";
import {StatusContext} from "../contexts/Status";
import Spinner from "./Spinner";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Main = () => {
  const {
    Email,
    Admin,
    SuperAdmin,
    Manager,
    Loading,
    ClientID,
    CompanyID,
    ClientName,
    CompanyName,
    ApprovalGroup,
  } = useContext(StatusContext);
  const {logout} = useContext(AccountContext);
  const navigate = useNavigate();

  const encodedClientId = window.btoa(ClientID);
  const encodedCompanyId = window.btoa(CompanyID);
  const encodedEmail = window.btoa(Email);
  const encodedClientName = window.btoa(ClientName);
  const encodedCompanyName = window.btoa(CompanyName);
  const encodedApprovalGroup = window.btoa(ApprovalGroup);

  const navSuper = () => {
    navigate("/super/dashboard");
  };
  const navAdmin = () => {
    navigate(
      `/${encodedEmail}/${encodedClientId}/${encodedClientName}/${encodedCompanyId}/${encodedCompanyName}/${encodedApprovalGroup}/admin/dashboard`
    );
  };
  const navManager = () => {
    navigate("/manager/dashboard");
  };

  useEffect(() => {
    if (Loading) {
      return <Spinner />;
    } else {
      if (SuperAdmin) {
        navSuper();
      } else if (Admin) {
        navAdmin();
      } else if (Manager) {
        navManager();
      } else {
        return (
          <div
            class="
      flex
      items-center
      justify-center
      w-screen
      h-screen
      bg-gradient-to-r
      from-indigo-600
      to-blue-400
    "
          >
            <div class="px-40 py-20 bg-white rounded-md shadow-xl">
              <div class="flex flex-col items-center">
                <h1 class="font-bold text-blue-600 text-9xl">Welcome!</h1>

                <div class="px-6 py-2 text-sm font-semibold text-blue-800">
                  Please contact your Super Admin to get assigned a role!
                </div>
                <button
                  onClick={logout}
                  class="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        );
      }
    }
  }, [Loading]);
};

export default Main;
