import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../contexts/Status";
import { AccountContext } from "../../contexts/Account";
import { getClients, getClientComps } from "../../global/api";
import Navbar from "../../global/NavbarSuper";
import Footer from "../../global/Footer";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";

const DashboardSuper = () => {
  const navigate = useNavigate();
  const { updateClientAndCompany, Email } = useContext(StatusContext);
  const { getSession } = useContext(AccountContext);

  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.title = "Super Dashboard";
    fetchClients();
    verifySession();
  }, []);

  const verifySession = async () => {
    try {
      const session = await getSession();
      // Verify if the user is a Super Admin here
      // If not, redirect to an appropriate page
    } catch (error) {
      console.error("Session verification failed:", error);
      // Handle session error (e.g., redirect to login)
    }
  };

  const fetchClients = async () => {
    setIsLoadingClients(true);
    setError(null);
    try {
      const fetchedClients = await getClients();
      setClients(fetchedClients);
    } catch (error) {
      setError("Failed to fetch clients");
      console.error("Error fetching clients:", error);
    } finally {
      setIsLoadingClients(false);
    }
  };

  const fetchCompanies = async (clientId) => {
    setIsLoadingCompanies(true);
    setError(null);
    try {
      const fetchedCompanies = await getClientComps(clientId);
      setCompanies(fetchedCompanies);
    } catch (error) {
      setError("Failed to fetch companies");
      console.error("Error fetching companies:", error);
    } finally {
      setIsLoadingCompanies(false);
    }
  };

  const handleClientSelect = (clientId) => {
    setSelectedClientId(clientId);
    setSelectedCompanyId(null);
    setCompanies([]); // Clear previous companies
    fetchCompanies(clientId);
  };

  const handleCompanySelect = (companyId) => {
    setSelectedCompanyId(companyId);
  };

  const handleConfirm = () => {
    if (selectedClientId && selectedCompanyId) {
      updateClientAndCompany(selectedClientId, selectedCompanyId);
      setShowModal(false);
      navigate('/admin/dashboard');
    }
  };

  const roleLinks = [
    { name: "Manager", link: "/manager/dashboard" },
    { name: "Approver", link: "/approver/dashboard" },
    { name: "Auditor", link: "/auditor" },
    { name: "HR", link: "/hr/dashboard" },
    { name: "Executive", link: "/executive" },
    { name: "Recorder", link: "/recorder" },
  ];

  return (
    <div className="bg-[#F2F2F2] flex flex-col min-h-screen items-center font-fabriga mx-auto">
      <Navbar name="DASHBOARD" />
      <div className="font-fabriga font-bold text-8xl mt-10">
        Welcome HHI Admin!
      </div>
      <div className="mt-10 ml-10 flex flex-wrap justify-center">
        <Button
          className="m-2 bg-purple-500 text-white"
          onClick={() => setShowModal(true)}
        >
          Admin
        </Button>
        {roleLinks.map((link) => (
          <Button
            key={link.name}
            className="m-2 bg-purple-500 text-white"
            onClick={() => navigate(link.link)}
          >
            {link.name}
          </Button>
        ))}
      </div>
      <div className="mt-10 ml-10 flex flex-wrap justify-center">
        <Button
          className="m-2 bg-purple-500 text-white"
          onClick={() => navigate("/onboarding/")}
        >
          Onboarding
        </Button>
        <Button
          className="m-2 bg-purple-500 text-white"
          onClick={() => navigate("/super/email")}
        >
          Email
        </Button>
      </div>

      <Dialog open={showModal} handler={() => setShowModal(false)}>
        <DialogHeader>Select a Client and Company</DialogHeader>
        <DialogBody>
          {isLoadingClients ? (
            <div>Loading clients...</div>
          ) : (
            <Select label="Select Client" onChange={handleClientSelect}>
              {clients.map((client) => (
                <Option key={client.Client_ID} value={client.Client_ID}>
                  {client.Client_Name}
                </Option>
              ))}
            </Select>
          )}
          {isLoadingCompanies ? (
            <div>Loading companies...</div>
          ) : selectedClientId && companies.length > 0 ? (
            <Select label="Select Company" onChange={handleCompanySelect}>
              {companies.map((company) => (
                <Option key={company.Company_ID} value={company.Company_ID}>
                  {company.Company_Name}
                </Option>
              ))}
            </Select>
          ) : selectedClientId ? (
            <div>No companies found for this client</div>
          ) : null}
          {error && <div className="text-red-500">{error}</div>}
        </DialogBody>
        <DialogFooter>
          <Button color="red" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button color="green" onClick={handleConfirm} disabled={!selectedClientId || !selectedCompanyId}>
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>

      <Footer />
    </div>
  );
};

export default DashboardSuper;