import React, { useState, useContext, useEffect } from 'react';
import {
  getAttritionAnalLoc,
  getAttritionAnalDept,
  getAttritionAnalJob,
  getLocDetails,
  getDeptDetails,
  getJobDetails,
} from '../../global/api';
import { useParams } from 'react-router-dom';
import { StatusContext } from '../../contexts/Status';

const AttritionHeatmap = () => {
  const {
    Email, ClientID, ClientName, CompanyID, CompanyName,
  } = useContext(StatusContext);

  const [view, setView] = useState('location');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let rawResult = [];
      let detailResult = {};
      if (view === 'location') {
        rawResult = await getAttritionAnalLoc(CompanyID);
        detailResult = await getLocDetails(CompanyID);
      } else if (view === 'department') {
        rawResult = await getAttritionAnalDept(CompanyID, selectedLocation);
        detailResult = await getDeptDetails(CompanyID);
      } else if (view === 'job') {
        rawResult = await getAttritionAnalJob(CompanyID, selectedDepartment);
        detailResult = await getJobDetails(CompanyID);
      }

      setDetails(detailResult);

      let aggregatedData = {};
      rawResult.forEach(row => {
        const id = view === 'location' ? row.Work_Location_ID : row.Department_ID; // adjust as needed
        aggregatedData[id] = (aggregatedData[id] || 0) + row.attrition_all_count;
      });

      const aggregatedResult = Object.keys(aggregatedData).map(id => ({
        id,
        total: aggregatedData[id],
        name: details[id],
      }));
      setData(aggregatedResult);
    };

    fetchData();
  }, [view, selectedLocation, selectedDepartment]);

  const calculateGridCols = () => {
    const count = data.length;
    if (count === 0) return '';
    const sqrt = Math.ceil(Math.sqrt(count));
    return `grid-cols-${sqrt} gap-2`;
  };

  const getColor = (count) => {
    const maxCount = Math.max(...data.map(item => item.total));
    const percentage = (count / maxCount) * 100;

    if (percentage > 75) return 'bg-red-500';
    if (percentage > 50) return 'bg-orange-400';
    if (percentage > 25) return 'bg-yellow-300';
    return 'bg-green-200';
  };

  return (
    <div>
      <h1 className="text-2xl mb-2">{`Client: ${ClientName}, Company: ${CompanyName}`}</h1>
      <h2 className="text-xl mb-4">{`Attrition Heatmap - ${view.charAt(0).toUpperCase() + view.slice(1)}`}</h2>
      {view !== 'location' && <button onClick={() => setView(view === 'department' ? 'location' : 'department')} className="text-blue-500 underline mb-4">Go Back</button>}
      <div className={`grid ${calculateGridCols()}`}>
        {data.map((dataItem, i) => (
          <div
            key={i}
            className={`p-2 text-center cursor-pointer relative ${getColor(dataItem.total)}`}
            onClick={() => {
              if (view === 'location') {
                setSelectedLocation(dataItem.id);
                setView('department');
              } else if (view === 'department') {
                setSelectedDepartment(dataItem.id);
                setView('job');
              }
            }}
          >
            <div className="text-xs absolute bottom-0 right-0 mb-1 mr-1">{dataItem.total}</div>
            <div className="text-xs">{dataItem.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttritionHeatmap;
