import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  const data = props.data;
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        stacked: true,
        barPercentage: 0.1,
        grid: {
          display: false,
        },
      },
      x: {
        stacked: true,
        barPercentage: 0.4,
        grid: {
          display: false,
        },
        ticks: {
          display: false, //this will remove only the label
        },
      },
    },
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
