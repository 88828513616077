import React from 'react';
import { useCompanyData } from '../../contexts/CompanyContext';
import SparkChart from './DataSparkChart';
import { prepareChartDataCompany } from '../data/prepareChartDataCompany';
import SkeletonChart from '../common/SkeletonChart';

export const RenderCompanySpark = () => {
  const { companyData } = useCompanyData();

  console.log('Company Data:', companyData);

  if (!companyData) {
    console.log('No company data available');
    return <SkeletonChart />;
  }

  const allChartData = prepareChartDataCompany(companyData);
  console.log('All Chart Data:', allChartData);

  // Define chart configurations
  const chartConfig = [
    {
      title: "Total Employees Over Time",
      seriesName: "count_total",
      color: "#FF4560"
    },
    {
      title: "Risk Analysis Over Time",
      seriesName: "total_risk",
      color: "#775DD0"
    }
    // Add more configurations as needed
  ];

  return (
    <div className="flex flex-wrap justify-between ml-16"> {/* Add margin-left to match the sidebar width */}
      {chartConfig.map((config, index) => {
        const seriesData = allChartData[config.seriesName];
        if (!seriesData) {
          console.error(`No data for series: ${config.seriesName}`);
          return null; // Skip rendering this chart
        }

        const series = [{
          name: config.seriesName,
          data: seriesData
        }];

        console.log(`Rendering chart for ${config.title}:`, series);

        return (
          <div key={`chart-${index}`} className="w-full md:w-1/2 lg:w-1/3 mb-4 pl-4"> {/* Add padding-left */}
            <SparkChart
              color={config.color}
              data={seriesData}
              height="100px"
              type="area"
            />
            <p className="mt-2 text-gray-600 text-xs">{config.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default RenderCompanySpark;